import React from 'react'
import './LoginForm.scss'
import {Button, Form} from 'semantic-ui-react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { recovery } from '../../../api/Common/user'
import {toast} from 'react-toastify'
import { useAuth } from '../../../hooks'
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";


export function ForgotForm() {
	const login = useAuth();
	/* 
	recibe un objeto de configuracion 
		1-valor inicial del formulario
		2- validaciones
		3- submit
	 */
	const formik = useFormik({
		initialValues:initialValues(),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
			try {
				const response = await recovery(formValue)
 				
			} catch (error) {
				toast.error(error.message)
			}
		}
	})
	
	
  return (
    <Form className='login-form-admin' onSubmit={formik.handleSubmit}>
        <Form.Group widths='equal'>
           
            <Form.Input 
                name="email"
                placeholder="Email*"
                label="Email *"
                type="email"
				value={formik.values.email}
				onChange={formik.handleChange}
				error={formik.errors.email}


            />
        </Form.Group>
		<Button.Group widths='5'>
		<Button
			basic 
		 	color='red'
			component={Link} href={'/login'}
		
			
		>Ingresar con otra cuenta</Button>
        <Button type="submit" content="Cambiar contraseña" primary >

        </Button>
		</Button.Group>
    </Form>
  )
}

function initialValues(){
	return {
		email: ""

	}
}


function validationSchema(){
	return {
		email: Yup.string().email().required(true)
	}
}
