import React, { useEffect, useState } from 'react'

import { Segment,Form,Input,Icon, Label, Menu, Table, Grid, Loader,Button } from 'semantic-ui-react'
import {  getProveedoresList,deleteProveedor } from '../../../../api/Lizen/proveedor';
import { ModalBasic,PaginationTable } from '../../../../components/Common';
import {useHistory,Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ListadoProveedores} from '../../../../components/Admin/Lizen';
import { HeaderPage } from '../../../../components/Admin';
import {toast} from 'react-toastify'
import "./AbmAdmin.scss"
import { getPermissions } from '../../../../components/Common';
import { useAuth } from '../../../../hooks';
import { animateScroll as scroll } from 'react-scroll'

export  function ProveedorLizenList() {
	const {auth} = useAuth()
    const [proveedores,setProveedores] = useState([])
    const [loading,setLoading] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
    const [contentModal,setContentModal] = useState(null)
    const [refetch, setRefetch] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [countPage, setCountPage] = useState(1);
	
	const limitPagination = 10
    const history = useHistory()
	const fetchProveedores = async (request = null) =>{
		
		const proveedores = await getProveedoresList(request)

		setProveedores(proveedores.results)
		setCountPage(proveedores.count)
		setLoading(false)
		
	} 
    useEffect(()=>{
		scroll.scrollToTop();
        fetchProveedores(`page=1&is_active=1`); 
    },[refetch])
	
    const onRefetch = () => setRefetch((prev) => !prev);
	const openCloseModal = ()=>{
		
		setShowModal((prev)=>!prev)
	}
   	const onDeleteProveedor =  async(proveedor)=>{
		setContentModal(<p>Desea eliminar el proveedor {proveedor.razon_social} ?</p>)
		setTitleModal("Eliminar proveedor")
		setShowModal(true)
		setDataModal(proveedor.id)
    

  	}
  	const openConfirmModal = async(id)=>{
		try {
			let response = await deleteProveedor(id)
		
			toast.success(response.data)
			setShowModal(false)
			onRefetch()
		} catch (error) {
			console.log(error)
		} 
  	}
  	const crearProveedor = (id) => {
    	history.push(`/admin/proveedor_lizen/crear`);
  	}

  	const onEditProveedor = (proveedor) => {
		history.push(`/admin/proveedor_lizen/edit/id/${proveedor.id}`);
  	}
	const onShowProveedor = (proveedor) => {
		history.push(`/admin/proveedor_lizen/show/id/${proveedor.id}`);
  	}
	


  	const onSearch =  (e) => {
    	 fetchProveedores(`search=${e.target.value}&is_active=1`); 
		
  	}
  	const onChange = (e, pageInfo) => {
    	fetchProveedores(`page=${pageInfo.activePage.toString()}&is_active=1`); 

		setActivePage(pageInfo.activePage);
		
	};
  return (
    <div>
		
		<HeaderPage title="Proveedores" btnTitle="Agregar proveedor" btnClick={crearProveedor} permitionName="add_proveedor"/>
		
		
		   <Grid as={Segment} className='segment_search'>
				<Grid.Row >
					<Grid.Column computer={3} mobile={16} tablet={16}>  
						<Input 
							fluid
							
						icon={<Icon name='search' className='color_search' inverted circular />} 
						className="input_search"  
						inverted 
						placeholder='Buscar...' 
						onChange={onSearch}
					/>
					</Grid.Column>
					<Grid.Column computer={11} mobile={16} tablet={16}>


					</Grid.Column>
					<br></br>
					<Grid.Column computer={2} mobile={16} tablet={16}>
					{getPermissions("add_proveedor",auth) && (
						<Button circular basic positive onClick={crearProveedor}>
							Agregar proveedor
						</Button>
            )}
					</Grid.Column>

			
			
			</Grid.Row>
			</Grid>
		   
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16}>
        
          </Grid.Column>
        </Grid.Row>
      </Grid>

	  {loading ? (
		<Loader active inline="centered">
			Cargando...
		</Loader>
	  ):(
		<ListadoProveedores 
      proveedores={proveedores} 
      onDeleteProveedor={onDeleteProveedor}
      onEditProveedor={onEditProveedor}
      onShowProveedor={onShowProveedor}
	  countPage={countPage}
	  pagination={<PaginationTable 
		proveedores={proveedores} 
		onChange={onChange}
		activePage={activePage}
		countPage={countPage}
		
		limitPagination={limitPagination}
		/>}
    />
  		)}

      <ModalBasic 
      show={showModal} 
      size="small" 
      title={titleModal} 
      children={contentModal}
      onClose={openCloseModal}
      onConfirm={openConfirmModal}
	  dataModal={dataModal}
      />
    </div>
  )
}
