import React, { createContext, useState, useEffect} from 'react';
import {checkReport, removeHash} from '../api/Common/report'
import Moment from 'moment';
import { useAuth } from '../hooks';
import { getPermissions } from '../components/Common';
export const NotificationContext = createContext({
  showNotification :()=>null,
  hiddenNotification:()=>null,
  checkReportStatus:()=>null,
  startInterval:()=>null
});
Moment.locale('en');
export const NotificationProvider = ({ children }) => {
    const {auth} = useAuth()
    const [lastNotificationInfo, setLastNotificationInfo] = useState(null);
	useEffect(() => {
		

		let intervalId = startInterval()
		return () => {
		clearInterval(intervalId);
		};
	}, []); 

    const addNotificationData = (url) => {
        let notificationData = localStorage.getItem("notificationData");

    
        // Si hay datos almacenados, conviértelos de cadena a array
        let parsedData = notificationData === null ? [] :  JSON.parse(notificationData);
       
        // Agrega el nuevo elemento al array
        parsedData.push({'fecha': Moment(Date()).format('DD-MM-YYYY H:mma'), 'url': url,'show':false});
        
    
        // Actualiza el array convertido a cadena en localStorage
        localStorage.setItem("notificationData", JSON.stringify(parsedData));
    
  };
   
    const checkReportStatus = async () => {

		if(getPermissions("generate_report",auth)){
        if (JSON.parse(localStorage.getItem("hash")) === null || JSON.parse(localStorage.getItem("hash")).length === 0 ) {
              clearInterval(localStorage.getItem("intervalId"));
          return false;
        }

        try {
          JSON.parse(localStorage.getItem("hash")).map(async hash=>{
            const response = await checkReport(hash);
            const data     = await response.json();

            if (data.filename !== undefined) {
              addNotificationData(data.filename)
              setLastNotificationInfo({'fecha': Moment(Date()).format('DD-MM-YYYY H:mma'), 'url': data.filename,'show':false});
              
              clearInterval(localStorage.getItem("intervalId")); // Detener el intervalo al obtener la URL
              
              removeHash(hash)
          
            }else{
              return false;
            }
          })
            
                
            
          
        } catch (error) {
            console.error('Error al verificar el estado del informe:', error);
            clearInterval(localStorage.getItem("intervalId")); 
                //localStorage.removeItem("hash")
        }
    }
		
	  };
  
const  hiddenNotification =()=>{
    setLastNotificationInfo(false)
}
 const startInterval =()=>{

    const intervalId = setInterval(() => {checkReportStatus();}, 5000);
    
    localStorage.setItem("intervalId",intervalId)
    // Limpia el intervalo al desmontar el componente
    return intervalId
 }
  const valueContext = {
    checkReportStatus,
    lastNotificationInfo,
    hiddenNotification,
    startInterval
}
  return (
    <NotificationContext.Provider value={valueContext}>
      {children}
    </NotificationContext.Provider>
  );
};

