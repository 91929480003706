import permissionsSecondaryServiceOrion from '../../utils/permissionsSecondaryServiceOrion.json'

export function getPermissions(permissionName,userData) {
    console.log('Get permissions')
    console.log('permissionsName',permissionName)
    console.log("userData",userData)

    let active = ""
    let group_tmp = ""
    if(userData?.me){
        active = userData?.me?.permissions.filter(e=>e.codename === permissionName)
        
        if(getActive(active))
            return true;

        if(userData?.me?.groups.length > 0){
            for (const group of userData?.me?.groups) {
                
                active = group.permissions.filter(e=>e.codename === permissionName)
                //return active
                group_tmp = group.name;
                if(getActive(active,group_tmp))
                    break;
            }
            
            return getActive(active,group_tmp)
        }
    }
   
    return false
} 


export function getPermissionsSecondary(permissionName,userData,key,type) {
    let match = ""
    
    if(userData?.me){
        
        if(userData?.me?.groups.length > 0){
            for (const group of userData?.me?.groups) {
                if (group?.permissionsSecondary !== null)
                    match = group?.permissionsSecondary[key].filter(e=>{return e.codename === permissionName && e.type === type})
                else 
                    match = permissionsSecondaryServiceOrion[key].filter(e=>{return e.codename === permissionName && e.type === type})
            }

            if(match.length > 0)
                if(match[0].active)
                    return true;
        }
    }
   
    return false
}  

function getActive(active,group = ""){
    
    if(active.length > 0){
        
        if(active[0].codename === "view_tipoestudio" && group === "ASEGURADORA")
            return false;

        return true;
    }
    return false;
}

export function getGroup(userData, groupToFind){
    if(userData?.me?.groups.length > 0){
        for (const group of userData?.me?.groups) {
            if(group.name === groupToFind) {
                return true
            }
        }
    }
    return false
}
