import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function getBeneficiario(id, search=null){
    try {
        
        const url =  search !== null ? `${BASE_API}/api-orion/beneficiario/?${search}` : `${BASE_API}/api-orion/beneficiario/${id}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
            const result= await response.json()
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}


export async function getBeneficiarios(query_string = null){
    try {
        
        const url =  query_string === null ? `${BASE_API}/api-orion/beneficiario/` : `${BASE_API}/api-orion/beneficiario/?${query_string}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
            const result= await response.json()
            return result
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}

export async function deleteBeneficiario(id){
    try {
        
        
        const url =  `${BASE_API}/api-orion/beneficiario/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 204){
            throw new Error (response.message)
        } 
        const result= {status:"sucess",data:"Se eliminó exitosamente"}
        return result
    } catch (error) {
        throw error
    }
}

export async function addBeneficiarioOrion(request){
  
    try {
        const url =  `${BASE_API}/api-orion/beneficiario/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"
        
        return result 
        
    } catch (error) {
        throw error
    } 
}

export async function editBeneficiarioOrion(id,request){
    try {
        const url =  `${BASE_API}/api-orion/beneficiario/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"
            
        return result 
    
        return result  
    } catch (error) {
        throw error
    }
}


export async function addExcelBeneficiario(path_file){
    let request = {
        "filename":path_file
    }
    const url =  `${BASE_API}/api-orion/import-beneficiario/`
    const params = {
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(request)
    }
    const response = await fetch(url,params)

    const result= await response.json()

    return result
}