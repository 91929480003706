import {Grid} from "semantic-ui-react";
import ServiciosLizenTiempo from "./Lizen/SeviciosLizenTiempo";
import ServiciosLizenEstado from "./Lizen/ServiciosLizenEstado";
import ServiciosLizenConvenioBar from "./Lizen/ServiciosLizenConvenioBar";
import DerivacionesOrionTiempo from "./Orion/DerivacionesOrionTiempo";
import DerivacionesOrionConvenioBar from "./Orion/DerivacionesOrionConvenio";
import DerivacionesOrionEstado from "./Orion/DerivacionesOrionEstado";
import React, {useState} from 'react'
import { MenuMenu, MenuItem, Input, Menu, Segment } from 'semantic-ui-react'

const Dashboard = () => {
    const [activeItem, setActiveItem] = useState('lizen')

    const handleItemClick = (e, { name }) => setActiveItem(name)

    return (
        <div>
            <Menu attached='top' tabular>
                <MenuItem
                    name='lizen'
                    active={activeItem === 'lizen'}
                    onClick={handleItemClick}
                />
                <MenuItem
                    name='orion'
                    active={activeItem === 'orion'}
                    onClick={handleItemClick}
                />
            </Menu>
            <Segment attached='bottom'>
                {activeItem === 'lizen' &&
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} style={{margin:'10px'}}>
                                <h4>Cantidad de servicios en el año</h4>
                                <ServiciosLizenTiempo/>
                            </Grid.Column>
                            <Grid.Column width={16} style={{margin:'10px'}}>
                                <h4>Cantidad de servicios por convenio</h4>
                                <ServiciosLizenConvenioBar/>
                            </Grid.Column>
                            <Grid.Column width={16} style={{margin:'10px'}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <div style={{textAlign:"center"}}>
                                        <h4>Estados servicios</h4>
                                        <ServiciosLizenEstado/>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                {activeItem === 'orion' &&
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16} style={{margin: '10px'}}>
                                <h4>Cantidad de derivaciones en el año</h4>
                                <DerivacionesOrionTiempo/>
                            </Grid.Column>
                            <Grid.Column width={16} style={{margin: '10px'}}>
                                <h4>Cantidad de derivaciones por convenio</h4>
                                <DerivacionesOrionConvenioBar/>
                            </Grid.Column>
                            <Grid.Column width={16} style={{margin: '10px'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <div style={{textAlign: "center"}}>
                                        <h4>Derivaciones estados</h4>
                                        <DerivacionesOrionEstado/>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Segment>
        </div>
    )

}

export default Dashboard;