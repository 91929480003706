import React from 'react'
import './App.scss';
import {Navigation} from './routes';
import {ToastContainer} from "react-toastify"
import {AuthProvider,NotificationProvider} from  './context'
export default function App() {


  return (
    (<AuthProvider>
    <NotificationProvider>
    <Navigation />
    <ToastContainer 
    position='bottom-center'
    autoClose={false}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover={false}
   
    />
    </NotificationProvider>
    </AuthProvider>)
    
  )
}

