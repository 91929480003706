import {Line} from "react-chartjs-2";
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
import {useEffect, useState} from "react";
import {BASE_API} from "../../../../../utils/constantes";
import {generarDataSetLine} from "../utils";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

const DerivacionesOrionTiempo = () => {

    const [dataSet, setDataSet] = useState(null)

    function getDerivacionesPorAnio() {
        fetch(`${BASE_API}/api/dashboard/derivacion_anio/`, {
            headers:{
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json()).then((data) => {
            setDataSet(generarDataSetLine(data))
        })
    }

    useEffect(() => {
        getDerivacionesPorAnio()
    }, [])

    const options = {
        plugins:{
            legend:true,
        },
    }

    return (dataSet? <Line data={dataSet} height={50} options={options}></Line> : <div>Cargando...</div>)
};

export default DerivacionesOrionTiempo;