import React from "react";
import {getMeApi} from '../api/Common/user'
export function useUser(){
    const getMe = async (token) =>{
        try {
            const response = await getMeApi(token);
            return response;

        } catch (error) {
            return false
        }
    }

    return{
        getMe
    }
}