import React,{useState} from 'react'
import './scss/AdminLayout.scss';
import {LoginAdmin} from '../../pages/Admin'
import {Redirect, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { useAuth } from '../../hooks';
import { getToken } from '../../api/Common/token';
import { TopMenu, SideMenu,Content } from '../../components/Admin/';

export function AdminLayout(props) {
  const {children} = props
  const {auth} = useAuth()
  const [toggleBtn, setToggleBtn] = useState(true);
  if(getToken() === null)
    return (<Redirect to={"/login"} />)


  const toggle = () => setToggleBtn(val => !val);
  return (
    <div className="top-wrapper">
    <TopMenu  />
    <SideMenu setToggle={toggle} toggleBtn={toggleBtn} />
     <Content toggleBtn={toggleBtn}>
     {children}
    </Content> 
  </div>
  )
}
