import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  Icon,
  Label,
  Grid,
  Form,
  Segment,
  Checkbox,
  Dropdown,
  Search,
  TextArea,
  Button,
} from "semantic-ui-react";
import "./ServicesAdmin.scss";
import Reducer from "./Reducer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  getContactoProveedor,
  getProveedores,
  getProveedor,
} from "../../../../api/Lizen/proveedor";
import { getBeneficiario } from "../../../../api/Lizen/beneficiario";
import {
  addServicioLizen,
  getServicioLizen,
  editServicioLizen,
  disableStatusByGroup,
  optionsStatusByGroup,
} from "../../../../api/Lizen/servicio";
import { getConvenio } from "../../../../api/Lizen/aseguradora";
import { getArt } from "../../../../api/Lizen/art";
import { getCiaSeguros } from "../../../../api/Lizen/ciaSeguros";
import { getProvincias } from "../../../../api/Lizen/provincia";
import { getLocalidades } from "../../../../api/Lizen/localidad";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../hooks";
import { BASE_API } from "../../../../utils/constantes";
import Moment from "moment";
import { fetchUploadFile } from "../../../../api/Common/file";
import { getPermissionsSecondary } from "../../../../components/Common";
import { Select } from "semantic-ui-react";
// import { addDocumentoClinica, getTiposDoc } from '../../../../api/Orion/clinica';
import {
  getTiposDoc,
  addDocumentoServicio,
  addDocumentoServicioNota,
  deleteDocumentoServicio,
  getDocumentosServicio,
} from "../../../../api/Lizen/servicio";

Moment.locale("en");
function Acciones(props) {
  const {
    compact,
    color,
    nameIcon,
    component,
    href,
    actionName,
    actionParams,
    title,
    description,
  } = props;

  const actionOnClick = () => {
    if (actionName === "GetContacto")
      props.callbackAcciones(actionParams.proveedor, actionParams.indice);

    if (actionName === "GetBeneficiario")
      props.callbackAcciones("", actionParams.dni);
  };
  return (
    <Modal
      className="modal-basic"
      trigger={
        <Button
          href={href}
          component={component}
          compact={compact}
          icon
          basic
          color={color}
          target="_blank"
          circular
          size="mini"
        >
          <Icon name={nameIcon} />
        </Button>
      }
      header={title}
      content={description}
      onClose={actionOnClick}
      actions={[
        { key: "Continuar", content: "Continuar", positive: true, basic: true },
      ]}
    />
  );
}
function Proveedores(props) {
  const {
    disabled,
    indice,
    inputProveedores,
    city,
    citiesOptions,
    getProvinceAR,
    provinciaSelected,
    proveedoresOptions,
    proveedor,
    proveedoresData,
    conIva,
    sinIva,
    checkValueWithIva,
    cantKM,
    valorKM,
    formik,
    trasladoOptions,
    lugarOptions,
    contactoOptions,
    auth,
    type_action,
  } = props;

  const removeProv = (e, indice) => {
    e.preventDefault();
    if (inputProveedores.length === 1) {
      toast.error("Al menos una cochería tiene que cargarse");
    } else {
      const newInputProv = inputProveedores.filter(
        (e) => e.props.indice !== indice
      );

      props.callbackRemoveItem(newInputProv, indice);
      formik.values.proveedor_provincias.splice(indice, 1);

      formik.values.proveedor_localidades.splice(indice, 1);
      formik.values.proveedor_proveedor.splice(indice, 1);
      formik.values.proveedor_proveedor_data.splice(indice, 1);
      formik.values.proveedor_contacto.splice(indice, 1);
      formik.values.proveedor_cantKM.splice(indice, 1);
      formik.values.proveedor_valorKM.splice(indice, 1);
      formik.values.proveedor_sinIva.splice(indice, 1);
      formik.values.proveedor_conIva.splice(indice, 1);
      formik.values.proveedor_checkValueWithIva.splice(indice, 1);
      formik.values.proveedor_traslado.splice(indice, 1);

      formik.setFieldValue(
        `proveedor_direccion_${indice}`,
        formik.values.proveedor_proveedor_data[0]?.calle
      );
      formik.setFieldValue(
        `proveedor_telefono_${indice}`,
        formik.values.proveedor_proveedor_data[0]?.telefono
      );
      formik.setFieldValue(
        `proveedor_email_${indice}`,
        formik.values.proveedor_proveedor_data[0]?.email
      );
    }
  };
  const changeProvincia = (value, indice) => {
    props.callbackProvincia(value, indice);
  };
  const changeCity = (value, indice) => {
    props.callbackCiudad(value, indice);
  };
  const changeProveedor = (data, indice) => {
    props.callbackProveedor(data.value, indice);
  };
  const changeTraslado = (value, indice) => {
    let trasladoAux = formik.values.proveedor_traslado;
    trasladoAux[indice] = value;
    formik.setFieldValue("proveedor_traslado", trasladoAux);
  };
  const changeLugar = (value, indice) => {
    let lugarAux = formik.values.proveedor_lugar;
    lugarAux[indice] = value;
    formik.setFieldValue("proveedor_lugar", lugarAux);
  };
  const changeContacto = (value, indice) => {
    let contactoAux = formik.values.proveedor_contacto;
    contactoAux[indice] = value;
    formik.setFieldValue("proveedor_contacto", contactoAux);
  };
  const changeCheckIva = (value, indice) => {
    props.callbackCheckIva(value, indice);
  };
  const changeWithoutIva = (value, indice) => {
    props.callbackWithoutIva(value, indice);
  };
  const changeCantKM = (value, indice) => {
    props.callbackInputKM(value, indice);
  };

  const prov =
    proveedoresData !== undefined ? proveedoresData[indice] : proveedoresData;

  const processAcciones = (proveedor, indice) => {
    props.callbackContactoProveedor(proveedor, indice);
  };

  return (
    <Grid as={Segment}>
      <Grid.Row>
        {getPermissionsSecondary(
          "proveedor_provincia",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>* Provincia</h5>
            {provinciaSelected !== undefined && (
              <Dropdown
                search
                disabled={disabled}
                placeholder="Seleccione una provincia"
                name="proveedor_provincias"
                options={getProvinceAR}
                value={provinciaSelected[indice]}
                onChange={(e, data) => changeProvincia(data.value, indice)}
                selection
                fluid
                error={provinciaSelected[indice] !== undefined ? false : true}
              />
            )}
          </Grid.Column>
        )}

        {getPermissionsSecondary(
          "proveedor_localidad",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>* Ciudad</h5>
            <Dropdown
              search
              disabled={disabled}
              placeholder="Seleccione una ciudad"
              name="proveedor_localidades"
              options={citiesOptions[indice]}
              value={city[indice]}
              onChange={(e, data) => {
                changeCity(data.value, indice);
              }}
              selection
              fluid
              error={city[indice] !== undefined ? false : true}
            />
          </Grid.Column>
        )}
        <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {getPermissionsSecondary(
          "proveedor_cocheria_razon_social",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <h5>Cochería</h5>

            <Dropdown
              placeholder="Seleccione una cochería"
              name="proveedor_proveedor"
              search
              value={proveedor[indice]}
              options={proveedoresOptions[indice]}
              scrolling
              selection
              onChange={(e, data) => {
                changeProveedor(data, indice);
              }}
              fluid
              disabled={
                disabled ? disabled : city[indice] !== "" ? false : true
              }
              error={proveedor[indice] !== undefined ? false : true}
            />
          </Grid.Column>
        )}
        {getPermissionsSecondary(
          "proveedor_lugar",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={4} mobile={16} tablet={16}>
            <h5 style={{ visibility: "hidden" }}>Lugar</h5>
            <Dropdown
              disabled={disabled}
              name="lugar"
              options={lugarOptions}
              selection
              value={formik.values["proveedor_lugar"][indice]}
              onChange={(e, data) => {
                changeLugar(data.value, indice);
              }}
              fluid
            />
          </Grid.Column>
        )}

        <Grid.Column computer={2} mobile={16} tablet={16}>
          {" "}
        </Grid.Column>
      </Grid.Row>
      <Grid.Column computer={16} mobile={16} tablet={16}></Grid.Column>
      <br></br>

      {getPermissionsSecondary(
        "proveedor_contacto_nombre",
        auth,
        type_action,
        "lizen"
      ) && (
        <Grid.Row>
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <h5>
              Contacto{" "}
              {!disabled && (
                <Acciones
                  contentPopup="Agregar un contacto"
                  title=""
                  description="Acepte y vera reflejado el  nuevo contacto"
                  content="Agregue un nuevo contacto"
                  compact={true}
                  color="green"
                  nameIcon="add"
                  actionName="GetContacto"
                  actionParams={{
                    indice: indice,
                    proveedor: proveedor[indice],
                  }}
                  href={`${BASE_API}/admin/plani/contactoproveedor/add/?proveedor=${proveedor[indice]}`}
                  component={Link}
                  callbackAcciones={processAcciones}
                />
              )}
            </h5>
            <Dropdown
              disabled={disabled}
              placeholder="Seleccione un contacto"
              name="proveedor_contacto"
              options={contactoOptions[indice]}
              selection
              value={formik.values["proveedor_contacto"][indice]}
              onChange={(e, data) => {
                changeContacto(data.value, indice);
              }}
              fluid
            />
          </Grid.Column>

          <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        {getPermissionsSecondary(
          "proveedor_contacto_nombre",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>Dirección</h5>
            <Form.Input
              disabled={disabled}
              fluid
              placeholder="dirección"
              name={`proveedor_direccion_${indice}`}
              value={formik.values[`proveedor_direccion_${indice}`]}
              onChange={(e, data) => {
                formik.setFieldValue(
                  `proveedor_direccion_${indice}`,
                  data.value
                );
              }}
              error={formik.errors[`proveedor_direccion_${indice}`]}
            />
          </Grid.Column>
        )}
        {getPermissionsSecondary(
          "proveedor_celular",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>Teléfono</h5>
            <Form.Input
              fluid
              disabled={disabled}
              placeholder="Teléfono"
              name={`proveedor_telefono_${indice}`}
              value={formik.values[`proveedor_telefono_${indice}`]}
              onChange={(_, data) =>
                formik.setFieldValue(`proveedor_telefono_${indice}`, data.value)
              }
              error={formik.errors[`proveedor_telefono_${indice}`]}
            />
          </Grid.Column>
        )}
        {getPermissionsSecondary(
          "proveedor_email",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={4} mobile={16} tablet={16}>
            <h5>Email</h5>
            <Form.Input
              disabled={disabled}
              fluid
              placeholder="Email"
              name={`proveedor_email_${indice}`}
              value={formik.values[`proveedor_email_${indice}`]}
              onChange={(_, data) =>
                formik.setFieldValue(`proveedor_email_${indice}`, data.value)
              }
              error={formik.errors[`proveedor_email_${indice}`]}
            />
          </Grid.Column>
        )}
        <Grid.Column computer={2} mobile={16} tablet={16}></Grid.Column>
      </Grid.Row>
      {getPermissionsSecondary(
        "proveedor_tipo_servicio",
        auth,
        type_action,
        "lizen"
      ) && (
        <Grid.Row>
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <h5>Tipo de Servicio</h5>
            <Dropdown
              disabled={disabled}
              name="traslado"
              options={trasladoOptions}
              selection
              value={formik.values["proveedor_traslado"][indice]}
              onChange={(e, data) => {
                changeTraslado(data.value, indice);
              }}
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        {getPermissionsSecondary(
          "proveedor_valor_sin_iva",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={4} mobile={16} tablet={16}>
            <h5>Valor sin IVA</h5>
            <Form.Input
              disabled={disabled}
              fluid
              placeholder="Valor sin IVA"
              value={sinIva[indice]}
              defaultValue={prov?.valor_acordado}
              onChange={(e, { value }) => {
                changeWithoutIva(value, indice);
              }}
            />
          </Grid.Column>
        )}
        <br></br>
        {getPermissionsSecondary(
          "proveedor_valor_con_iva",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={2} mobile={16} tablet={16}>
            <Checkbox
              label="+ IVA"
              disabled={disabled}
              checked={checkValueWithIva[indice]}
              className="checkbox"
              onChange={(e, data) => {
                changeCheckIva(data, indice);
              }}
            />
          </Grid.Column>
        )}
        {getPermissionsSecondary(
          "proveedor_valor_con_iva",
          auth,
          type_action,
          "lizen"
        ) && (
          <Grid.Column computer={4} mobile={16} tablet={16}>
            <h5>Valor final Con IVA</h5>
            <Form.Input
              fluid
              disabled
              defaultValue={0}
              value={conIva[indice]}
            />
          </Grid.Column>
        )}

        <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
      </Grid.Row>
      {(formik.values.proveedor_traslado[indice] === "Traslado" ||
        formik.values.proveedor_traslado[indice] === "Servicio y traslado") && (
        <Grid.Row>
          {getPermissionsSecondary(
            "proveedor_cant_km",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <h5>Cantidad de KMs</h5>
              <Form.Input
                disabled={disabled}
                type="number"
                value={cantKM[indice]}
                fluid
                placeholder="Cantidad de KMs totales del Traslado"
                onChange={(e, data) => {
                  changeCantKM(data.value, indice);
                }}
              />
            </Grid.Column>
          )}
          {getPermissionsSecondary(
            "proveedor_valor_km",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <h5>Valor x KM</h5>
              <Form.Input
                fluid
                disabled
                defaultValue={0}
                value={valorKM[indice]}
              />
            </Grid.Column>
          )}

          <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
        </Grid.Row>
      )}

      <Grid.Column computer={13} mobile={16} tablet={16}></Grid.Column>

      <Grid.Column computer={3} mobile={16} tablet={16}>
        <Button
          fluid
          circular
          disabled={disabled}
          onClick={(e, data) => removeProv(e, indice)}
          color="red"
          basic
        >
          Eliminar proveedor
        </Button>
      </Grid.Column>
    </Grid>
  );
}

export function ServicesLizenCreateEditShow(props) {
  const { auth } = useAuth();
  const { history } = props;
  const { id, type_action } = props.match.params;
  const disabledGeneral = type_action === "show" ? true : false;
  const isAddMode = !id;
  const [inputProveedores, setInputProveedores] = useState([]);
  const [tiposDocs, setTiposDocs] = React.useState([]);
  const [documentos, setDocumentos] = React.useState([]);
  const [deletdDocs, setDeletedDocs] = React.useState([]);
  const [tipoDocumento, setTipoDocumento] = React.useState("");
  const [servicio, setServicio] = React.useState([]);

  const fetchProvincias = async () => {
    await getProvincias().then((data) => {
      let provincias_process = data.results.map((x) => {
        return { key: x.id, text: x.nombre, value: x.id };
      });
      dispatch({ type: "SET_PROVINCIA", provincias: provincias_process });
    });
  };

  const handleTipoDocChange = (event, data) => {
    setTipoDocumento(data.value);
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let { status, data } = await fetchUploadFile(formData);
    let tipoDoc = tipoDocumento;
    if (tipoDoc) {
      await addDocumentoServicio(id, tipoDoc, data[0]).then((data) => {
        if (data) {
          setDocumentos([...documentos, data]);
        }
      });
    } else {
      toast.error("Error al subir el archivo");
    }

    if (status === "success") {
      toast.success("Archivo subido correctamente");
    } else {
      toast.error("Error al subir el archivo");
    }
  };

  const handleDocDelete = async (id) => {
    if (deletdDocs.includes(id)) {
      setDeletedDocs(deletdDocs.filter((doc) => doc !== id));
      let deletedDoc = documentos.find((doc) => doc.id === id);
      deletedDoc.deleted = false;
      setDocumentos([...documentos]);
      return;
    } else {
      setDeletedDocs([...deletdDocs, id]);
      let deletedDoc = documentos.find((doc) => doc.id === id);
      deletedDoc.deleted = true;
      setDocumentos([...documentos]);
    }
  };

  const handleSave = () => {

    deletdDocs.map(async (id) => {

      await deleteDocumentoServicio(id);
    });

    documentos.map(async (doc) => {
      if (doc.comentario) {
        await addDocumentoServicioNota(doc.id, doc.comentario);
      }
    });
  };

  const handleDocChange = (nota, docId) => {
    let doc = documentos.find((doc) => doc.id === docId);
    doc.comentario = nota;
    setDocumentos([...documentos]);
  };

  const dataProvincias = async (indice, proveedor = null) => {
    fetchProvincias();

    let provincia_id = proveedor?.provincia || "";

    let provincias_formik = formik.values.proveedor_provincias;
    if (provincia_id !== "") provincias_formik[indice] = provincia_id;

    formik.setFieldValue("proveedor_provincias", provincias_formik);
  };
  const changeFallecidoProvincia = async (value) => {

    formik.setFieldValue("fallecido_provincia", value);
    let localidades_process = await fetchLocalidades(value);

    dispatch({
      type: "SET_LOCALIDAD_FALLECIDO",
      ciudades: localidades_process,
    });
  };
  const formik = useFormik({
    initialValues: initialValues(auth, servicio),
    validationSchema: Yup.object(
      isAddMode
        ? validationSchema(inputProveedores)
        : updateSchema(inputProveedores)
    ),
    onSubmit: async (formValue) => {


      if (isAddMode) {
        if (formik.values.fecha === null) {
          formik.values.fecha = Moment(new Date()).format("YYYY-MM-DD");
        }
        const response = await addServicioLizen(formValue, inputProveedores);

        try {
          if (response.status !== undefined && response.status === "error") {
            toast.error(
              `Error en el sistema: ${JSON.stringify(response.message)}`
            );
          } else {
            toast.success("Se guardó exitosamente el servicio.", {
              onClose: () => {
                history.push(`/admin/servicios_lizen/listado`);
              },
              autoClose: 1000,
            });
          }
        } catch (error) {

          toast.error(JSON.stringify(error.message));
        }
      } else {
        const response = await editServicioLizen(
          id,
          formValue,
          inputProveedores
        );

        try {
          if (response.status !== undefined && response.status === "error") {
            toast.error(
              `Error en el sistema: ${JSON.stringify(response.message)}`
            );
          } else {
            toast.success("Se guardó exitosamente el servicio.", {
              onClose: () => {
                history.push(`/admin/servicios_lizen/listado`);
              },
              autoClose: 1000,
            });
          }
        } catch (error) {

          toast.error(JSON.stringify(error.message));
        }
      }
    },
    enableReinitialize: isAddMode ? false : false,
  });

  const initialState = {
    art: [],
    ciaSeguros: [],
    contactoOptions: [],
    provincias: [],
    loading_search: false,
    results_search: [],
    value_search: "",
    citiesOptions: [],
    proveedoresOptions: [],
    proveedor: [],
    proveedores: [],
  };

  const [state, dispatch] = React.useReducer(Reducer, initialState);

  const trasladoOptions = [
    { key: 1, text: "Traslado", value: "Traslado" },
    { key: 2, text: "Servicio", value: "Servicio" },
    { key: 3, text: "Servicio y traslado", value: "Servicio y traslado" },
    { key: 4, text: "Ninguna", value: "Ninguna" },
  ];

  const lugarOptions = [
    { key: 1, text: "Origen", value: "Origen" },
    { key: 2, text: "Destino", value: "Destino" },
    { key: 2, text: "Origen y Destino", value: "Origen y Destino" },
    { key: 2, text: "Ninguna", value: "Ninguna" },
  ];

  const serviceTypeOptions = [
    { key: 1, text: "A - Básico", value: "A" },
    { key: 2, text: "B - Medio", value: "B" },
    { key: 3, text: "C - Premium", value: "C" },
    { key: 4, text: "2 - Base", value: "2" },
  ]

  const {
    art,
    ciaSeguros,
    contactoOptions,
    provincias,
    loading_search,
    results_search,
    citiesOptions,
    ciudades_fallecido,
    proveedoresOptions,
    proveedores,
  } = state;

  const destino = [
    { key: 1, text: "Cremación", value: "Cremación" },
    { key: 2, text: "Inhumación (Tierra)", value: "Inhumación (Tierra)" },
    { key: 3, text: "Nicho", value: "Nicho" },
    { key: 4, text: "No definido", value: "No definido" },
  ];

  const vinculoOptions = [
    { key: 1, text: "Familiar / Allegado", value: 1 },
    { key: 2, text: "ART", value: 2 },
    { key: 3, text: "Compañía de Seguros", value: 3 },
  ];

  const carencia = [
    { text: "Si", value: true },
    { text: "No", value: false },
    { text: "No aplica", value: null },
  ];

  const [loadProveedor, setLoadProveedor] = React.useState(false);

  const resultRenderer = ({ nombre, apellido, dni }) => (
    <Label key={dni} content={`${nombre} ${apellido}`} />
  );
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, value) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: "START_SEARCH", query: value });
    formik.setFieldValue("fallecido_dni", value);
    timeoutRef.current = setTimeout(async () => {
      const beneficiario =
        value.length > 4 ? await getBeneficiario(`search=${value}`) : [];

      if (value.length === 0) {
        setLoadProveedor(false);

        setearCamposVacios(true);
        formik.setFieldValue("fallecido_carencia", null);

        return;
      }

      if (beneficiario.count > 0) {
        if (beneficiario.results[0].carencia === false) {
          setLoadProveedor(false);
        }
      }

      dispatch({
        type: "FINISH_SEARCH",
        results: beneficiario.results,
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      const getSercivioDocs = async () => {
        await getDocumentosServicio(id).then((data) => {
          setDocumentos(data.results);
        });
      };
      getSercivioDocs();

      const fetchServicio = async () => {
        await getServicioLizen(`${props.match.params.id}`).then((data) => {
          changeFallecidoProvincia(data?.provincia.id);
          formik.setFieldValue("fallecido_nombre", data?.cliente?.nombre);
          formik.setFieldValue("fallecido_apellido", data?.cliente?.apellido);
          formik.setFieldValue("fallecido_velatorio", data?.velatorio);
          formik.setFieldValue("fallecido_destino", data?.destino);
          formik.setFieldValue(
            "fallecido_direccion_residencia",
            data?.direccion_residencia
          );
          formik.setFieldValue(
            "fallecido_direccion_fallecimiento",
            data?.direccion_fallecimiento
          );
          formik.setFieldValue("fallecido_provincia", data?.provincia.id);
          formik.setFieldValue("fallecido_localidad", data?.localidad.id);
          formik.setFieldValue("fallecido_dni", data?.cliente?.dni);
          formik.setFieldValue("cliente", data?.cliente?.id);
          formik.setFieldValue("fallecido_carencia", data?.cliente?.carencia);
          formik.setFieldValue("fallecido_poliza", data?.cliente?.poliza);
          formik.setFieldValue("fallecido_tipo_servicio", data?.cliente.tipo_servicio);
          formik.setFieldValue(
            "fallecido_convenio",
            data?.cliente?.aseguradora__nombre
          );
          formik.setFieldValue(
            "fallecido_inicio_vigencia",
            data?.cliente?.vigencia !== null
              ? Moment(data?.cliente?.vigencia).format("DD-MM-YYYY")
              : data?.cliente?.vigencia
          );

          formik.setFieldValue(
            "contacto_nombre_apellido",
            data?.lizen_contacto_servicio?.nombre_apellido
          );
          formik.setFieldValue(
            "contacto_telefono",
            data?.lizen_contacto_servicio?.telefono
          );
          formik.setFieldValue(
            "contacto_email",
            data?.lizen_contacto_servicio?.email
          );
          formik.setFieldValue(
            "contacto_vinculo",
            parseInt(data?.lizen_contacto_servicio?.vinculo)
          );
          formik.setFieldValue(
            "contacto_art",
            data?.lizen_contacto_servicio?.art_id
          );
          formik.setFieldValue(
            "contacto_cia",
            data?.lizen_contacto_servicio?.cia_seguro_id
          );
          formik.setFieldValue(
            "contacto_detalle",
            data?.lizen_contacto_servicio?.detalle_vinculo
          );
          formik.setFieldValue(
            "contacto_id",
            data?.lizen_contacto_servicio?.id
          );

          formik.setFieldValue("notas", data?.nota_importancia);
          formik.setFieldValue("url_autorizacion", data?.url_autorizacion);
          formik.setFieldValue("estado", data?.estado);
          formik.setFieldValue("fecha", data?.fecha);
          formik.setFieldValue("operador", auth?.me?.id);

          setLoadProveedor(true);
          if (data?.proveedor_servicio?.length > 0) {
            setLoadProveedor(true);
            let inputs = [];
            data.proveedor_servicio.map((proveedor, i) => {
              setLoadProveedor(true);
              inputs.push(<Proveedores indice={i} />);

              dataProvincias(i, proveedor);
              dataLocalidades(proveedor.provincia, i, proveedor);
              fetchProveedores(proveedor.localidad, i, proveedor);
            });

            setInputProveedores(inputs);
          }

          setServicio(data);
        });
      };

      const tiposDocs = async () =>
        await getTiposDoc().then((data) => {
          setTiposDocs(data.results);
        });

      tiposDocs();
      fetchServicio();
    }
    if (formik.values.fallecido_carencia === false) {
      toast.warn(({ closeToast }) => (
        <div className="texto_toastify">
          <strong>
            No se puede proseguir. El beneficiario ${}no esta dentro de la
            carencia
          </strong>
        </div>
      ));
    }
    const fetchDataArt = async () => {
      const art = await getArt();
      let artProcess = art.results.map((x) => {
        return { key: x.id, text: x.nombre, value: x.id };
      });

      dispatch({ type: "SET_ART", payload: artProcess });
    };
    fetchDataArt();
    const fetchDataCiaSeguros = async () => {
      const cia_seguros = await getCiaSeguros();
      let cia_seguros_process = cia_seguros.results.map((x) => {
        return { key: x.id, text: x.nombre, value: x.id };
      });
      dispatch({ type: "SET_CIA_SEGUROS", payload: cia_seguros_process });
    };
    fetchDataCiaSeguros();
    fetchProvincias();
  }, []);

  const fetchLocalidades = async (provincia) => {
    const ciudades = await getLocalidades(`provincia=${provincia}`).then(
      (data) => {
        let localidades_process = data.results.map((x) => {
          return { key: x.id, text: x.nombre, value: x.id };
        });
        return localidades_process;
      }
    );

    return ciudades;
  };

  const dataLocalidades = async (provincia, indice, proveedor = null) => {
    let localidades_process = await fetchLocalidades(provincia);
    let locAux = formik.values.proveedor_localidades;
    let localidad_id = proveedor?.localidad || "";
    //let localidad_id = proveedor?.localidad || data.results[0].id
    if (localidad_id !== "") locAux[indice] = localidad_id;

    formik.setFieldValue("proveedor_localidades", locAux);
    citiesOptions[indice] = localidades_process;
    //fetchProveedores(localidad_id,indice,proveedor)
    dispatch({ type: "SET_LOCALIDAD", cities: citiesOptions });
  };

  const fetchProveedores = async (localidad, indice, proveedor = null) => {
    await getProveedores(`localidad=${localidad}&is_active=1`).then((data) => {
      let proveedoresProcesados = data.map((x) => {
        return { key: x.id, text: x.razon_social, value: x.id, data: x };
      });

      proveedoresOptions[indice] = proveedoresProcesados;
      let provAux = formik.values.proveedor_proveedor;
      let servicioProveedorIds = formik.values.proveedor_servicio_proveedor;

      let provDataAux = formik.values.proveedor_proveedor_data;
      let trasladoAux = formik.values.proveedor_traslado;
      let lugarAux = formik.values.proveedor_lugar;
      let proveedor_cantKMAux = formik.values.proveedor_cantKM;
      let proveedor_valorKMAux = formik.values.proveedor_valorKM;
      let proveedor_sinIvaAux = formik.values.proveedor_sinIva;
      let proveedor_conIvaAux = formik.values.proveedor_conIva;
      let proveedor_checkValueWithIvaAux =
        formik.values.proveedor_checkValueWithIva;
      proveedor_cantKMAux[indice] = proveedor?.cantidad_km || 0;
      proveedor_valorKMAux[indice] = proveedor?.valor_km || 0;
      proveedor_sinIvaAux[indice] = proveedor?.valor_sin_iva || 0;
      proveedor_conIvaAux[indice] = proveedor?.valor_con_iva || 0;
      proveedor_checkValueWithIvaAux[indice] =
        proveedor?.valor_con_iva > 0 ? true : false;
      trasladoAux[indice] = proveedor?.traslado || "Ninguna";
      lugarAux[indice] = proveedor?.lugar || "Ninguna";
      let proveedor_id = proveedor?.proveedor?.id || "";
      if (proveedor_id !== "") provAux[indice] = proveedor_id;
      //provAux[indice] = proveedor?.proveedor?.id || data[0].id
      servicioProveedorIds[indice] = proveedor?.id || "";
      let contacto_proveedor_id = proveedor?.proveedor?.id || "";
      if (contacto_proveedor_id !== "")
        fetchContactoProveedor(contacto_proveedor_id, indice);

      if (proveedor !== null) provDataAux[indice] = proveedor?.proveedor;
      //provDataAux[indice] = proveedor === null ? data[0] : proveedor?.proveedor
      formik.setFieldValue("proveedor_proveedor", provAux);
      formik.setFieldValue(
        "proveedor_servicio_proveedor",
        servicioProveedorIds
      );
      formik.setFieldValue("proveedor_proveedor_data", provDataAux);
      formik.setFieldValue("proveedor_traslado", trasladoAux);
      formik.setFieldValue("proveedor_lugar", lugarAux);
      formik.setFieldValue("proveedor_cantKM", proveedor_cantKMAux);
      formik.setFieldValue("proveedor_valorKM", proveedor_valorKMAux);
      formik.setFieldValue("proveedor_sinIva", proveedor_sinIvaAux);
      formik.setFieldValue("proveedor_conIva", proveedor_conIvaAux);
      formik.setFieldValue(
        "proveedor_checkValueWithIva",
        proveedor_checkValueWithIvaAux
      );

      formik.setFieldValue(
        `proveedor_direccion_${indice}`,
        proveedor?.proveedor?.calle || ""
      );

      formik.setFieldValue(
        `proveedor_telefono_${indice}`,
        proveedor?.proveedor?.telefono || ""
      );
      formik.setFieldValue(
        `proveedor_email_${indice}`,
        proveedor?.proveedor?.email || ""
      );

      dispatch({
        type: "UPDATE_PROVIDER",
        indice: 0,
        proveedoresOptions: proveedoresOptions,
        proveedores: proveedores,
      });
    });
  };

  const fetchProveedor = async (proveedor, indice) => {

    await fetchContactoProveedor(proveedor, indice);

    await getProveedor(proveedor).then((data) => {
      let provProv = formik.values.proveedor_proveedor;
      provProv[indice] = proveedor;
      formik.setFieldValue("proveedor_proveedor", provProv);

      let provDataAux = formik.values.proveedor_proveedor_data;
      provDataAux[indice] = data;
      formik.setFieldValue("proveedor_proveedor_data", provDataAux);

      formik.setFieldValue(`proveedor_direccion_${indice}`, data?.calle);

      formik.setFieldValue(`proveedor_telefono_${indice}`, data?.telefono);
      formik.setFieldValue(`proveedor_email_${indice}`, data?.email);
    });
  };

  const fetchContactoProveedor = async (proveedor, indice) => {
    await getContactoProveedor(`proveedor=${proveedor}`).then((data) => {
      let contacto_process =
        data.results.length > 0
          ? data.results.map((x) => {
              return {
                key: x.id,
                text: x.nombre + " " + x.apellido,
                value: x.id,
              };
            })
          : [{ key: 0, text: "No hay resultados", value: "" }];

      let contacto = formik.values.proveedor_contacto;
      let contacto_proveedor_id =
        data.results.length > 0 ? data.results[0]?.id : "";
      if (contacto_proveedor_id !== "") contacto[indice] = data.results[0]?.id;

      contactoOptions[indice] = contacto_process;
      dispatch({ type: "SET_CONTACTO_PROVEEDOR", payload: contactoOptions });
      formik.setFieldValue("proveedor_contacto", contacto);
    });
  };
  const setValueWithoutIva = (value, indice) => {
    let sinIvaAux = formik.values.proveedor_sinIva;
    sinIvaAux[indice] = value;
    formik.setFieldValue("proveedor_sinIva", sinIvaAux);
  };

  const checkIva = (data, indice) => {
    let proveedor_checkValueWithIvaAux =
      formik.values.proveedor_checkValueWithIva;
    proveedor_checkValueWithIvaAux[indice] = data.checked;
    formik.setFieldValue(
      "proveedor_checkValueWithIva",
      proveedor_checkValueWithIvaAux
    );
    let proveedor_conIvaAux = formik.values.proveedor_conIva;
    if (data.checked) {
      proveedor_conIvaAux[indice] =
        formik.values.proveedor_sinIva[indice] * 1.21;
    } else {
      proveedor_conIvaAux[indice] = 0;
    }
    formik.setFieldValue("proveedor_conIva", proveedor_conIvaAux);
  };

  const setInputKM = (value, indice) => {
    let cantKMAux = formik.values.proveedor_cantKM;
    let valorKMAux = formik.values.proveedor_valorKM;
    cantKMAux[indice] = value;
    formik.setFieldValue("proveedor_cantKM", cantKMAux);

    valorKMAux[indice] =
      formik.values.proveedor_conIva[indice] !== 0
        ? formik.values.proveedor_conIva[indice] / value
        : formik.values.proveedor_sinIva[indice] / value;
    formik.setFieldValue("proveedor_valorKM", valorKMAux);
  };

  const selectProvincia = (value, indice) => {
    /* INICIO LOGICA DE PROVINCIAS */

    let provinciasAux = formik.values.proveedor_provincias;

    provinciasAux[indice] = value;

    formik.setFieldValue("proveedor_provincias", provinciasAux);
    /* FIN LOGICA DE PROVINCIAS */

    /* INICIO LOGICA DE LOCALIDADES */
    dataLocalidades(value, indice);
  };
  const selectCiudad = (value, indice) => {
    /* INICIO LOGICA DE LOCALIDADES */
    let city = formik.values.proveedor_localidades;

    city[indice] = value;
    formik.setFieldValue("proveedor_localidades", city);
    //const cityOption = citiesOptions[indice].filter(e=>e.value===value)[0]
    /* FIN LOGICA DE LOCALIDADES */
    fetchProveedores(value, indice);
  };

  const setProveedorData = (value, name, indice) => {
    formik.setFieldValue(`proveedor_direccion_${indice}`, value);
  };
  const selectProveedor = async (prov, indice) => {
    await fetchProveedor(prov, indice);
  };
  const selectContactoProveedor = (proveedor, indice) => {
    fetchContactoProveedor(proveedor, indice);
  };
  const setearCamposVacios = (boolean) => {
    dispatch({ type: "CLEAR_INPUT" });
  };
  const resultSelect = React.useCallback(async (e, data) => {
    if (data.result.carencia === false) {
      setearCamposVacios(true);
      toast.warn(({ closeToast }) => (
        <div className="texto_toastify">
          <strong>
            No se puede proseguir. El beneficiario {formik.values.fallecido_dni}{" "}
            no esta dentro de la carencia
          </strong>
        </div>
      ));
      formik.setFieldValue("fallecido_dni", "");
    } else {
      const convenio = await getConvenio(`id=${data.result.aseguradora}`);

      data.result.aseguradora = convenio.results[0]?.id;
      data.result.aseguradora_name = convenio.results[0]?.nombre;
      formik.setFieldValue("fallecido_dni", data.result.dni);
      formik.setFieldValue("fallecido_nombre", data.result.nombre);
      formik.setFieldValue("fallecido_apellido", data.result.apellido);
      formik.setFieldValue("fallecido_convenio", convenio.results[0]?.nombre);
      formik.setFieldValue(
        "fallecido_inicio_vigencia",
        data?.result?.vigencia !== null
          ? Moment(data.result.vigencia).format("DD-MM-YYYY")
          : data.result.vigencia
      );

      if (data.result.domicilio !== null)
        formik.setFieldValue(
          "fallecido_direccion_residencia",
          data.result.domicilio
        );

      formik.setFieldValue("fallecido_carencia", data.result.carencia);
      formik.setFieldValue("fallecido_poliza", data.result.poliza);
      formik.setFieldValue("cliente", data.result.id);
      formik.setFieldValue("fallecido_tipo_servicio", data.result.tipo_servicio);
      dispatch({
        type: "UPDATE_SELECTION",
        selection: data.result,
        icon: "remove",
      });
    }
  });
  const removeItem = (item, indice) => {
    setInputProveedores(item);
    formik.values.proveedor_conIva.splice(indice, 1);

    citiesOptions.splice(indice, 1);
    dispatch({ type: "SET_LOCALIDAD", cities: citiesOptions });
    contactoOptions.splice(indice, 1);
    proveedoresOptions.splice(indice, 1);

    dispatch({ type: "SET_CONTACTO_PROVEEDOR", payload: contactoOptions });
    dispatch({
      type: "UPDATE_PROVIDER",
      indice: indice,
      proveedoresOptions: proveedoresOptions,
    });
  };

  const handleChangeVinculo = (e, data) => {
    formik.setFieldValue("contacto_vinculo", data.value);
    if (data.value === 1) {
      formik.setFieldValue("contacto_cia", "");
      formik.setFieldValue("contacto_art", "");
    }
    if (data.value === 2) {
      formik.setFieldValue("contacto_cia", "");
    }

    if (data.value === 3) {
      formik.setFieldValue("contacto_art", "");
    }
  };

  const addProv = (e) => {
    if (inputProveedores.length === 2) {
      toast.error("No se pueden agregar más de 2 Proveedores.");
    } else {
      setLoadProveedor(true);

      setInputProveedores([
        ...inputProveedores,
        <Proveedores indice={inputProveedores.length} />,
      ]);
      dataProvincias(inputProveedores.length);
    }
  };

  const SegmentProveedor = (props) => {
    const { disabled } = props;
    return (
      <Grid.Row className="segment_border_primary">
        <Grid.Column computer={8} mobile={16} tablet={16}>
          <p>
            <strong>PROVEEDOR</strong>(Cochería)
          </p>
        </Grid.Column>
        <Grid.Column computer={5} mobile={16} tablet={16}>
          {" "}
        </Grid.Column>
        <Grid.Column computer={3} mobile={16} tablet={16}>
          <Button
            fluid
            circular
            disabled={disabled}
            onClick={addProv}
            color="green"
            basic
          >
            Agregar proveedor
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  };
  const handleUploadFile = async (event) => {
    var file = event.target.files[0];
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
      const response_pdf_upload = await fetchUploadFile(formData);

      formik.setFieldValue("url_autorizacion", response_pdf_upload.data[0]);
    }
  };
  const handleClickDeleteAutorizacion = (factura) => {
    formik.setFieldValue("url_autorizacion", null);
  };
  return (
    <Form onSubmit={formik.handleSubmit} style={{ display: "grid" }}>
      {servicio?.cliente ?
          <h4>Beneficiario: {servicio.cliente.nombre + " " + servicio?.cliente?.apellido}</h4>
      : ''}

      <Grid as={Segment}>
        {getPermissionsSecondary("estado", auth, type_action, "lizen") && (
            <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>Estado</h5>
            <Dropdown
              clearable
              //options={estados}
              options={optionsStatusByGroup(auth)}
              value={formik.values.estado}
              selection
              fluid
              onChange={(_, data) => formik.setFieldValue("estado", data.value)}
              disabled={disableStatusByGroup(auth, disabledGeneral)}
            />
          </Grid.Column>
        )}
        {getPermissionsSecondary("fecha", auth, type_action, "lizen") && (
          <Grid.Row>
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <h5>Fecha de servicio</h5>
              <Form.Input
                type="date"
                name="fecha"
                fluid
                value={formik.values.fecha}
                onChange={(_, data) =>
                  formik.setFieldValue("fecha", data.value)
                }
                disabled={disabledGeneral}
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      {getPermissionsSecondary(
        "segment_fallecido",
        auth,
        type_action,
        "lizen"
      ) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <p>
                <strong>DATOS DEL FALLECIDO</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getPermissionsSecondary(
              "cliente_dni",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>
                  * DNI{" "}
                  {!disabledGeneral && (
                    <Acciones
                      contentPopup="Agregar un beneficiario"
                      title=""
                      description="Acepte y vera reflejado el  nuevo beneficiario"
                      content="Agregua un nuevo beneficiario"
                      compact={true}
                      color="green"
                      nameIcon="add"
                      href={`${BASE_API}/admin/plani/clientes/add/`}
                      component={Link}
                      actionName="GetBeneficiario"
                      actionParams={{ dni: formik.values.fallecido_dni }}
                      callbackAcciones={handleSearchChange}
                    />
                  )}
                </h5>
                <Search
                  disabled={disabledGeneral}
                  input={{
                    name: "fallecido_dni",
                    icon: "search",
                    iconPosition: "left",
                    fluid: true,
                    error: formik.errors.fallecido_dni,
                  }}
                  loading={loading_search}
                  onResultSelect={resultSelect}
                  onSearchChange={(e, data) =>
                    handleSearchChange(e, data.value)
                  }
                  resultRenderer={resultRenderer}
                  results={results_search}
                  value={formik.values.fallecido_dni}
                  noResultsMessage="No se encontró el dni ingresado"
                  placeholder="Ingrese un DNI"
                  minCharacters={5}
                />
              </Grid.Column>
            )}

            {getPermissionsSecondary(
              "cliente_nombre",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Nombre</h5>
                <Form.Input
                  value={formik.values.fallecido_nombre}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "cliente_apellido",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={4} mobile={16} tablet={16}>
                <h5>Apellido</h5>
                <Form.Input
                  value={formik.values.fallecido_apellido}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            <Grid.Column computer={2} mobile={16} tablet={16}>
              {" "}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getPermissionsSecondary(
              "cliente_poliza",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Póliza</h5>
                <Form.Input
                  value={formik.values.fallecido_poliza}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "cliente_convenio_nombre",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Convenio</h5>
                <Form.Input
                  value={formik.values.fallecido_convenio}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "cliente_carencia",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Carencia</h5>
                <Form.Group inline>
                  {carencia.map((value, i) => (
                    <Form.Radio
                      label={value.text}
                      value={formik.values.fallecido_carencia}
                      checked={formik.values.fallecido_carencia === value.value}
                      disabled
                    />
                  ))}
                </Form.Group>
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "cliente_vigencia",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={4} mobile={16} tablet={16}>
                <h5>Inicio vigencia</h5>

                <Form.Input
                  value={formik.values.fallecido_inicio_vigencia}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            <Grid.Column computer={2} mobile={16} tablet={16}>
              {" "}
            </Grid.Column>
          </Grid.Row>
          {getPermissionsSecondary(
            "cliente_vigencia",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <h5>* Tipo servicio</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  placeholder="Seleccione un tipo de servicio"
                  name="cliente.tipo_servicio"
                  options={serviceTypeOptions}
                  value={formik.values.fallecido_tipo_servicio}
                  onChange={(_, data) => {
                    formik.setFieldValue("fallecido_tipo_servicio", data.value);
                  }}
                  selection
                  fluid
                  error={formik.errors.fallecido_tipo_servicio}
                />
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}
          {getPermissionsSecondary(
            "direccion_residencia",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <h5>* Dirección Residencia</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="fallecido_direccion_residencia"
                  fluid
                  placeholder="Ingrese una dirección de residencia"
                  value={formik.values.fallecido_direccion_residencia}
                  onChange={(_, data) =>
                    formik.setFieldValue(
                      "fallecido_direccion_residencia",
                      data.value
                    )
                  }
                  error={
                    formik.values.fallecido_direccion_residencia !== ""
                      ? false
                      : true
                  }
                />
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}
          {getPermissionsSecondary(
            "direccion_fallecimiento",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <h5>* Dirección Fallecimiento</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="fallecido_direccion_fallecimiento"
                  fluid
                  placeholder="Ingrese una dirección de fallecimiento"
                  value={formik.values.fallecido_direccion_fallecimiento}
                  onChange={(_, data) =>
                    formik.setFieldValue(
                      "fallecido_direccion_fallecimiento",
                      data.value
                    )
                  }
                  error={formik.errors.fallecido_direccion_fallecimiento}
                />
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}>
                <br></br>
                <br></br>
                <Checkbox
                  disabled={disabledGeneral}
                  label="Autocompletar con dirección de residencia?"
                  className="checkbox"
                  onChange={(_, data) => {
                    if (data.checked) {
                      formik.setFieldValue(
                        "fallecido_direccion_fallecimiento",
                        formik.values.fallecido_direccion_residencia
                      );
                    } /* else{ formik.setFieldValue("fallecido_direccion_fallecimiento","")} */
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            {getPermissionsSecondary(
              "servicio_provincia_nombre",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Provincia/Localidad del Servicio</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  placeholder="Seleccione una provincia"
                  name="provincia"
                  options={provincias}
                  value={formik.values.fallecido_provincia}
                  onChange={(_, data) => changeFallecidoProvincia(data.value)}
                  selection
                  fluid
                  error={formik.errors.fallecido_provincia}
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "servicio_localidad_nombre",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5 style={{ visibility: "hidden" }}>*Ciudad</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  placeholder="Seleccione una ciudad"
                  name="localidad"
                  options={ciudades_fallecido}
                  value={formik.values.fallecido_localidad}
                  onChange={(_, data) => {
                    formik.setFieldValue("fallecido_localidad", data.value);
                  }}
                  selection
                  fluid
                  error={formik.errors.fallecido_localidad}
                />
              </Grid.Column>
            )}
            <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getPermissionsSecondary("destino", auth, type_action, "lizen") && (
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <h5>Destino</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  clearable
                  options={destino}
                  value={formik.values.fallecido_destino}
                  selection
                  fluid
                  onChange={(_, data) =>
                    formik.setFieldValue("fallecido_destino", data.value)
                  }
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "velatorio",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <br></br>
                <br></br>
                <Checkbox
                  disabled={disabledGeneral}
                  label="Velatorio"
                  name="fallecido_velatorio"
                  id="fallecido_velatorio"
                  className="checkbox"
                  checked={formik.values.fallecido_velatorio}
                  onChange={formik.handleChange}
                />
              </Grid.Column>
            )}
            <Grid.Column computer={1} mobile={16} tablet={16}>
              {" "}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {getPermissionsSecondary(
        "segment_contacto",
        auth,
        type_action,
        "lizen"
      ) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <p>
                <strong>DATOS DE CONTACTO</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getPermissionsSecondary(
              "contacto_nombre_apellido",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Nombre y apellido</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="contacto_nombre_apellido"
                  value={formik.values.contacto_nombre_apellido}
                  fluid
                  placeholder="Ingrese nombre y apellido"
                  error={
                    isAddMode
                      ? formik.errors.contacto_nombre_apellido
                      : formik.values.contacto_nombre_apellido !== ""
                      ? false
                      : true
                  }
                  onChange={(_, data) =>
                    formik.setFieldValue("contacto_nombre_apellido", data.value)
                  }
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "contacto_telefono",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Teléfono</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="contacto_telefono"
                  value={formik.values.contacto_telefono}
                  type="number"
                  fluid
                  placeholder="Ingrese un teléfono"
                  error={
                    isAddMode
                      ? formik.errors.contacto_telefono
                      : formik.values.contacto_telefono !== ""
                      ? false
                      : true
                  }
                  onChange={(_, data) =>
                    formik.setFieldValue("contacto_telefono", data.value)
                  }
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "contacto_email",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={4} mobile={16} tablet={16}>
                <h5>Email</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="contacto_email"
                  value={formik.values.contacto_email}
                  fluid
                  placeholder="Ingrese un email"
                  onChange={(_, data) =>
                    formik.setFieldValue("contacto_email", data.value)
                  }
                  error={formik.errors.contacto_email}
                />
              </Grid.Column>
            )}
            <Grid.Column computer={2} mobile={16} tablet={16}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getPermissionsSecondary(
              "contacto_vinculo",
              auth,
              type_action,
              "lizen"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Vínculo</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  options={vinculoOptions}
                  selection
                  fluid
                  value={formik.values.contacto_vinculo}
                  name="contacto_vinculo"
                  onChange={handleChangeVinculo}
                />
              </Grid.Column>
            )}
            {(formik.values.contacto_vinculo === 1 ||
              formik.values.contacto_vinculo === "") &&
              getPermissionsSecondary(
                "contacto_vinculo",
                auth,
                type_action,
                "lizen"
              ) && (
                <Grid.Column
                  computer={11}
                  mobile={16}
                  tablet={16}
                ></Grid.Column>
              )}
            {formik.values.contacto_vinculo === 2 &&
              getPermissionsSecondary(
                "contacto_art",
                auth,
                type_action,
                "lizen"
              ) && (
                <React.Fragment>
                  <Grid.Column computer={5} mobile={16} tablet={16}>
                    <h5>ART</h5>
                    <Dropdown
                      disabled={disabledGeneral}
                      options={art}
                      scrolling
                      selection
                      error={formik.errors.contacto_art}
                      value={formik.values.contacto_art}
                      name="contacto_art"
                      onChange={(_, data) =>
                        formik.setFieldValue("contacto_art", data.value)
                      }
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={6}
                    mobile={16}
                    tablet={16}
                  ></Grid.Column>
                </React.Fragment>
              )}
            {formik.values.contacto_vinculo === 3 &&
              getPermissionsSecondary(
                "contacto_compania_seguro",
                auth,
                type_action,
                "lizen"
              ) && (
                <React.Fragment>
                  <Grid.Column computer={5} mobile={16} tablet={16}>
                    <h5>Compañía de Seguros</h5>
                    <Dropdown
                      disabled={disabledGeneral}
                      options={ciaSeguros}
                      scrolling
                      selection
                      error={formik.errors.contacto_cia}
                      value={formik.values.contacto_cia}
                      name="contacto_cia"
                      onChange={(_, data) =>
                        formik.setFieldValue("contacto_cia", data.value)
                      }
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={6}
                    mobile={16}
                    tablet={16}
                  ></Grid.Column>
                </React.Fragment>
              )}
          </Grid.Row>
          <br></br>
          {getPermissionsSecondary(
            "contacto_detalle_vinculo",
            auth,
            type_action,
            "lizen"
          ) && (
            <Grid.Row>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <h5>Detalle vínculo</h5>
                <TextArea
                  disabled={disabledGeneral}
                  value={formik.values.contacto_detalle}
                  name="contacto_detalle"
                  onChange={(_, data) =>
                    formik.setFieldValue("contacto_detalle", data.value)
                  }
                  placeholder="Indique la relación con el fallecido"
                />
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}

      {getPermissionsSecondary(
        "segment_proveedor",
        auth,
        type_action,
        "lizen"
      ) && (
        <Grid as={Segment}>
          <SegmentProveedor disabled={disabledGeneral} />

          {loadProveedor &&
            inputProveedores.map((data, i) => (
              <Grid as={Segment}>
                <Grid.Row className="segment_border_secondary">
                  <Proveedores
                    auth={auth}
                    type_action={type_action}
                    disabled={disabledGeneral}
                    inputProveedores={inputProveedores}
                    formik={formik}
                    countProveedores={inputProveedores.length}
                    key={i}
                    indice={i}
                    city={formik.values.proveedor_localidades}
                    getProvinceAR={provincias}
                    citiesOptions={citiesOptions}
                    provinciaSelected={formik.values.proveedor_provincias}
                    proveedoresOptions={proveedoresOptions}
                    proveedor={formik.values.proveedor_proveedor}
                    proveedoresData={formik.values.proveedor_proveedor_data}
                    traslado={formik.values.proveedor_traslado}
                    lugar={formik.values.proveedor_lugar}
                    trasladoOptions={trasladoOptions}
                    lugarOptions={lugarOptions}
                    conIva={formik.values.proveedor_conIva}
                    sinIva={formik.values.proveedor_sinIva}
                    checkValueWithIva={
                      formik.values.proveedor_checkValueWithIva
                    }
                    cantKM={formik.values.proveedor_cantKM}
                    valorKM={formik.values.proveedor_valorKM}
                    contacto={formik.values.proveedor_contacto}
                    contactoOptions={contactoOptions}
                    callbackProvincia={selectProvincia}
                    callbackCiudad={selectCiudad}
                    callbackProveedor={selectProveedor}
                    callbackWithoutIva={setValueWithoutIva}
                    callbackCheckIva={checkIva}
                    callbackInputKM={setInputKM}
                    callbackDireccion={setProveedorData}
                    callbackRemoveItem={removeItem}
                    callbackContactoProveedor={selectContactoProveedor}
                  />{" "}
                </Grid.Row>
              </Grid>
            ))}
        </Grid>
      )}
      <Grid as={Segment}>
        <Grid.Row className="segment_border_primary">
          {getPermissionsSecondary(
            "url_autorizacion",
            auth,
            type_action,
            "lizen"
          ) && (
            <Fragment>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                {formik.values.url_autorizacion !== null ? (
                  <>
                    <h5> Autorización</h5>
                  </>
                ) : (
                  !disabledGeneral && (
                    <>
                      {" "}
                      <h5> Adjuntar Autorización</h5>
                      <input
                        disabled={disabledGeneral}
                        accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
                        type="file"
                        onChange={handleUploadFile}
                      />{" "}
                    </>
                  )
                )}
              </Grid.Column>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <Grid as={Segment} style={{ padding: "10px" }}>
                  {formik.values.url_autorizacion !== null && (
                    <>
                      <p>
                        <a
                          href={`${BASE_API}/${formik.values.url_autorizacion}`}
                          target="_blank"
                          rel="noreferrer"
                        >{`${formik.values.url_autorizacion
                          .split("/")[2]
                          .slice(32)}`}</a>

                        {!disabledGeneral && (
                          <Button
                            compact={true}
                            icon
                            basic
                            target="_blank"
                            circular
                            size="mini"
                            onClick={handleClickDeleteAutorizacion}
                          >
                            <Icon name="delete" color="red" />
                          </Button>
                        )}
                      </p>
                    </>
                  )}
                  {getPermissionsSecondary(
                    "nota_importancia",
                    auth,
                    type_action,
                    "lizen"
                  ) && (
                    <Grid.Row>
                      <Grid.Column computer={16} mobile={16} tablet={16}>
                        <label>Nota</label>
                      </Grid.Column>
                      <Grid.Column computer={10} mobile={16} tablet={16}>
                        <TextArea
                          disabled={disabledGeneral}
                          name="nota"
                          value={formik.values.notas}
                          onChange={(_, data) =>
                            formik.setFieldValue("notas", data.value)
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
            </Fragment>
          )}
        </Grid.Row>
        <Grid.Row className="segment_border_primary">
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <h5 style={{ marginBottom: "5px" }}>Más Documentos</h5>
            {!disabledGeneral && (
              <>
                <Select
                  name="tipo_documento"
                  placeholder="Seleccionar Tipo"
                  options={tiposDocs.map((x) => {
                    return { key: x.id, value: x.id, text: x.nombre };
                  })}
                  onChange={handleTipoDocChange}
                  value={tipoDocumento}
                />
                <input
                  className="input"
                  accept="'image/jpg'"
                  type="file"
                  style={{ marginTop: "5px" }}
                  onChange={(e) => handleChange(e)}
                  disabled={disabledGeneral || !tipoDocumento}
                />
              </>
            )}
            {getPermissionsSecondary(
              "url_autorizacion",
              auth,
              type_action,
              "lizen"
            ) && (
              <Fragment>
                <Grid.Column computer={10} mobile={16} tablet={16}>
                    {documentos.length > 0 &&
                      getPermissionsSecondary(
                        "nota_importancia",
                        auth,
                        type_action,
                        "lizen"
                      ) &&
                      documentos.map((doc) => {
                        return (
                          <Grid as={Segment} style={{ padding: "10px" }}>                            
                            <a
                              style={
                                doc.deleted
                                  ? { textDecoration: "line-through" }
                                  : {}
                              }
                              href={`${BASE_API}/${doc.url_archivo}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {doc.tipo_documento_nombre}:{" "}
                              {doc.url_archivo.split("/")[2].slice(32)}
                            </a>
                            {!disabledGeneral && (
                              <Button
                                style={{ margin: "5px 5px" }}
                                size="mini"
                                type="button"
                                icon
                                negative
                                basic
                                onClick={(e) => handleDocDelete(doc.id)}
                              >
                                <Icon name="close" />
                              </Button>
                            )}
                            <Grid.Row>
                              <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                              >
                                <label>Nota</label>
                              </Grid.Column>
                              <Grid.Column
                                computer={16}
                                mobile={16}
                                tablet={16}
                              >
                                <TextArea
                                  disabled={disabledGeneral}
                                  name={`nota_${doc.id}`}
                                  value={doc.comentario}
                                  onChange={(_, data) =>
                                    handleDocChange(data.value, doc.id)
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                            </Grid>
                        );
                      })}
                </Grid.Column>
                <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
              </Fragment>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Column computer={2} mobile={16} tablet={16}>
          <Button
            fluid
            disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting}
            type="submit"
            content={isAddMode ? "Guardar" : "Actualizar"}
            onClick={() => handleSave()}
            primary
          />
        </Grid.Column>
        <Grid.Column computer={2} mobile={16} tablet={16}>
          <Button
            fluid
            content="Volver"
            primary
            component={Link}
            href="/admin/servicios_lizen/listado"
          />
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} tablet={16}></Grid.Column>
      </Grid>
    </Form>
  );
}

function initialValues(auth, servicio) {
  let proveedor_provincias = [];

  let proveedor_localidades = [];
  let proveedor_proveedor = [];
  let proveedor_servicio_proveedor = [];
  let proveedor_proveedor_data = [];

  let proveedor_traslado = [];
  let proveedor_lugar = [];
  let proveedor_conIva = [0, 0];
  let proveedor_sinIva = [0, 0];
  let proveedor_checkValueWithIva = [false, false];
  let proveedor_cantKM = [0, 0];
  let proveedor_valorKM = [0, 0];
  let proveedor_contacto = [];

  let prov_data = {
    proveedor_direccion_0: "",
    proveedor_direccion_1: "",
    proveedor_telefono_0: "",
    proveedor_telefono_1: "",
    proveedor_email_0: "",
    proveedor_email_1: "",
  };

  return {
    fallecido_nombre: servicio?.cliente?.nombre || "",
    fallecido_apellido: servicio?.cliente?.apellido || "",
    fallecido_velatorio: servicio?.velatorio || false,
    fallecido_destino: servicio?.destino || "No definido",
    fallecido_direccion_fallecimiento: servicio?.direccion_fallecimiento || "",
    fallecido_direccion_residencia: servicio?.direccion_residencia || "",
    fallecido_dni: servicio?.cliente?.dni || "",
    fallecido_tipo_servicio: servicio?.cliente?.tipo_servicio || "",
    cliente: servicio?.cliente?.id || "",
    fallecido_carencia: servicio?.cliente?.carencia || null,
    fallecido_poliza: servicio?.cliente?.poliza || null,
    fallecido_convenio: servicio?.cliente?.aseguradora__nombre || "",
    fallecido_inicio_vigencia:
      servicio?.cliente?.vigencia !== undefined
        ? Moment(servicio?.cliente?.vigencia).format("DD-MM-YYYY")
        : "",

    contacto_nombre_apellido:
      servicio?.lizen_contacto_servicio?.nombre_apellido || "",
    contacto_telefono: servicio?.lizen_contacto_servicio?.telefono || "",
    contacto_email: servicio?.lizen_contacto_servicio?.email || "",
    contacto_vinculo: parseInt(servicio?.lizen_contacto_servicio?.vinculo) || 1,
    contacto_art: servicio?.lizen_contacto_servicio?.art_id || "",
    contacto_cia: servicio?.lizen_contacto_servicio?.cia_seguro_id || "",
    contacto_detalle: servicio?.lizen_contacto_servicio?.detalle_vinculo || "",
    contacto_id: servicio?.lizen_contacto_servicio?.id || "",

    notas: servicio?.nota_importancia || "",
    url_autorizacion: servicio?.url_autorizacion || null,
    estado: servicio?.estado || "Activo",
    fecha: servicio?.fecha || null,
    operador: auth?.me?.id || "",

    proveedor_provincias: proveedor_provincias,
    proveedor_localidades: proveedor_localidades,
    proveedor_proveedor: proveedor_proveedor,

    proveedor_servicio_proveedor: proveedor_servicio_proveedor,
    proveedor_proveedor_data: proveedor_proveedor_data,
    proveedor_direccion_0: prov_data["proveedor_direccion_0"],
    proveedor_telefono_0: prov_data["proveedor_telefono_0"],
    proveedor_email_0: prov_data["proveedor_email_0"],
    proveedor_direccion_1: prov_data["proveedor_direccion_1"],
    proveedor_telefono_1: prov_data["proveedor_telefono_1"],
    proveedor_email_1: prov_data["proveedor_email_1"],
    proveedor_traslado: proveedor_traslado,
    proveedor_lugar: proveedor_lugar,
    proveedor_conIva: proveedor_conIva,
    proveedor_sinIva: proveedor_sinIva,
    proveedor_checkValueWithIva: proveedor_checkValueWithIva,
    proveedor_cantKM: proveedor_cantKM,
    proveedor_valorKM: proveedor_valorKM,
    proveedor_contacto: proveedor_contacto,
  };
}

function updateSchema(inputProveedores) {
  return {
    fallecido_velatorio: Yup.bool(),
    fallecido_direccion_fallecimiento: Yup.string().required(true),
    fallecido_provincia: Yup.number().required("Debe ingresar una provincia"),
    fallecido_localidad: Yup.string().required("Debe ingresar una localidad"),
    fallecido_direccion_residencia: Yup.string().required(true),
    fallecido_dni: Yup.string().required(true),
    contacto_nombre_apellido: Yup.string().required(true),
    contacto_telefono: Yup.number().required(true),
    contacto_vinculo: Yup.number().required(true),
    contacto_email: Yup.string().email("Formato inválido"),
    proveedor_provincias: Yup.array().length(inputProveedores.length),
    proveedor_localidades: Yup.array().length(inputProveedores.length),
    proveedor_proveedor: Yup.array().length(inputProveedores.length),
  };
}
function validationSchema(inputProveedores) {
  return {
    fallecido_velatorio: Yup.bool(),
    fallecido_direccion_residencia: Yup.string().required(
      "Debe ingresar una dirección"
    ),
    fallecido_direccion_fallecimiento: Yup.string().required(
      "Debe ingresar una dirección"
    ),
    fallecido_provincia: Yup.string().required("Debe ingresar una provincia"),
    fallecido_localidad: Yup.string().required("Debe ingresar una localidad"),
    fallecido_dni: Yup.string().required("Debe ingresar un DNI"),
    contacto_nombre_apellido: Yup.string().required(
      "Debe ingresar un nombre y apellido"
    ),
    contacto_telefono: Yup.number().required("Debe ingresar un teléfono"),
    contacto_vinculo: Yup.number().required("Debe ingresar un vínculo"),
    contacto_email: Yup.string().email("Formato inválido"),
    contacto_art: Yup.string().when("contacto_vinculo", {
      is: 2,
      then: Yup.string().required(true),
      otherwise: Yup.string(),
    }),
    contacto_cia: Yup.string().when("contacto_vinculo", {
      is: 3,
      then: Yup.string().required(true),
      otherwise: Yup.string(),
    }),
    proveedor_provincias: Yup.array().length(inputProveedores.length),
    proveedor_localidades: Yup.array().length(inputProveedores.length),
    proveedor_proveedor: Yup.array().length(inputProveedores.length),
  };
}
