import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './ListadoServicios.scss'
import { useAuth } from '../../../hooks';
import { getPermissions,getPermissionsSecondary } from '../../Common/Permissions';
export  function ListadoServicios(props) {
  const {servicios, onDeleteServicio,onEditServicio,onAddFacturacionServicio,onEditFacturacionServicio,pagination,onShowServicio,countPage,onOrdering, column,direction} = props
  
  const {auth} = useAuth()

  const class_name_sorted = direction === "ascending" ? "ascending sorted" :  "descending sorted"
  
  return (
    <Table sortable size='small' celled  className='tabla-servicios'>
    <Table.Header /* className='color_header_table' */>
   
      <Table.Row >
        
      {getPermissionsSecondary("id",auth,'list','lizen') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'id' ? direction : null} onClick={()=>onOrdering('id')}>ID</Table.HeaderCell>}
      {getPermissionsSecondary("fecha",auth,'list','lizen') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'fecha' ? direction : null} onClick={()=>onOrdering('fecha')}>Fecha / Hora Servicio</Table.HeaderCell>}
       {getPermissionsSecondary("cliente_dni",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__dni' ? direction : null} onClick={()=>onOrdering('cliente__dni')}>DNI </Table.HeaderCell>}
       {getPermissionsSecondary("cliente_nombre",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__nombre' ? direction : null} onClick={()=>onOrdering('cliente__nombre')}>Beneficiario</Table.HeaderCell>}
       {getPermissionsSecondary("contacto_nombre_apellido",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'lizen_contacto_servicio__nombre_apellido' ? direction : null} onClick={()=>onOrdering('lizen_contacto_servicio__nombre_apellido')}>Contacto</Table.HeaderCell>}
       {getPermissionsSecondary("proveedor_cocheria_razon_social",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'proveedor_servicio__proveedor__razon_social' ? direction : null} onClick={()=>onOrdering('proveedor_servicio__proveedor__razon_social')}>Proveedor(es)</Table.HeaderCell>}

       {getPermissionsSecondary("proveedor_valor_sin_iva",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'proveedor_servicio__valor_sin_iva' ? direction : null} onClick={()=>onOrdering('proveedor_servicio__valor_sin_iva')}>Valor sin IVA</Table.HeaderCell>}
       {getPermissionsSecondary("proveedor_valor_con_iva",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'proveedor_servicio__valor_con_iva' ? direction : null} onClick={()=>onOrdering('proveedor_servicio__valor_con_iva')}>Valor con IVA</Table.HeaderCell>}

       {getPermissionsSecondary("cliente_convenio_nombre",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__aseguradora__nombre' ? direction : null} onClick={()=>onOrdering('cliente__aseguradora__nombre')}>Convenio</Table.HeaderCell>}
       {getPermissionsSecondary("servicio_provincia_nombre",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'provincia__nombre' ? direction : null} onClick={()=>onOrdering('provincia__nombre')}>Provincia Servicio</Table.HeaderCell>}
       {getPermissionsSecondary("servicio_localidad_nombre",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'localidad__nombre' ? direction : null} onClick={()=>onOrdering('localidad__nombre')}>Localidad Servicio</Table.HeaderCell>}
       {getPermissionsSecondary("estado",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'estado' ? direction : null} onClick={()=>onOrdering('estado')}>Estado</Table.HeaderCell>}
       {getPermissionsSecondary("cliente_poliza",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__poliza' ? direction : null} onClick={()=>onOrdering('cliente__poliza')}>Póliza</Table.HeaderCell>}
       {getPermissionsSecondary("cliente_carencia",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__carencia' ? direction : null} onClick={()=>onOrdering('cliente__carencia')}>Carencia</Table.HeaderCell>}
       {getPermissionsSecondary("cliente_vigencia",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'cliente__vigencia' ? direction : null} onClick={()=>onOrdering('cliente__vigencia')}>Vigencia</Table.HeaderCell>}
       {getPermissionsSecondary("direccion_residencia",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'direccion_residencia' ? direction : null} onClick={()=>onOrdering('direccion_residencia')}>Dirección residencia</Table.HeaderCell>}
       {getPermissionsSecondary("direccion_fallecimiento",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'direccion_fallecimiento' ? direction : null} onClick={()=>onOrdering('direccion_fallecimiento')}>Dirección fallecimiento</Table.HeaderCell>}
       {getPermissionsSecondary("destino",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'destino' ? direction : null} onClick={()=>onOrdering('destino')}>Destino</Table.HeaderCell>}
       {getPermissionsSecondary("contacto_telefono",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'lizen_contacto_servicio__telefono' ? direction : null} onClick={()=>onOrdering('lizen_contacto_servicio__telefono')}>Contacto teléfono</Table.HeaderCell>}
       {getPermissionsSecondary("contacto_email",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'lizen_contacto_servicio__email' ? direction : null} onClick={()=>onOrdering('lizen_contacto_servicio__email')}>Contacto email</Table.HeaderCell>}
       {getPermissionsSecondary("contacto_vinculo",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'lizen_contacto_servicio__vinculo' ? direction : null} onClick={()=>onOrdering('lizen_contacto_servicio__vinculo')}>Contacto vínculo</Table.HeaderCell>}

       {getPermissionsSecondary("contacto_detalle_vinculo",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'lizen_contacto_servicio__detalle_vinculo' ? direction : null} onClick={()=>onOrdering('lizen_contacto_servicio__detalle_vinculo')}>Contacto detalle</Table.HeaderCell>}
       {getPermissionsSecondary("url_autorizacion",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'url_autorizacion' ? direction : null} onClick={()=>onOrdering('url_autorizacion')}>Url autorización</Table.HeaderCell>}
       {getPermissionsSecondary("nota_importancia",auth,'list','lizen') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'nota_importancia' ? direction : null} onClick={()=>onOrdering('nota_importancia')}>Nota</Table.HeaderCell>}
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {servicios.map((servicio,i)=>{
          Moment.locale('en');
         const proveedores = servicio.proveedor_servicio.map(prov=>(`${prov?.proveedor?.razon_social} (Tipo de servicio: ${prov.traslado})`))
         const valores_sin_iva = servicio.proveedor_servicio.map(prov=>(`${prov?.valor_sin_iva}`))
         const valores_con_iva = servicio.proveedor_servicio.map(prov=>(`${prov?.valor_con_iva}`))
        
        return(<Table.Row key={servicio.id}>
         
            {getPermissionsSecondary("id",auth,'list','lizen') && <Table.Cell> <Icon name='clock' /> {servicio?.id}</Table.Cell>}
            {getPermissionsSecondary("fecha",auth,'list','lizen') && <Table.Cell className='fecha_hora'> <Icon name='clock' /> {Moment(servicio.created_at).format('DD-MM-YYYY H:mma')}</Table.Cell>}
            {getPermissionsSecondary("cliente_dni",auth,'list','lizen') && <Table.Cell>{servicio.cliente.dni}</Table.Cell>}
            {getPermissionsSecondary("cliente_nombre",auth,'list','lizen') && <Table.Cell>{servicio.cliente.nombre} {servicio.cliente.apellido}</Table.Cell>}
            {getPermissionsSecondary("contacto_nombre_apellido",auth,'list','lizen') && <Table.Cell>{servicio.lizen_contacto_servicio.nombre_apellido}</Table.Cell>}
            {getPermissionsSecondary("proveedor_cocheria_razon_social",auth,'list','lizen') && <Table.Cell>{proveedores.toString()}</Table.Cell>}
            {getPermissionsSecondary("proveedor_valor_sin_iva",auth,'list','lizen') && <Table.Cell>{valores_sin_iva.toString()}</Table.Cell>}
            {getPermissionsSecondary("proveedor_valor_con_iva",auth,'list','lizen') && <Table.Cell>{valores_con_iva.toString()}</Table.Cell>}
            {getPermissionsSecondary("cliente_convenio_nombre",auth,'list','lizen') &&  <Table.Cell > {servicio.cliente.aseguradora__nombre}</Table.Cell>}
            {getPermissionsSecondary("servicio_provincia_nombre",auth,'list','lizen') && <Table.Cell > {servicio?.provincia?.nombre}</Table.Cell>}
            {getPermissionsSecondary("servicio_localidad_nombre",auth,'list','lizen') &&<Table.Cell > {servicio?.localidad?.nombre}</Table.Cell>}
            {getPermissionsSecondary("estado",auth,'list','lizen') &&<Table.Cell > {servicio?.estado}</Table.Cell>}

            {getPermissionsSecondary("cliente_poliza",auth,'list','lizen') &&  <Table.Cell>{servicio?.cliente?.poliza}</Table.Cell>}
            {getPermissionsSecondary("cliente_carencia",auth,'list','lizen') &&  <Table.Cell>{servicio?.cliente?.carencia}</Table.Cell>}
            {getPermissionsSecondary("cliente_vigencia",auth,'list','lizen') &&  <Table.Cell>{servicio?.cliente?.vigencia}</Table.Cell>}
            {getPermissionsSecondary("direccion_residencia",auth,'list','lizen') &&  <Table.Cell>{servicio?.direccion_residencia}</Table.Cell>}
            {getPermissionsSecondary("direccion_fallecimiento",auth,'list','lizen') &&  <Table.Cell>{servicio?.direccion_fallecimiento}</Table.Cell>}
            {getPermissionsSecondary("destino",auth,'list','lizen') &&  <Table.Cell>{servicio?.destino}</Table.Cell>}
            {getPermissionsSecondary("contacto_telefono",auth,'list','lizen') &&  <Table.Cell>{servicio?.lizen_contacto_servicio?.telefono}</Table.Cell>}
            {getPermissionsSecondary("contacto_email",auth,'list','lizen') &&  <Table.Cell>{servicio?.lizen_contacto_servicio?.email}</Table.Cell>}
            {getPermissionsSecondary("contacto_vinculo",auth,'list','lizen') &&  <Table.Cell>{servicio?.lizen_contacto_servicio?.vinculo}</Table.Cell>}
            {getPermissionsSecondary("contacto_detalle_vinculo",auth,'list','lizen') &&  <Table.Cell>{servicio?.detalle_vinculo}</Table.Cell>}
            {getPermissionsSecondary("url_autorizacion",auth,'list','lizen') &&  <Table.Cell>{servicio?.url_autorizacion}</Table.Cell>}
            {getPermissionsSecondary("nota_importancia",auth,'list','lizen') &&  <Table.Cell>{servicio?.nota_importancia}</Table.Cell>}



         <Acciones auth={auth} servicio={servicio} onShowServicio={onShowServicio} onDeleteServicio={onDeleteServicio} onEditServicio={onEditServicio} onAddFacturacionServicio={onAddFacturacionServicio} onEditFacturacionServicio={onEditFacturacionServicio}/>
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {servicio,onDeleteServicio,onEditServicio, onAddFacturacionServicio,onEditFacturacionServicio ,onShowServicio,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_lizenservicio",auth) &&
    <Popup content='Ver servicio' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowServicio(servicio)}>
    <Icon name='eye' />
  </Button>} />}
        {(getPermissions("add_lizenfacturacion",auth) && servicio.lizen_facturacion === null) &&
      <Popup content='Agregar facturación' trigger={<Button size='mini' icon basic onClick={()=>onAddFacturacionServicio(servicio)}>
      <Icon name='file' />
    </Button>} />  }
      {(getPermissions("change_lizenfacturacion",auth) && servicio.lizen_facturacion !== null) && 
      <Popup content='Editar facturación' trigger={<Button size='mini' icon basic onClick={()=>onEditFacturacionServicio(servicio)}>
      <Icon name='file' />
    </Button>} />  }
      {getPermissions("change_lizenservicio",auth) &&
      <Popup content='Editar servicio' trigger={<Button size='mini' icon basic onClick={()=>onEditServicio(servicio)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_lizenservicio",auth) &&
      <Popup content='Eliminar servicio' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteServicio(servicio)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}