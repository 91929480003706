import React from 'react'
import { Button,Grid } from 'semantic-ui-react'
import { getPermissions } from '../../Common'
import { useAuth } from '../../../hooks'
import './HeaderPage.scss'
export  function HeaderPage(props) {
  const {title,btnTitle,btnClick,permitionName} = props
  const {auth} = useAuth()
  return (
    <Grid /* className='header-page' */>
    <Grid.Row >
      <Grid.Column computer={4} mobile={16} tablet={16}>
    
        <h2>{title}</h2>
        
    
    </Grid.Column>
    <Grid.Column computer={12} mobile={16} tablet={16}></Grid.Column>
    
   {/*  <Grid.Column computer={2} mobile={16} tablet={16}>
    {(btnTitle &&  getPermissions(permitionName,auth)) &&(
                <Button circular basic positive onClick={btnClick}>
                    {btnTitle}
                </Button>
            )}
    </Grid.Column> */}
    </Grid.Row>
    </Grid>
  )
}
