import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'

export async function getFacturacionOrion(request_get = null){
    try {
        
        const url = `${BASE_API}/api-orion/facturacion/${request_get}/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        const result= await response.json()
          
        return result
        
    } catch (error) {
        throw new Error ("Error")
    }
}

export async function createOrionFacturacion(request_post){
    const url =  `${BASE_API}/api-orion/facturacion/`
    const request = {
        servicio: request_post.servicio
    }
    const params = {
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(request)
    }   
    const response = await fetch(url,params)

    const result= await response.json()

    return result
}

export async function createOrionFacturacionArchivo(factura,path_file_facturas){
    if(path_file_facturas.length === 0)
        return null

    const url =  `${BASE_API}/api-orion/facturacion_archivo/`
  
    path_file_facturas.map(async (file_factura)=>{
        let request = {
            "url_archivo":file_factura,
            "factura": factura.id
        }
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
        const result= await response.json()
    
    })
    return {"status" : "sucess"}
}

export async function addFacturacionOrion(request_post){
    try {
        /* 
        FACTURA
        */
        const  response_createFacturacion = await createOrionFacturacion(request_post)

        /* 
        ARCHIVO FACTURA
        */
        let response_createFactura = await  createOrionFacturacionArchivo(response_createFacturacion,request_post.url_archivo)

        return {status:"sucess"} 
    } catch (error) {
        return error
        throw error
        
    }
}

export async function editFacturacionOrion(id,request_put){
    try {
      
        const url =  `${BASE_API}/api-orion/facturacion/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request_put)
        }
        const response = await fetch(url,params)

        const result= await response.json()

        return result
    } catch (error) {
        throw error
    }
}

export async function getFacturacionArchivo(request_get = null){
    try {
        
        const url = `${BASE_API}/api-orion/facturacion_archivo/?${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        const result= await response.json()
          
        return result
        
    } catch (error) {
        throw new Error ("Error")
    }
}


export async function deleteFacturacionArchivo(id){
    try {
        
        
        const url =  `${BASE_API}/api-orion/facturacion_archivo/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 204){
            return {status: "sucess"}
        }
      
        return {status: "error"}
    } catch (error) {
        throw error
    }
}