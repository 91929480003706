import React from 'react'

export default function Reducer(state,action) {
    const initialState = {
		loading_search: false,
		results_search: [],
		value_search: '',
		carencia_search:"No aplica",
		nombre_client : "",
		apellido_client : "",
		aseguradora_client : "",
		vigencia_client :"",
		domicilio_client : "",
		nombre_client : "",
		apellido_client : "",
		aseguradora_client : "",
		carencia_client : "",
		vigencia_client : "",
		domicilio_client : ""
    }
    switch (action.type) {
        case 'CLEAN_QUERY':
          return initialState
        case 'START_SEARCH':
			
			
          return { ...state, loading_search: true, value_search: action.query}
        case 'FINISH_SEARCH':
          return { ...state, loading_search: false, results_search: action.results}
        case 'UPDATE_SELECTION':
          return { ...state, value_search: action.selection.dni,icon_search:action.icon }
      
		case "CLEAR_INPUT":
			initialState.nombre_client = ""
			initialState.apellido_client = ""
			initialState.aseguradora_client = ""
			initialState.carencia_client = ""
			initialState.vigencia_client = ""
			initialState.domicilio_client = ""
			
          return { ...state, loading_search: false,value_search: "",nombre_client:initialState.nombre_client,apellido_client:initialState.apellido_client,icon_search:action.icon, aseguradora_client: initialState.aseguradora_client,carencia_client: initialState.carencia_client,vigencia_client: initialState.vigencia_client,domicilio_client: initialState.domicilio_client  }
		case "INICIALIZAR_CAMPO_SIN_IVA":
			
			state.sinIva[action.indice] = action.provData.valor_acordado
			
			return { ...state }
		 

		case "UPDATE_PROVIDER":
			
			if(action.provData)
				state.proveedoresData = action.provData

			if(action.proveedoresOptions )
		    	state.proveedoresOptions = action.proveedoresOptions

			if(action.proveedores)
				state.proveedores = action.proveedores

			if(action.proveedor)
				state.proveedor = action.proveedor
			return {...state}

		case "UPDATE_PROVEEDOR":
			//state.city[action.indice] = action.city
			return {...state}
		case "UPDATE_TRASLADO":
			
			state.traslado[action.indice] = action.traslado
			return {...state}
		
		case "SET_ART":
			state.art = action.payload
			return {...state}
		case "SET_CONTACTO_PROVEEDOR":
			state.contactoOptions = action.payload
			return {...state}
		case "SET_CIA_SEGUROS":
			state.ciaSeguros = action.payload
			return {...state}
		case "SET_PROVINCIA":
			if(action.provincias !== undefined)
				state.provincias = action.provincias


			return {...state}
		case "SET_LOCALIDAD":
			if(action.cities !== undefined)
				state.citiesOptions = action.cities
			
 		   return {...state}
		case "SET_LOCALIDAD_FALLECIDO":
			if(action.ciudades !== undefined)
				state.ciudades_fallecido = action.ciudades
			
 		   return {...state}

        default:
          throw new Error()
      }
}
