import React from 'react'
import './ForgotAdmin.scss'
import {ForgotForm} from '../../../components/Admin/LoginForm'
import { Container } from 'semantic-ui-react'
export function ForgotAdmin() {
  return (
    <Container>
    <div className='login-admin'>
      <div className='login-admin__content'>
      <h1 className='h1-login-form'>Olvidó su contraseña?</h1>
      <h2 className='h2-login-form'> Ingresa tu email para recuperar tu cuenta.</h2>
        <ForgotForm/>
      </div>
    
    </div>
    </Container>
  )
}
