import axios from "axios";
import { BASE_API } from "../../utils/constantes";

export async function loginApi(formValue) {
  try {
    const url = `${BASE_API}/api/auth/login/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };
    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error("Usuario o contraseña incorrectos");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function loginBackoffice(formValue) {
  try {
    const result = await axios
      .post(
        `${BASE_API}/api/auth/login2/`,
        { username: formValue.username, password: formValue.password },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "https://work.lizen.com.ar",
          },
        }
      )
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  } catch (error) {}
}

export async function getMeApi(token) {
  try {
    const url = `${BASE_API}/api/auth/userData/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, params);
    if (response.status === 200) {
      const result = await response.json();
      return result;
    }
    return false;
    throw new Error("Credenciales invalidas");
  } catch (error) {
    throw error;
  }
}

export async function recovery(formValue) {
  try {
    const url = `${BASE_API}/api/auth/password_reset/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };
    const response = await fetch(url, params);
    if (response.status !== 200) {
      throw new Error("Usuario o contraseña incorrectos");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
}

export async function reset(formValue) {
  try {
    const url = `${BASE_API}/api/auth/password_reset/confirm/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };
    const response = await fetch(url, params);

    const result = await response.json();

    if (response.status !== 200) {
      if (result.detail !== undefined)
        throw new Error(
          "El token de recuperación de contraseña ha expirado o es invalido."
        );

      if (result.password !== undefined) throw new Error(result.password);
    }
    return result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}
