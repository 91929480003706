import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './TipoCobertura.scss'
import { useAuth } from '../../../hooks';
import { getPermissions } from '../../Common/Permissions';
export  function ListadoTiposCobertura(props) {
  const {tiposCobertura, onDeleteTipoCobertura,onEditTipoCobertura,pagination,onShowTipoCobertura,countPage} = props
  
  const {auth} = useAuth()
  return (
    <Table size='small' celled  className='tabla-servicios'>
    <Table.Header className='color_header_table'>
   
      <Table.Row >
        <Table.HeaderCell>ID </Table.HeaderCell>
        <Table.HeaderCell>Nombre corto </Table.HeaderCell>
        <Table.HeaderCell>Nombre largo</Table.HeaderCell>
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {tiposCobertura.map((tipoCobertura,i)=>{
        
        return(<Table.Row key={i}>
         
       
         <Table.Cell > {tipoCobertura?.id}</Table.Cell>
         <Table.Cell > {tipoCobertura.short_name}</Table.Cell>
         <Table.Cell > {tipoCobertura.long_name}</Table.Cell>
       
         <Acciones auth={auth} tipoCobertura={tipoCobertura} onShowTipoCobertura={onShowTipoCobertura} onDeleteTipoCobertura={onDeleteTipoCobertura} onEditTipoCobertura={onEditTipoCobertura} />
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {tipoCobertura,onDeleteTipoCobertura,onEditTipoCobertura, onShowTipoCobertura,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_tipocobertura",auth) &&
    <Popup content='Ver tipo cobertura' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowTipoCobertura(tipoCobertura)}>
    <Icon name='eye' />
  </Button>} />}
        
     
      {getPermissions("change_tipocobertura",auth) &&
      <Popup content='Editar tipo cobertura' trigger={<Button size='mini' icon basic onClick={()=>onEditTipoCobertura(tipoCobertura)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_tipocobertura",auth) &&
      <Popup content='Eliminar tipo cobertura' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteTipoCobertura(tipoCobertura)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}