import {getPermissionsSecondary} from './Permissions'
import { Form, Grid,Dropdown, Segment,Accordion,Button,Modal,Icon} from 'semantic-ui-react'
import { getClinicas } from '../../api/Orion/clinica'
import {getProvincias} from '../../api/Common/provincia'
import {getProveedores} from '../../api/Lizen/proveedor'
import { getAseguradoras } from '../../api/Orion/aseguradora';
import { getConvenio } from '../../api/Lizen/aseguradora';
import {useFormik} from 'formik'
import Moment from 'moment';
import * as Yup from 'yup'


import { useState, useEffect } from 'react'
import { getLocalidades } from '../../api/Common/localidad'
import { set } from 'lodash'
export function FiltersOrionService({show,onClose,onConfirm,onClear,auth,type}){
    const estados = [
		{ key: 0, text: 'Todos', value: 0},
		{ key: 1, text: 'En tratamiento', value: 1 },
		{ key: 2, text: 'Abandono tratamiento', value: 2 },
		{ key: 3, text: 'Dado de alta', value: 3 },
		{ key: 4, text: 'No autorizado', value:4},
		{ key: 5, text: 'Pendiente de revisión', value: 5},
		{ key: 6, text: 'con OS/Particular', value: 6},
	]
    const [clinicas, setClinicas] = useState([])
    const [aseguradoras, setAseguradoras] = useState([])
    const formik = useFormik({
		initialValues:initialValues(auth),
		validationSchema:Yup.object(validationSchema()),
		enableReinitialize: true
	})

    const proccess_clinica =(data)=>{
		let clinicas_process = data.map((x) => {
			return{ key: x.id, text: x.clinica_nombre, value: x.id }
		})
		
        setClinicas(clinicas_process)
	}
    const proccess_aseguradora =(data)=>{
		let aseguradoras_process = data.map((x) => {
			return{ key: x.id, text: x.short_name, value: x.id }
		})
		
        setAseguradoras(aseguradoras_process)
	}
    useEffect(()=>{
		//scroll.scrollToTop();
        fetchClinicas(); 
        fetchAseguradoras();
        
    },[])
	
	
	
	const fetchClinicas = async () =>{
		const clinicas = await getClinicas()
		.then(data=>{
			proccess_clinica(data)
			
		})
	}
    const fetchAseguradoras = async () =>{
        const aseguradoras = await getAseguradoras()
        .then(data=>{
			proccess_aseguradora(data.results)
			
		})
     
    }
    const onClearFilters = ()=>{
       
        formik.setFieldValue("estado",[])
        formik.setFieldValue("clinica",[])
        formik.setFieldValue("aseguradora",[])
        formik.setFieldValue("created_at_from",null)
        formik.setFieldValue("created_at_to",null)
        onClear()
    }

    return(
        <Modal className='modal-basic' open={show} size={"large"}>
            <Modal.Header>
                <div  style={{textAlign: 'center'}}><Icon name='filter' className='color_search' inverted circular size="large" /> <p >Filtros</p></div>
            </Modal.Header>
             <Modal.Content>
               
                <Form   style={{display:'grid'}}>
                <Grid as={Segment}>
                        {getPermissionsSecondary("created_at",auth,'filters',type) &&  <Grid.Row>
                                
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                    <h5>Fecha desde</h5> 
                                        <Form.Input
                                            type="date"
                                            name="created_at_from"
                                            
                                            value={formik.values.created_at_from}
                                            onChange={(_,data)=>formik.setFieldValue("created_at_from",data.value)}
                                        />   
                                    </Grid.Column>	
                               
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                 <h5>Fecha hasta</h5> 
                                    <Form.Input
                                        type="date"
                                        name="created_at_to"
                                         
                                        value={formik.values.created_at_to}
                                        onChange={(_,data)=>formik.setFieldValue("created_at_to",data.value)}
                                    />   
                                </Grid.Column>	
                                <Grid.Column  mobile={16} >
                                </Grid.Column>
                            

                            </Grid.Row>
                        }
                        {getPermissionsSecondary("estado_descripcion",auth,'filters',type) &&  <Grid.Row>
                        
                                <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Estado</h5>
                                    <Dropdown
                                        upward 
                                        
                                        search
                                        fluid
                                        clearable
                                        options={estados}    
                                        value={formik.values.estado}  	
                                        selection 
                                        
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("estado",data.value)}	
                                    />
                                </Grid.Column>	

                            </Grid.Row>
                        }
                        {getPermissionsSecondary("aseguradora_long_name",auth,'filters',type) &&  <Grid.Row>
                            <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Convenio</h5>
                                    <Dropdown
                                        search
                                        upward
                                        fluid
                                        clearable
                                        options={aseguradoras}    
                                        value={formik.values.aseguradora}  	
                                        selection 
                                        
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("aseguradora",data.value)}	
                                    />
                                </Grid.Column>	
                               
                                </Grid.Row>
                        }
                        {getPermissionsSecondary("clinica_nombre",auth,'filters',type) &&  <Grid.Row>
                            <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Clínica</h5>
                                    <Dropdown
                                        search
                                        upward
                                        clearable
                                        options={clinicas}    
                                        value={formik.values.clinica}  	
                                        selection 
                                        fluid
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("clinica",data.value)}	
                                    />
                                </Grid.Column>	
                             
                            </Grid.Row>
                        }

            
                 </Grid>       
                    </Form>
             </Modal.Content>
         <Modal.Actions>
            <Button  color='red' inverted  onClick={onClose} >
            <Icon name='remove' /> {"Cancelar"}
            </Button>
            <Button color='blue' inverted  onClick={() =>onClearFilters()} >
            <Icon name='trash alternate' /> {"Limpiar filtros"}
            </Button>
            <Button color='green' inverted  onClick={() =>onConfirm(formik.values)} >
            <Icon name='checkmark' /> {"Aplicar"}
            </Button>
           
        </Modal.Actions> 
   </Modal>

        
    )

}
function initialValues(auth){
    let filtro = {
        estado: [],
        clinica: [],
        aseguradora:[],
        created_at_from:null,
        created_at_to:null,
        proveedor:[],
        convenio:[],
        provincia:[],
        localidad:[],
    }

    // let filtrosExistentes = localStorage.getItem('lizen_servicios_filters') 
    let filtrosExistentes 
    let url = window.location.href

    if(url.includes('servicios_lizen')){
        filtrosExistentes =  sessionStorage.getItem('lizen_servicios_filters')
    } else if(url.includes('servicios_orion')) {
        filtrosExistentes =  sessionStorage.getItem('orion_servicios_filters')
    } else {
        filtrosExistentes = sessionStorage.getItem('orion_beneficiarios_filters')
    }

    if(filtrosExistentes){
        filtrosExistentes = filtrosExistentes.split('&')

        let estado = filtrosExistentes.find(x=>x.includes('estado'))
        let estados = estado ? estado.split('=')[1].split(',') : []
        if(isNaN(parseInt(estados[0]))) {
            filtro.estado = estado ? estado.split('=')[1].split(',') : []
        } else {
            filtro.estado = estados.map(x=>parseInt(x))
        }

        let proveedor = filtrosExistentes.find(x=>x.includes('proveedor'))
        let proveedores = proveedor ? proveedor.split('=')[1].split(',') : []
        filtro.proveedor = proveedores.map(x=>parseInt(x))

        let convenio = filtrosExistentes.find(x=>x.includes('convenio'))
        let convenios = convenio ? convenio.split('=')[1].split(',') : []
        filtro.convenio = convenios.map(x=>parseInt(x))

        let created_at_from = filtrosExistentes.find(x=>x.includes('fecha_inicio'))
        filtro.created_at_from = created_at_from ? Moment(created_at_from.split('=')[1]).startOf('day').format('YYYY-MM-DD HH:mm:ss').split(' ')[0] : null
        
        let created_at_to = filtrosExistentes.find(x=>x.includes('fecha_fin'))
        filtro.created_at_to = created_at_to ? Moment(created_at_to.split('=')[1]).startOf('day').format('YYYY-MM-DD HH:mm:ss').split(' ')[0] : null

        let provincia = filtrosExistentes.find(x=>x.includes('provincia'))
        let provincias = provincia ? provincia.split('=')[1].split(',') : []
        filtro.provincia = provincias.map(x=>parseInt(x))

        let localidad = filtrosExistentes.find(x=>x.includes('localidad'))
        let localidades = localidad ? localidad.split('=')[1].split(',') : []
        filtro.localidad = localidades.map(x=>parseInt(x))

        let aseguradora = filtrosExistentes.find(x=>x.includes('aseguradora'))
        let aseguradoras = aseguradora ? aseguradora.split('=')[1].split(',') : []
        filtro.aseguradora = aseguradoras.map(x=>parseInt(x))

        let servicio_clinica = filtrosExistentes.find(x=>x.includes('servicio_clinica'))
        let clinicas = servicio_clinica ? servicio_clinica.split('=')[1].split(',') : []
        filtro.clinica = clinicas.map(x=>parseInt(x))

    }

	return filtro
}

function validationSchema (){
    return {}
}

export function FiltersLizenService({show,onClose,onConfirm,onClear,auth,type}){

    const estados = [
		{ key: 0, text: 'Todos', value: 0 },
		{ key: 1, text: 'Activo', value: 'Activo' },
		{ key: 2, text: 'Legajo', value: 'Legajo' },
		{ key: 3, text: 'Facturado', value: 'Facturado' },
		{ key: 4, text: 'Abonado', value: 'Abonado' },
        { key: 5, text: 'Particular', value: 'Particular' },
        { key: 5, text: 'Rechazado', value: 'Rechazado' },
	]
    const [provincias, setProvincias] = useState([])
    const [localidades, setLocalidades] = useState([])
    const [proveedores, setProveedores] = useState([])
    const [convenios, setConvenios] = useState([])
    
    const formik = useFormik({
		initialValues:initialValues(auth),
		validationSchema:Yup.object(validationSchema()),
		enableReinitialize: true
	})

    const proccess_provincias =(data)=>{
		let provincias_process = data.map((x) => {
			return{ key: x.id, text: x.nombre, value: x.id }
		})
		
        setProvincias(provincias_process)
	}

    const process_localidades =(data)=>{
		let localidades_process = data.map((x) => {
			return{ key: x.id, text: x.nombre, value: x.id, provincia:x.provincia }
		})
		
        setLocalidades(localidades_process)
	}

    const proccess_proveedores =(data)=>{
		let proveedores_process = data.map((x) => {
			return{ key: x.id, text: x.razon_social, value: x.id }
		})
		
        setProveedores(proveedores_process)
	}
    const proccess_convenios =(data)=>{
		let convenios_process = data.map((x) => {
			return{ key: x.id, text: x.nombre, value: x.id }
		})
		
        setConvenios(convenios_process)
	}
   
    useEffect(()=>{
		
        fetchProvincias(); 
        fetchLocalidades(); 
        fetchProveedores(); 
        fetchConvenios(); 
        
    },[])
	
    useEffect(()=>{
        fetchLocalidades()
    },[formik.values.provincia])

	const fetchProvincias = async () =>{
		const provincias = await getProvincias()
		.then(data=>{
			proccess_provincias(data.results)
            return data.results
		})
	}
	const fetchLocalidades = async () =>{
		const localidades = await getLocalidades()
		.then(data=>{
            if(formik.values.provincia && formik.values.provincia.length > 0){
                let filterLocaldiades = data.results.filter(x=>formik.values.provincia.includes(x.provincia))
                process_localidades(filterLocaldiades)
            } else {
                process_localidades(data.results)
            }
			
		})
    }

    useEffect(()=>{
        if(formik.values.provincia && formik.values.provincia.length > 0){
            fetchLocalidades()
            let filterLocaldiades = localidades.filter(x=>formik.values.provincia.includes(x.provincia))
            setLocalidades([...filterLocaldiades])
        }
    },[formik.values.provincia])

    const fetchProveedores = async () =>{
		const proveedores = await getProveedores(`is_active=1`)
		.then(data=>{
			proccess_proveedores(data)
			
		})
	}
    const fetchConvenios = async () =>{
		const convenios = await getConvenio()
		.then(data=>{
			proccess_convenios(data.results)
			
		})
	}
   
    const onClearFilters = ()=>{
       
        formik.setFieldValue("estado",[])
        formik.setFieldValue("provincia",[])
        formik.setFieldValue("convenio",[])
        formik.setFieldValue("created_at_from",null)
        formik.setFieldValue("created_at_to",null)
        onClear()
    }

    return(
        <Modal className='modal-basic' open={show} size={"large"}>
            <Modal.Header>
                <div  style={{textAlign: 'center'}}><Icon name='filter' className='color_search' inverted circular size="large" /> <p >Filtros</p></div>
            </Modal.Header>
             <Modal.Content>
               
                <Form   style={{display:'grid'}}>
                <Grid as={Segment}>
                        {getPermissionsSecondary("created_at",auth,'filters',type) &&  <Grid.Row>
                                
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                    <h5>Fecha desde</h5> 
                                        <Form.Input
                                            type="date"
                                            name="created_at_from"
                                            
                                            value={formik.values.created_at_from}
                                            onChange={(_,data)=>formik.setFieldValue("created_at_from",data.value)}
                                        />   
                                    </Grid.Column>	
                               
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                 <h5>Fecha hasta</h5> 
                                    <Form.Input
                                        type="date"
                                        name="created_at_to"
                                         
                                        value={formik.values.created_at_to}
                                        onChange={(_,data)=>formik.setFieldValue("created_at_to",data.value)}
                                    />   
                                </Grid.Column>	
                                <Grid.Column  mobile={16} >
                                </Grid.Column>
                            

                            </Grid.Row>
                        }
                        {getPermissionsSecondary("estado",auth,'filters',type) &&  <Grid.Row>
                        
                                <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Estado</h5>
                                    <Dropdown
                                        upward 
                                        
                                        search
                                        fluid
                                        clearable
                                        options={estados}    
                                        value={formik.values.estado}  	
                                        selection 
                                        
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("estado",data.value)}	
                                    />
                                </Grid.Column>	

                            </Grid.Row>
                        }
                        {getPermissionsSecondary("servicio_provincia_nombre",auth,'filters',type) &&  
                            <Grid.Row>
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                    <h5>Provincia</h5>
                                    <Dropdown
                                        search
                                        upward
                                        fluid
                                        clearable
                                        options={provincias}    
                                        value={formik.values.provincia}  	
                                        selection 
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("provincia",data.value)}	
                                    />
                                </Grid.Column>	
                                <Grid.Column computer={8} mobile={16} tablet={8}> 
                                    <h5>Localidad</h5>
                                    <Dropdown
                                        search
                                        upward
                                        fluid
                                        clearable
                                        options={localidades}    
                                        value={formik.values.localidad}  	
                                        selection 
                                        multiple
                                        limit={1000}
                                        onChange={(_,data)=>formik.setFieldValue("localidad",data.value)}	
                                    />
                                </Grid.Column>	
                            </Grid.Row>
                        }
                        {getPermissionsSecondary("proveedor_cocheria_razon_social",auth,'filters',type) &&  
                            <Grid.Row>
                                <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Proveedor</h5>
                                    <Dropdown
                                        search
                                        upward
                                        fluid
                                        clearable
                                        options={proveedores}    
                                        value={formik.values.proveedor}  	
                                        selection 
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("proveedor",data.value)}	
                                    />
                                </Grid.Column>	
                               
                            </Grid.Row>
                        }
                         {getPermissionsSecondary("cliente_convenio_nombre",auth,'filters',type) &&  <Grid.Row>
                            <Grid.Column computer={16} mobile={16} tablet={16}> 
                                    <h5>Convenio</h5>
                                    <Dropdown
                                        search
                                        upward
                                        fluid
                                        clearable
                                        options={convenios}    
                                        value={formik.values.convenio}  	
                                        selection 
                                        
                                        multiple
                                        onChange={(_,data)=>formik.setFieldValue("convenio",data.value)}	
                                    />
                                </Grid.Column>	
                               
                                </Grid.Row>
                        }
                        
            
                 </Grid>       
                    </Form>
             </Modal.Content>
         <Modal.Actions>
            <Button  color='red' inverted  onClick={onClose} >
            <Icon name='remove' /> {"Cancelar"}
            </Button>
            <Button color='blue' inverted  onClick={() =>onClearFilters()} >
            <Icon name='trash alternate' /> {"Limpiar filtros"}
            </Button>
            <Button color='green' inverted  onClick={() =>onConfirm(formik.values)} >
            <Icon name='checkmark' /> {"Aplicar"}
            </Button>
           
        </Modal.Actions> 
   </Modal>

        
    )

}

export function FiltersOrionBeneficiaro({show,onClose,onConfirm,onClear,auth,type}){
    const [aseguradoras, setAseguradoras] = useState([])
    const formik = useFormik({
        initialValues:initialValues(auth),
        validationSchema:Yup.object(validationSchema()),
        enableReinitialize: true
    })
    const proccess_aseguradora =(data)=>{
        let aseguradoras_process = data.map((x) => {
            return{ key: x.id, text: x.short_name, value: x.id }
        })

        setAseguradoras(aseguradoras_process)
    }
    useEffect(()=>{
        fetchAseguradoras();

    },[])

    const fetchAseguradoras = async () =>{
        await getAseguradoras()
            .then(data=>{proccess_aseguradora(data.results)
        })
    }
    const onClearFilters = ()=>{
        formik.setFieldValue("aseguradora",[])
        onClear()
    }

    return(
        <Modal className='modal-basic' open={show} size={"large"}>
            <Modal.Header>
                <div style={{textAlign: 'center'}}><Icon name='filter' className='color_search' inverted circular size="large" /> <p >Filtros</p></div>
            </Modal.Header>
            <Modal.Content>
                <Form style={{display:'grid'}}>
                    <Grid as={Segment}>
                        {getPermissionsSecondary("aseguradora_long_name",auth,'filters',type) &&  <Grid.Row>
                            <Grid.Column computer={16} mobile={16} tablet={16}>
                                <h5>Convenio</h5>
                                <Dropdown
                                    search
                                    upward
                                    fluid
                                    clearable
                                    options={aseguradoras}
                                    value={formik.values.aseguradora}
                                    selection
                                    multiple
                                    onChange={(_,data)=>formik.setFieldValue("aseguradora",data.value)}
                                />
                            </Grid.Column>

                        </Grid.Row>
                        }
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button  color='red' inverted  onClick={onClose} >
                    <Icon name='remove' /> {"Cancelar"}
                </Button>
                <Button color='blue' inverted  onClick={() =>onClearFilters()} >
                    <Icon name='trash alternate' /> {"Limpiar filtros"}
                </Button>
                <Button color='green' inverted  onClick={() =>onConfirm(formik.values)} >
                    <Icon name='checkmark' /> {"Aplicar"}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}