import React, { useEffect, useState } from 'react'
import { Form,Grid,Segment,Button,Dropdown } from 'semantic-ui-react';
import { addBeneficiarioOrion, editBeneficiarioOrion, getBeneficiario } from '../../../../api/Orion/beneficiario';
import { getTiposCobertura } from '../../../../api/Orion/tipoCobertura';
import { getAseguradoras } from '../../../../api/Orion/aseguradora';
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import Reducer  from './Reducer_beneficiario';
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";

export  function BeneficiarioOrionCreateEditShow(props) {
  	const {auth} = useAuth()
	const {history} = props;
	const {id,type_action} = props.match !== undefined ? props.match.params : "" 
	
	const disabledGeneral = type_action === "show" ? true : false
	const isAddMode = !id;
  	const [beneficiario,setBeneficiario] = React.useState([])
	  const initialState = {
		aseguradoras:[],
		tipos_cobertura:[]
	}

	const [state, dispatch] = React.useReducer(Reducer, initialState)
	const { 
		aseguradoras,
		tipos_cobertura
	} = state
  	const formik = useFormik({
		initialValues:initialValues(beneficiario),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
			 
				if (isAddMode){
					
					const response = await addBeneficiarioOrion(formValue)
					try {

						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							if(props.redireccionar !== undefined){
								toast.success("Se guardó exitosamente el beneficiario.") 
								props.hijoAPadre(formik.values.asegurado_dni) 
							}else{
								toast.success("Se guardó exitosamente el beneficiario.",{
									onClose: () => {
										history.push(`/admin/beneficiario_orion/listado`);
									},
									autoClose: 1000
								})  
							}
						}
						
					} catch (error) {

						toast.error(JSON.stringify(error.message))
					} 
				}else{
					const response = await editBeneficiarioOrion(id,formValue)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							
							 toast.success("Se guardó exitosamente el beneficiario.",{
								onClose: () => {
									history.push(`/admin/beneficiario_orion/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
					 
				} 
			
		},
		enableReinitialize: isAddMode ? false :false
	})

	const fetchDataTiposCobertura = async (aseguradora) =>{
		const tiposCobertura = await getTiposCobertura(`aseguradora=${aseguradora}&paginate=false`)
		let tipos_coberturaProcess = tiposCobertura.map((x) => {
			return { key: x.id, text: x.short_name, value: x.id };
		})
		
		dispatch({type:"SET_TIPOS_COBERTURA",payload:tipos_coberturaProcess})
	}
	const changeAseguradora = async(value)=>{
		formik.setFieldValue("aseguradora",value)
		let coberturas_process =  await fetchDataTiposCobertura(value)
	
	}

  	useEffect(()=>{
		const fetchDataAseguradoras = async () =>{
			const aseguradoras = await getAseguradoras()
			let aseguradorasProcess = aseguradoras.results.map((x) => {
				return { key: x.id, text: x.short_name, value: x.id };
			})
			
			dispatch({type:"SET_ASEGURADORAS",payload:aseguradorasProcess})
		}
		
		if(!isAddMode){
			const fetchBeneficiario = async () =>{
				const servicio = await getBeneficiario(`${props.match.params.id}`)
				.then(data=>{
					
					
					formik.setFieldValue("id_poliza",data.id_poliza)
					formik.setFieldValue("suma_asegurada",data.suma_asegurada)
					formik.setFieldValue("asegurado_dni",data.asegurado_dni)
					formik.setFieldValue("asegurado_apellido",data.asegurado_apellido)
					formik.setFieldValue("asegurado_nombre",data.asegurado_nombre)
					formik.setFieldValue("asegurado_provincia",data.asegurado_provincia)
					formik.setFieldValue("asegurado_localidad",data.asegurado_localidad)
					formik.setFieldValue("asegurado_calle",data.asegurado_calle)
					formik.setFieldValue("asegurado_numero",data.asegurado_numero)
					formik.setFieldValue("asegurado_codigo_postal",data.asegurado_codigo_postal)
					formik.setFieldValue("asegurado_fecha_nacimiento",data.asegurado_fecha_nacimiento)
					formik.setFieldValue("asegurado_fecha_alta_poliza",data.asegurado_fecha_alta_poliza)
					formik.setFieldValue("tomador_codigo",data.tomador_codigo)
					formik.setFieldValue("tomador_apellido",data.tomador_apellido)
					formik.setFieldValue("tomador_nombre",data.tomador_nombre)
					formik.setFieldValue("tomador_id",data.tomador_id)
					
					//formik.setFieldValue("aseguradora",data.aseguradora?.id)
					
					setBeneficiario(data)
					changeAseguradora(data.aseguradora?.id)
					formik.setFieldValue("tipo_cobertura",data.tipo_cobertura?.id)

				})
			} 
			fetchBeneficiario()
		}

		fetchDataAseguradoras();
		

  	},[])
	
  return (
	<Form  onSubmit={formik.handleSubmit} style={{display:'grid'}}>
		{!isAddMode &&
			<p><strong>Beneficiario: {beneficiario.asegurado_nombre + ' ' + beneficiario.asegurado_apellido}</strong></p>
		}
		<Grid as={Segment}>

			<Grid.Row className='segment_border_primary'>

				<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>* Id póliza</h5>
						<Form.Input  
							type='number'
							disabled={disabledGeneral}
							name="id_poliza"
							fluid 
							placeholder="Ingrese un id"
							value={formik.values.id_poliza} 
							onChange={(_,data)=>formik.setFieldValue("id_poliza",data.value)}
							error={formik.values.id_poliza !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>* Suma asegurada</h5>
						<Form.Input  
							type='number'
							disabled={disabledGeneral}
							name="suma_asegurada"
							fluid 
							placeholder="Ingrese un nombre largo"
							value={formik.values.suma_asegurada} 
							onChange={(_,data)=>formik.setFieldValue("suma_asegurada",data.value)}
							error={formik.values.suma_asegurada !== "" ? false : true}
							
						/>
					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>Aseguradora</h5>
					<Dropdown 
							search
							disabled={disabledGeneral}
							options={aseguradoras} 
							scrolling 
							selection 
							value={formik.values.aseguradora}  
							name="aseguradora"
							onChange={(_,data)=>changeAseguradora(data.value)}  

							//onChange={(_,data)=>formik.setFieldValue("aseguradora",data.value)}
							fluid
							error={formik.values.aseguradora !== "" ? false : true}

						/>
					
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>Tipo cobertura</h5>
						<Dropdown 
							search
							disabled={disabledGeneral}
							options={tipos_cobertura} 
							scrolling 
							selection 
							value={formik.values.tipo_cobertura}  
							name="tipo_cobertura"
							onChange={(_,data)=>formik.setFieldValue("tipo_cobertura",data.value)}
							fluid
							error={formik.values.tipo_cobertura !== "" ? false : true}
						/>
					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}>
					</Grid.Column>
				</Grid.Row>
			</Grid>		
			<Grid as={Segment}>
				<Grid.Row className='segment_border_primary'>
					<Grid.Column computer={16} mobile={16} tablet={16}> 
						<p ><strong>DATOS DEL BENEFICIARIO</strong></p>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* DNI</h5>
						<Form.Input  
							type="number"
							disabled={disabledGeneral}
							name="asegurado_dni"
							fluid 
							placeholder="Ingrese un DNI"
							value={formik.values.asegurado_dni} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_dni",data.value)}
							error={formik.values.asegurado_dni !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>Fecha alta de póliza</h5>
						<Form.Input  
							type="date"
							disabled={disabledGeneral}
							name="asegurado_fecha_alta_poliza"
							fluid 
							placeholder="Ingrese una fecha"
							value={formik.values.asegurado_fecha_alta_poliza} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_fecha_alta_poliza",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* Nombre</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="asegurado_nombre"
							fluid 
							placeholder="Ingrese un nombre"
							value={formik.values.asegurado_nombre} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_nombre",data.value)}
							error={formik.values.asegurado_nombre !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* Apellido</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="asegurado_apellido"
							fluid 
							placeholder="Ingrese un apellido"
							value={formik.values.asegurado_apellido} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_apellido",data.value)}
							error={formik.values.asegurado_apellido !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>Fecha de nacimiento</h5>
						<Form.Input  
							type="date"
							disabled={disabledGeneral}
							name="asegurado_fecha_nacimiento"
							fluid 
							placeholder="Ingrese una fecha de nacimiento"
							value={formik.values.asegurado_fecha_nacimiento} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_fecha_nacimiento",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={1} mobile={16} tablet={16}></Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* Provincia</h5>
						<Form.Input  
							
							disabled={disabledGeneral}
							name="asegurado_provincia"
							fluid 
							placeholder="Ingrese una provincia"
							value={formik.values.asegurado_provincia} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_provincia",data.value)}
							error={formik.values.asegurado_provincia !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* Localidad</h5>
						<Form.Input  
							
							disabled={disabledGeneral}
							name="asegurado_localidad"
							fluid 
							placeholder="Ingrese una localidad"
							value={formik.values.asegurado_localidad} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_localidad",data.value)}
							error={formik.values.asegurado_localidad !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}> </Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5> Calle</h5>
						<Form.Input  
							
							disabled={disabledGeneral}
							name="asegurado_calle"
							fluid 
							placeholder="Ingrese una calle"
							value={formik.values.asegurado_calle} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_calle",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={3} mobile={16} tablet={16}> 
						<h5>Número</h5>
						<Form.Input  
							type="number"
							disabled={disabledGeneral}
							name="asegurado_numero"
							fluid 
							placeholder="Ingrese un número"
							value={formik.values.asegurado_numero} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_numero",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
					<h5>CP</h5>
						<Form.Input  
							type="number"
							disabled={disabledGeneral}
							name="asegurado_codigo_postal"
							fluid 
							placeholder="Ingrese un número"
							value={formik.values.asegurado_codigo_postal} 
							onChange={(_,data)=>formik.setFieldValue("asegurado_codigo_postal",data.value)}
							
						/>
					</Grid.Column> 
					<Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid as={Segment}>
				<Grid.Row className='segment_border_primary'>
					<Grid.Column computer={16} mobile={16} tablet={16}> 
						<p ><strong>DATOS DEL TOMADOR</strong></p>
					</Grid.Column>
					
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Código</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="tomador_codigo"
							fluid 
							placeholder="Ingrese un nombre corto"
							value={formik.values.tomador_codigo} 
							onChange={(_,data)=>formik.setFieldValue("tomador_codigo",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Nombre</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="tomador_nombre"
							fluid 
							placeholder="Ingrese un apellido"
							value={formik.values.tomador_nombre} 
							onChange={(_,data)=>formik.setFieldValue("tomador_nombre",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Apellido</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="tomador_apellido"
							fluid 
							placeholder="Ingrese un apellido"
							value={formik.values.tomador_apellido} 
							onChange={(_,data)=>formik.setFieldValue("tomador_apellido",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={1} mobile={16} tablet={16}> </Grid.Column>
					</Grid.Row>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
						<Button  fluid disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting} type="submit" content={isAddMode ? "Guardar" : "Actualizar"} primary />
					
					</Grid.Column>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
					{props.redireccionar === undefined &&
						<Button fluid content="Volver" primary component={Link} href='/admin/beneficiario_orion/listado'/>
						}
					</Grid.Column>
					<Grid.Column computer={12} mobile={16} tablet={16}> 
					
			
					</Grid.Column>
				
				
			</Grid>
			 </Form>
	)
}
function validationSchema(){
	
	return {
		id_poliza: Yup.string().required(true),
		suma_asegurada: Yup.string().required(true),
		asegurado_dni: Yup.string().required(true),
		asegurado_apellido: Yup.string().required(true),
		asegurado_nombre: Yup.string().required(true),
		asegurado_provincia: Yup.string().required(true),
		asegurado_localidad: Yup.string().required(true),
		tipo_cobertura: Yup.number().required(true),
		aseguradora: Yup.number().required(true),
		
  }
}

function initialValues(beneficiario){
	return{
		id_poliza: beneficiario?.id_poliza || "",
		suma_asegurada: beneficiario?.suma_asegurada || "",
		asegurado_dni: beneficiario?.asegurado_dni || "",
		asegurado_apellido: beneficiario?.asegurado_apellido || "",
		asegurado_nombre: beneficiario?.asegurado_nombre || "",
		asegurado_provincia: beneficiario?.asegurado_provincia || "",
		asegurado_localidad: beneficiario?.asegurado_localidad || "",
		asegurado_calle: beneficiario?.asegurado_calle || "",
		asegurado_numero: beneficiario?.asegurado_numero || "",
		asegurado_codigo_postal: beneficiario?.asegurado_codigo_postal || "",
		asegurado_fecha_nacimiento: beneficiario?.asegurado_fecha_nacimiento || null,
		asegurado_fecha_alta_poliza: beneficiario?.asegurado_fecha_alta_poliza || null,
		tomador_codigo: beneficiario?.tomador_codigo || "",
		tomador_apellido: beneficiario?.tomador_apellido || "",
		tomador_nombre: beneficiario?.tomador_nombre || "",
		tipo_cobertura: beneficiario?.tipo_cobertura || "",
		aseguradora: beneficiario?.aseguradora || ""
	}
}