import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './ListadoProveedores.scss'
import { useAuth } from '../../../hooks';
import { getPermissions } from '../../Common/Permissions';
export  function ListadoProveedores(props) {
  const {proveedores, onDeleteProveedor,onEditProveedor,pagination,onShowProveedor,countPage} = props

  const {auth} = useAuth()
  return (
    <Table size='small' celled  className='tabla-servicios'>
    <Table.Header className='color_header_table'>
   
      <Table.Row >
        <Table.HeaderCell>Razón social </Table.HeaderCell>
        <Table.HeaderCell>Provincia</Table.HeaderCell>
        <Table.HeaderCell>Localidad</Table.HeaderCell>
        <Table.HeaderCell>Teléfono</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {proveedores.map((proveedor,i)=>{
        
        return(<Table.Row key={i}>
         
       
         <Table.Cell >{proveedor.razon_social} </Table.Cell>
         <Table.Cell >{proveedor.provincia.nombre} </Table.Cell>
         <Table.Cell >{proveedor.localidad.nombre} </Table.Cell>
         <Table.Cell >{proveedor.telefono} </Table.Cell>
         <Table.Cell >{proveedor.email} </Table.Cell>
       
       
         <Acciones auth={auth} proveedor={proveedor} onShowProveedor={onShowProveedor} onDeleteProveedor={onDeleteProveedor} onEditProveedor={onEditProveedor} />
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {proveedor,onDeleteProveedor,onEditProveedor, onShowProveedor,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_proveedor",auth) &&
    <Popup content='Ver proveedor' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowProveedor(proveedor)}>
    <Icon name='eye' />
  </Button>} />}
        
     
      {getPermissions("change_proveedor",auth) &&
      <Popup content='Editar proveedor' trigger={<Button size='mini' icon basic onClick={()=>onEditProveedor(proveedor)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("__delete_proveedor",auth) &&
      <Popup content='Eliminar proveedor' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteProveedor(proveedor)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}