import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'

export async function checkReport(request_get){
    try {
        
        const url =  `${BASE_API}/api/check-report/?hash=${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response
          
            return result
            
        }
        return response
        
    } catch (error) {
        return false
    }
}


export function removeNotification(){
    localStorage.removeItem("hash")
    localStorage.removeItem("intervalId")
    localStorage.removeItem("notificationData")
}

export function addHash(hash){
    let hashes_current = localStorage.getItem("hash") === null ? [] : JSON.parse(localStorage.getItem("hash")); 
  
    hashes_current.push(hash)

    
    localStorage.setItem("hash", JSON.stringify(hashes_current));
    return true;
}

export function removeHash(hash){
    // Crear un nuevo array sin el valor a remover
    let newArray = JSON.parse(localStorage.getItem("hash")).filter(valor => valor !== hash);
   
    if (newArray.length === 0 )
        localStorage.removeItem("hash")
    else
        localStorage.setItem("hash",JSON.stringify(newArray))

    return true;
}

