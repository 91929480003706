import React, { Fragment, useEffect, useState } from 'react'

import { Segment,Form,Input,Icon, Label, Menu, Table, Grid, Loader,Button,Modal,Header,Popup } from 'semantic-ui-react'
import {  getServiciosOrion,deleteServicio,getReport } from '../../../../api/Orion/servicio';
import { addHash } from '../../../../api/Common/report';
import { ModalBasic,PaginationTable } from '../../../../components/Common';
import {useHistory,Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ListadoServicios}from '../../../../components/Admin/Orion';
import { HeaderPage } from '../../../../components/Admin';
import {toast} from 'react-toastify'
import "./ServicesAdmin.scss"
import { getPermissions, FiltersOrionService,getPermissionsSecondary,Reports, MessageBeforeList } from '../../../../components/Common';
import { useAuth,useNotification } from '../../../../hooks';
import { animateScroll as scroll } from 'react-scroll'
import Moment from 'moment';


export  default function ServicesOrionList() {
	const {auth} = useAuth()
	const {startInterval} = useNotification()
    const [servicios,setServicios] = useState([])
    const [loading,setLoading] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
    const [contentModal,setContentModal] = useState(null)
    const [filtersActive,setFiltersActive] = useState("")
    const [searchActive,setSearchActive] = useState("")
	const [closeMessage,setCloseMessage] = useState(false)
	//Orden
    const [column,setColumn] = useState(null)
    const [direction,setDirection] = useState(null)
    const [nameSorted,setNameSorted] = useState(null)
	
    const [refetch, setRefetch] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [countPage, setCountPage] = useState(1);
	const [openFilter, setOpenFilter] = React.useState(false)
	const limitPagination = 10
    const history = useHistory()
	const fetchServicios = async (request = null) =>{
		
		const servicios = await getServiciosOrion(request)

		setServicios(servicios.results)
		setCountPage(servicios.count)
		setLoading(false)
		
	} 
    useEffect(()=>{
		scroll.scrollToTop();

		let filtrosExistentes = sessionStorage.getItem("orion_servicios_filters")
		let searchExistente = sessionStorage.getItem("orion_servicios_search")

		if(searchExistente){
			setSearchActive(searchExistente)
			fetchServicios(filtrosExistentes !== "" ? `search=${searchExistente}&${filtrosExistentes}` : `search=${searchExistente}`);
		} else {
			fetchServicios(filtrosExistentes !== "" ? `page=1&${filtrosExistentes}` : `page=1`);
		}
    },[refetch])
	
    const onRefetch = () => setRefetch((prev) => !prev);
	/*  ##################### INICIO FUNCIONALIDAD DE ELIMINAR ##############################*/
	const openCloseModal = ()=>{
		setShowModal((prev)=>!prev)
	}
	
   	const onDeleteServicio =  async(servicio)=>{
		setContentModal(<p>¿Desea eliminar la Derivación {servicio.num_siniestro} - {servicio.beneficiario.asegurado_apellido} {servicio.beneficiario.asegurado_nombre}?</p>)
		setTitleModal("Eliminar Derivación")
		setShowModal(true)
		setDataModal(servicio.id)
  	}
  	const openConfirmModal = async(id)=>{
		try {
			let response = await deleteServicio(id)
			toast.success(response.data)
				
			setShowModal(false)
			onRefetch()
			
		} catch (error) {

		} 
  	}
	/*  ##################### FIN FUNCIONALIDAD DE ELIMINAR ##############################*/

	/*  ##################### INICIO FUNCIONALIDASD FILTROS ##############################*/
	
	const openConfirmModalFilters = (formData)=>{

		let estado_filter =""
		let respuesta_final = []
		
		if(!formData.estado.includes(0) && formData.estado.length > 0){
		
			estado_filter = `estado=${formData.estado.join(",")}`
			respuesta_final.push(estado_filter)

		}
		let clinica_filter =""
		if(formData.clinica !== undefined && formData.clinica.length > 0){
			
			clinica_filter = `servicio_clinica=${formData.clinica.join(",")}`
			respuesta_final.push(clinica_filter)

		}

		let aseguradora_filter =""
		if(formData.aseguradora !== undefined && formData.aseguradora.length > 0){

			aseguradora_filter = `aseguradora=${formData.aseguradora.join(",")}`
			respuesta_final.push(aseguradora_filter)

		}
		let created_filter =""
		if(formData.created_at_from !== undefined && formData.created_at_from !== null){

			created_filter = `fecha_inicio=${Moment(formData.created_at_from).startOf('day').format('YYYY-MM-DD HH:mm:ss')}`
			if(formData.created_at_to !== undefined && formData.created_at_to !== null){
				created_filter += `&fecha_fin=${Moment(formData.created_at_to).endOf('day').format('YYYY-MM-DD HH:mm:ss')}`
			}else{
				created_filter += `&fecha_fin=${Moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`

			}
			respuesta_final.push(created_filter)

		}

		fetchServicios(respuesta_final.join('&'));
		sessionStorage.setItem("orion_servicios_filters",respuesta_final.join('&'))

		setFiltersActive(respuesta_final.join('&'))
		setOpenFilter((prev)=>!prev)
		setLoading(true)
		
	}

	const openCloseModalFilters = ()=>{
		
		setOpenFilter((prev)=>!prev)
	}
	const openClearFilters = () =>{
		fetchServicios(); 
		setFiltersActive("")
		sessionStorage.removeItem('orion_servicios_filters')
		setOpenFilter((prev)=>!prev)
		setLoading(true)
		onRefetch()
	}

	const  onFilters = ()=> {
		setOpenFilter(true)
	}

	const  onReports = async()=> {

		let response = await getReport(filtersActive)

		
		if (!response){

		}else{
			setCloseMessage(true)
			//localStorage.setItem("hash",response.hash)
			addHash(response.hash)
			startInterval()
		} 
	}
	
	const BotonFiltros = () =>{
		return(getPermissionsSecondary("segment_filters",auth,'filters','orion') ? (
			<Popup 
				content='Ver filtros' 
				trigger={<Button  onClick={onFilters} icon={<Icon name='filter' className='color_search' inverted circular />} 
				inverted 
				
			/> }/>

		):(<Grid.Column computer={1} mobile={16} tablet={8}  ></Grid.Column>))
			
	} 
	/*  ##################### FIN FILTROS ##############################*/

	/*  ##################### INICIO SERIVICIO ##############################*/
  	const crearServicio = (id) => {
    	history.push(`/admin/servicios_orion/crear/id`);
  	}

  	const onEditServicio = (servicio) => {
		history.push(`/admin/servicios_orion/edit/id/${servicio.id}`);
  	}
	const onShowServicio = (servicio) => {
		history.push(`/admin/servicios_orion/show/id/${servicio.id}`);
  	}
	
  	const onSearch =  (e) => {
		setSearchActive(e.target.value)
		sessionStorage.setItem("orion_servicios_search",e.target.value)
		fetchServicios(filtersActive !== "" ? `search=${e.target.value}&${filtersActive}` : `search=${e.target.value}`); 
		
  	}
	const onAddFacturacionServicio = (servicio) => {
		
		history.push(`/admin/facturacion_orion/crear`,{servicio_id:servicio.id});
  	}

	const onEditFacturacionServicio = (servicio) => {
		
		history.push(`/admin/facturacion_orion/id/${servicio.facturacion}`,{servicio_id:servicio.id});
  	}
  	const onChange = (e, pageInfo) => {

		let request = `page=${pageInfo.activePage.toString()}`
		/* 
		Logica para los filtros
		*/
		if(filtersActive !== "")
			request += `&${filtersActive}` 
    	
		/*
		Logica de orden
		*/
		if(nameSorted !== null)
			request += `&ordering=${nameSorted}`

		/*
		Logica de search
		*/
		if(searchActive !== "")
			request += `&search=${searchActive}` 

		fetchServicios(request); 

		setActivePage(pageInfo.activePage);
		
	};

	const onOrdering = (order_field) =>{
		
		let name_order = order_field
		
		if (column === order_field) {
			setDirection(direction === 'ascending' ? 'descending' : 'ascending')
			name_order = direction === 'ascending' ? `-${order_field}` : order_field
		}else{
			setDirection('ascending')
		}
		setColumn(order_field)
		setNameSorted(name_order)
		fetchServicios(filtersActive !== "" ? `ordering=${name_order}&${filtersActive}&page=${activePage}` : `ordering=${name_order}&page=${activePage}`); 
	
	}
	
	/*  ##################### FIN SERIVICIO ##############################*/
		
  return (
    <div>
		
		<HeaderPage title="Derivaciones" btnTitle="Agregar derivación" btnClick={crearServicio} permitionName="add_orionservice"/>
		<Grid.Row >
		<Grid as={Segment} className='segment_search'>
			<Grid.Row >
		
				<Grid.Column computer={3} mobile={16} tablet={16}>  
					<Input 
						
					fluid
					icon={<Icon name='search' className='color_search' inverted circular />} 
					className="input_search"  
					inverted 
					placeholder='Buscar...' 
					onChange={onSearch}
					value={searchActive}
				/>
				</Grid.Column>
				<Grid.Column computer={2} mobile={8} tablet={8} style={{bottom: '5px'}}>
					<BotonFiltros />
					{getPermissions("generate_report",auth) && (
					<Reports confirmReports={onReports}/> 
					)}
					</Grid.Column>
				<Grid.Column computer={7} mobile={16} tablet={16}></Grid.Column>
				
				
				<br></br>
				{getPermissions("add_servicio",auth) && (
					<Fragment>
					<Grid.Column computer={1} mobile={8} tablet={8}></Grid.Column>
					<Grid.Column computer={3} mobile={16} tablet={8}>
					
						<Button  content="Agregar derivación" fluid basic positive onClick={crearServicio} />
					
					</Grid.Column>
					</Fragment >
				)}
				
				</Grid.Row>
			
			</Grid>
			</Grid.Row>
      <Grid>
		
        <Grid.Row>
          <Grid.Column computer={16}>
        
          </Grid.Column>
        </Grid.Row>
      </Grid>

	  {loading ? (
		<Loader active inline="centered">
			Cargando...
		</Loader>
	  ):(
		<>
		<MessageBeforeList text={{
			value:"Cuando finalice la descarga, se visualizará en la campana de notificaciones"}} 
			closeMessage={closeMessage} 
			onCloseMessage={()=>setCloseMessage(false)}
			 warning={true}/>
		
		<ListadoServicios 
      servicios={servicios} 
      onDeleteServicio={onDeleteServicio}
      onEditServicio={onEditServicio}
      onShowServicio={onShowServicio}
	  onAddFacturacionServicio={onAddFacturacionServicio}
      onEditFacturacionServicio={onEditFacturacionServicio}
	  countPage={countPage}
	  onOrdering={onOrdering}
	  direction={direction}
	  column={column}
	  pagination={<PaginationTable 
		servicios={servicios} 
		onChange={onChange}
		activePage={activePage}
		countPage={countPage}
		
		limitPagination={limitPagination}
		/>
		
	}
    />
  		</>)}

      <ModalBasic 
      show={showModal} 
      size="small" 
      title={titleModal} 
      children={contentModal}
      onClose={openCloseModal}
      onConfirm={openConfirmModal}
	  dataModal={dataModal}
	 
      />
	  <FiltersOrionService
	  show={openFilter}
	  onClose={openCloseModalFilters}
	  onConfirm={openConfirmModalFilters}
	  onClear={openClearFilters}
	  auth={auth}
	  type="orion"
	  />
    </div>
  )
}
