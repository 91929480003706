import React, { useState } from 'react'
import { Icon, Button,Popup,Modal,ModalContent,ModalActions } from 'semantic-ui-react'

export  function Reports({confirmReports}) {

    const [open, setOpen]=useState(false)
    const onReports =()=>{
        setOpen(true)
    }
    const download =()=>{
        setOpen(false)
        confirmReports()
    }
  return (
    <>
    <Popup 
    content='Descargar Reporte' 
    trigger={<Button  onClick={onReports} icon={<Icon name='download' className='color_search' inverted circular />} 
    inverted 
   
/> }/>
<Modal
    size={'large'}
    open={open}
    onClose={() => setOpen(false)}
  >
    
    <ModalContent>
      <p>¿Desea inicializar el proceso de descarga?</p>
    </ModalContent>
    <ModalActions>
      <Button  onClick={() => setOpen(false)}>
        Volver
      </Button>
      <Button primary onClick={download}>
        Descargar
      </Button>
    </ModalActions>
  </Modal>
  </>
  )
}
