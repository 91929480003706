import React from 'react'
import './LoginForm.scss'
import {Button, Form} from 'semantic-ui-react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { reset } from '../../../api/Common/user'
import {toast} from 'react-toastify'
import { useAuth } from '../../../hooks'
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";


export function ResetForm(props) {

	const login = useAuth();
	const [activeLogin,setActiveLogin]=React.useState(false)
	/* 
	recibe un objeto de configuracion 
		1-valor inicial del formulario
		2- validaciones
		3- submit
	 */
	const formik = useFormik({
		initialValues:initialValues(props.token),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
			try {
				const response = await reset(formValue)
				setActiveLogin(true)
			} catch (error) {
				toast.error(error.message)
			}
		}
	})
	
	
	if(activeLogin)
	return (<React.Fragment>
		<h1 className='h1-login-form'>Ya puedes usar tu nueva contraseña para logearte en tu cuenta!</h1>
	<Button
		basic 
		 color='red'
		component={Link} href={'/login'}
	>Login</Button></React.Fragment>)

  return (
	
  	<Form className='login-form-admin' onSubmit={formik.handleSubmit}>
		<h1 className='h1-login-form'>Cambiar contraseña</h1>
	<h2 className='h2-login-form'> Asegurese de que cumpla con los siguientes requisitos:</h2>
	<ul>
		  <li> No debe ser similar a su nombre de usuario</li>
		  <li> Debe contener al menos 8 caracteres.</li>
	  </ul>
        <Form.Group widths='equal'>
           
            <Form.Input 
                name="password"
                placeholder="Contraseña*"
                label="Contraseña *"
                type="password"
				value={formik.values.password}
				onChange={formik.handleChange}
				error={formik.errors.password}


            />
			 <Form.Input 
                name="confirmPassword"
                placeholder="Confirmar contraseña*"
                label="Confirmar contraseña *"
                type="password"
				value={formik.values.confirmPassword}
				onChange={formik.handleChange}
				error={formik.errors.confirmPassword}


            />
        </Form.Group>
		<Button.Group widths='5'>
		<Button
			basic 
		 	color='red'
			component={Link} href={'/login'}
		
			
		>Ingresar con otra cuenta</Button>
        <Button type="submit" content="Cambiar contraseña" primary >

        </Button>
		</Button.Group>
    </Form>
  )
}

function initialValues(token){
	
	return {
		password: "",
		confirmPassword: "",
		token:token


	}
}


function validationSchema(){
	return {
		password: Yup.string()
		.required(true)
		.min(8,"Debe contener al menos 8 caracteres."),
		confirmPassword: Yup.string()
		.required(true)
		.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
	}
}
