import React from 'react'
import './ForgotAdmin.scss'
import {ResetForm} from '../../../components/Admin/LoginForm'
import { Container } from 'semantic-ui-react'
export function ResetAdmin(props) {
  
  return (
    <Container>
    <div className='login-admin'>
      <div className='login-admin__content'>
     
        <ResetForm token={props.match.params.hash}/>
      </div>
    
    </div>
    </Container>
  )
}
