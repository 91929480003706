/* import React from 'react'
import "./TopMenu.scss"
import {Icon, Menu} from "semantic-ui-react"
import { useAuth } from '../../../hooks'
import Logo from './lizen_logo.jpeg'

export function TopMenu() {
    const {auth,logout} = useAuth()
    
    const renderName = () =>{
        if(auth?.me?.username){
            return auth.me.username
        }
    }
  return (
   
    <Menu fixed='top' className='top-menu-admin'>
        <Menu.Item  className='top-menu-admin__izq'>
            <img  src={Logo} alt='Lizen' className='top-menu-admin__izq__logo' />
        </Menu.Item>
        <Menu.Menu position='right' >
            <Menu.Item>
                Hola {renderName()}
            </Menu.Item>
            <Menu.Item onClick={logout}>
                <Icon name='sign-out' className='top-menu-admin__color_brown'/>
            </Menu.Item>
        </Menu.Menu>
    </Menu>
  )
}
 */

import React from "react";
import Logo from './lizen_logo.jpeg'
import "./TopMenu.scss"
import {Icon, Menu} from "semantic-ui-react"
import { useAuth } from '../../../hooks'
import { Notification } from "../../Common";

export function TopMenu () {
    const {auth,logout} = useAuth()
    
    const renderName = () =>{
        if(auth?.me?.username){
            return auth.me.username
        }
    }
  return (
    <div className="navbar">
      <div className="brand">
        
        <div className="logo">
            <img  src={Logo} alt='Lizen' className='top-menu-admin__izq__logo' />
        </div>
      </div>
      <div className="left" style={{ position: 'relative' }}>
        <span>Hola {renderName()}</span>
        <Notification />
        
        <Icon name='sign-out' style={{padding: "0px",marginRight: "3px"}} className='top-menu-admin__color_brown' onClick={logout}/>
        
       
      </div>
    </div>
  );
};



