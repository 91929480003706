import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {BASE_API} from "../../../../../utils/constantes";
import {useEffect, useState} from "react";
import {generarDataSetPie} from "../utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const ServicioLizenEstado = () => {

    const [dataSet, setDataSet] = useState(null);

    function getServiciosPorAnio() {
        fetch(`${BASE_API}/api/dashboard/servicio_estado_anio/`, {
            headers:{
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json()).then((data) => {
            setDataSet(generarDataSetPie(data));
        })
    }

    useEffect(() => {
        getServiciosPorAnio();
    }, []);

    return (dataSet ? <div><Pie data={dataSet} /></div> : <div>Cargando...</div>);
}
export default ServicioLizenEstado;