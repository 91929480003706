import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, BarElement} from "chart.js";
import {BASE_API} from "../../../../../utils/constantes";
import {useEffect, useState} from "react";
import {generarDataSetBar} from "../utils";
ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

const DerivacionesOrionConvenioBar = () => {

    const [dataSet, setDataSet] = useState(null);

    function getServiciosPorAnio() {
        fetch(`${BASE_API}/api/dashboard/derivacion_convenio_anio/`, {
            headers:{
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json()).then((data) => {
            setDataSet(generarDataSetBar(data));
        })
    }

    useEffect(() => {
        getServiciosPorAnio();
    }, []);

    const options = {
        plugins:{
            legend:true,
        },
    }
    return (dataSet? <Bar height={50} data={dataSet} options={options} />: <div>Cargando...</div>);
}

export default DerivacionesOrionConvenioBar;