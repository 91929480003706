import routesAdmin from "./routes.admin";
import routesClient from "./routes.client";
import {ErrorLayout} from '../layouts';
import { Error404 } from "../pages/Error404";

const routes = [
    ...routesAdmin,
    ...routesClient,
    {
        layout:ErrorLayout,
        component:Error404
    }
];

export default routes;
