import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function addComentario(id_proveedor,comentario){
    try {
       
        let request = {
            "comentario":comentario,
            "proveedor":id_proveedor
        }
        const url =  `${BASE_API}/api/comentario_proveedor/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)

        if(response.status === 200){
           
            const result= await response.json()
            console.log(result)
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}


export async function putComentario(id_proveedor,comentario,id){
    try {
        
        const url =  `${BASE_API}/api/comentario_proveedor/${id}/`
        let request = {
            "comentario":comentario,
            "proveedor":id_proveedor
        }
        //console.log("url",url)
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            console.log(result)
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}

export async function deleteComentario(id){
    try {
        
        const url =  `${BASE_API}/api/comentario_proveedor/${id}/`
            
        //console.log("url",url)
        const params = {
            method:"DELETE",
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            console.log(result)
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}