import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function getConvenio(request_get){
    try {
        const url = request_get === undefined ? `${BASE_API}/api/aseguradora/` : `${BASE_API}/api/aseguradora/?${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}


