import React from "react";

import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import "simplebar/dist/simplebar.css";
export function Content ({ toggleBtn, children }) {
  return (
    
    <div
      className={`${toggleBtn ? "app-wrapper collapse" : "app-wrapper"}`}
      data-simplebar
    >
      {children}
    </div>
    
  );
};


