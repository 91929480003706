import React, { useState,useEffect } from 'react'
import { Icon} from "semantic-ui-react"
import {MessageBeforeList} from './MessageBeforeList'
import ReactDOM from 'react-dom';
import {useNotification} from '../../hooks'
export const  Notification = () => {
    const  [showMessage,setShowMessage] = useState(false)
    const  [bellActive,setBellActive] = useState(false)
  
    const { lastNotificationInfo,hiddenNotification } = useNotification();
    const  [closeMessage,setCloseMessage] = useState(true)

    const [portalRoot] = useState(() => {
      const root = document.createElement('div');
      root.style.position = 'absolute';
      root.style.bottom = '0%';
      root.style.right = '1%';
      root.style.zIndex = '1000';
      root.style.top = '10%';
      root.style.width = '30%';
      root.style.height = '20%';
      // Agrega otros estilos según sea necesario
      return root;
    });

     const verificarShowBell =()=>{
     
        let parsedData = localStorage.getItem("notificationData") === null ? [] : JSON.parse(localStorage.getItem("notificationData"));
        if (parsedData.length > 0) {
           
            if (parsedData.some(objeto => objeto.show === false)){
                setBellActive(true)
            }else{
                setBellActive(false)
            }
        }
    } 

    const verificarShowNotification =()=>{
      let parsedData = JSON.parse(localStorage.getItem("notificationData"));
      if (parsedData.length > 0) {
          // Obtiene el último objeto del array
          //let ultimoObjeto = parsedData[parsedData.length - 1];
          parsedData.map(noti=>{
            noti.show = true;
          })
          
          //ultimoObjeto.show = true;
          localStorage.setItem("notificationData", JSON.stringify(parsedData));
          verificarShowBell()
      }
    }
    useEffect(()=>{
        verificarShowBell()
    },[])
    useEffect(()=>{
        verificarShowBell()
    },[showMessage])
   
   const viewNotification =()=>{
   
        setShowMessage(true)
        document.body.appendChild(portalRoot);
        verificarShowNotification()
    
   }
   const close =()=>{
    setCloseMessage(false)
    setShowMessage(false)
    hiddenNotification()
    verificarShowNotification()
    
    document.body.removeChild(portalRoot);
   }

    const MensajeNot = ({portalRoot})=> {
        return(
            JSON.parse(localStorage.getItem("notificationData")).map((noti)=>(
            ReactDOM.createPortal( 
               
                <MessageBeforeList 
                
                text={{value:`Descargá tu reporte `,url:noti.url,name_url:<Icon name='download' className='color_search' inverted circular />}} 
                closeMessage={true} 
                onCloseMessage={close} 
                warning={false} 
                info={true}
                header={`Fecha: ${noti.fecha} `}
                />,
                portalRoot
            )
        ))

    )}

const  parsedData = localStorage.getItem("notificationData") === null ? [] : JSON.parse(localStorage.getItem("notificationData"));
const count  = parsedData.filter(objeto => objeto.show === false).length  
return (
    <>
   
    <Icon style={{padding: "0px",marginRight: "0px",marginLeft: "7px"}} name={lastNotificationInfo ? 'bell' : 'bell outline'} className={`top-menu-admin__color_brown`} onClick={viewNotification}/>

    {(lastNotificationInfo) &&
    
      <div
        style={{
          position: 'absolute',
          top: '-5px',
          right: '52px',
          background: '#9E9E9E',
          color: 'white',
          borderRadius: '50%',
          padding: '4px',
          fontSize: '12px',
          width: '20px',
          height: '20px',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}
      >
       {count}
      </div>
    }
    {(localStorage.getItem("notificationData") !== null && showMessage) &&
    <MensajeNot portalRoot={portalRoot}/>}
   
  </>
  )
}

