//require('dotenv').config();
//const BASE_API = process.env.BASE_API;
import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
import { addComentario,putComentario } from './comentario'

export async function getProveedores(request_get){
    
    try {
        const url =  `${BASE_API}/api/proveedor/?${request_get}`

        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            return result
            
        }
        return false
    } catch (error) {
        throw error;
        
    }
}

export async function getProveedoresList(request_get){
    
    try {
        const url =  `${BASE_API}/api/proveedor_lizen/?${request_get}`

        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
            const result= await response.json()
            return result
        }
        return false
    } catch (error) {
        throw error;
        
    }
}


export async function getContactoProveedor(request_get){
    try {
        
        const url =  `${BASE_API}/api/contacto_proveedor/?${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 200){
            const result= await response.json()
            return result
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}

export async function editProveedor (request_put,proveedor_id){
    try {
        const url =  `${BASE_API}/api/proveedor/${proveedor_id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request_put)
        }
         const response = await fetch(url,params)

        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result 
    } catch (error) {
        throw error
    }
}

export async function editProveedorLizen (request_put,proveedor_id,comentarios){
    try {

        const url =  `${BASE_API}/api/proveedor_lizen/${proveedor_id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request_put)
        }
        const response = await fetch(url,params)

        const result= await response.json()

        if(response.status === 200){
            result["status"]= "sucess"
            await comentarios.forEach(async (element)=>{
                if(element.id === undefined){
                    const responseComentariosAdd = await addComentario(result.id,element.texto)
                }else{
                    const responseComentariosPut = await putComentario(result.id,element.texto,element.id)
                }
            })
        }else
            result["status"]= "error"


        return result 
    } catch (error) {
        throw error
    }
}

export async function getProveedor(request_get = null){
    try {
        
        const url = `${BASE_API}/api/proveedor/${request_get}/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}


export async function deleteProveedor(id){
    try {
        
        
        const url =  `${BASE_API}/api/proveedor/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export async function addProveedor(request,comentarios){
    try {
        const url =  `${BASE_API}/api/proveedor/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 201){
            result["status"]= "sucess"
            await comentarios.forEach(async (element)=>{
                const responseComentarios = await addComentario(result.id,element.texto)
            })
        }else
            result["status"]= "error"

        return result  
    } catch (error) {
        throw error
    }
}