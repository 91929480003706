import React, { useEffect, useState } from 'react'
import { Form,Grid,Segment,Button,TextArea,Dropdown, Select, Icon } from 'semantic-ui-react';
import { addClinicaOrion, addDocumentoClinica, editClinicaOrion, getClinica, getDocumentosClinica, getTiposDoc, deleteDocumentoClinica } from '../../../../api/Orion/clinica';
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import {Icons, toast} from 'react-toastify'
import {useFormik, Field} from 'formik'
import Reducer from "./Reducer_clinica"
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { getProvincias } from '../../../../api/Common/provincia';
import { getLocalidades } from '../../../../api/Common/localidad';
import { fetchUploadFile } from '../../../../api/Common/file';
import { get, set } from 'lodash';

export  function ClinicaOrionCreateEditShow(props) {
	const initialState = {
		
		provincias:[],
		localidades:[]
	}

	const [state, dispatch] = React.useReducer(Reducer, initialState)
	const { 
		provincias,
		localidades
	} = state
	
  	const {auth} = useAuth()
	const {history} = props;
	const {id,type_action} = props.match.params
	const disabledGeneral = type_action === "show" ? true : false
	const isAddMode = !id;
  	const [clinica,setClinica] = React.useState([])
	const [tipoDocumento,setTipoDocumento] = React.useState('')
	const [tiposDocs,setTiposDocs] = React.useState([])
	const [documentos, setDocumentos] = React.useState([])
	const [deletdDocs, setDeletedDocs] = React.useState([])
  	const formik = useFormik({
		initialValues:initialValues(clinica),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
				if (isAddMode){
					
					const response = await addClinicaOrion(formValue)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
						
							 toast.success("Se guardó exitosamente la clínica.",{
								onClose: () => {
									history.push(`/admin/clinica_orion/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
				}else{
					const response = await editClinicaOrion(id,formValue)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							
							 toast.success("Se guardó exitosamente la clínica.",{
								onClose: () => {
									history.push(`/admin/clinica_orion/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
					 
				} 
			
		},
		enableReinitialize: isAddMode ? false :false
	})
	const fetchProvincias = async () =>{
		const provincias = await getProvincias()
		.then(data=>{
			let provincias_process = data.results.map((x) => {
				return{ key: x.id, text: x.nombre, value: x.id }
			})
			dispatch({type:"SET_PROVINCIA",provincias: provincias_process})	
			
		})
	}

	const fetchLocalidades = async(provincia)=>{
		const localidades = await getLocalidades(`provincia=${provincia}`)
		.then(data =>{
			let localidades_process = data.results.map((x) => {
				return { key: x.id, text: x.nombre, value: x.id };
			})
			return localidades_process	
		})
	
		return localidades
		
	}
	useEffect(()=>{
			
		if(!isAddMode){
			const fetchClinica = async () =>{
				const servicio = await getClinica(`${props.match.params.id}`)
				.then(data=>{
					changeProvincia(data?.clinica_provincia.id)
					formik.setFieldValue("clinica_nombre",data.clinica_nombre)
					formik.setFieldValue("clinica_provincia",data?.clinica_provincia.id)
					formik.setFieldValue("clinica_localidad",data?.clinica_localidad.id)
					formik.setFieldValue("clinica_direccion",data.clinica_direccion)
					formik.setFieldValue("clinica_telefono",data.clinica_telefono)
					formik.setFieldValue("clinica_codigo_postal",data.clinica_codigo_postal)
					formik.setFieldValue("clinica_comentario",data.clinica_comentario)
					setClinica(data)
				})
				
			} 
			
			const getClinicaDocs = async () => {
				await getDocumentosClinica(id).then(data => {
					setDocumentos(data.results)
				})
			}

			getClinicaDocs()
			
			fetchClinica()
		}

		const tiposDocs = async() => await getTiposDoc().then(data => {
			setTiposDocs(data.results)
		})
		
		tiposDocs()	
	fetchProvincias()
  },[])

  

  const changeProvincia = async(value)=>{
	formik.setFieldValue("clinica_provincia",value)
	let localidades_process =  await fetchLocalidades(value)
	

	dispatch({type:"SET_LOCALIDAD",localidades:localidades_process})
}

 const handleChange = async(e) => {
	const file = e.target.files[0];
	const formData = new FormData();
	formData.append('file', file);

	let {status, data} = await fetchUploadFile(formData)
	let tipoDoc = tipoDocumento
	if(tipoDoc){

		await addDocumentoClinica(id, tipoDoc, data[0]).then(data => {
			if(data){
				setDocumentos([...documentos, data])
			}
		})
	} else {
		toast.error("Error al subir el archivo")

	}


	if(status === "success"){
		toast.success("Archivo subido correctamente")
	} else {
		toast.error("Error al subir el archivo")
	}
}

const handleDocDelete = async(id) => {
	if(deletdDocs.includes(id)){

		setDeletedDocs(deletdDocs.filter((doc) => doc !== id))
		let deletedDoc = documentos.find((doc) => doc.id === id)
		deletedDoc.deleted = false

		setDocumentos([...documentos])

		return
	
	} else {

		setDeletedDocs([...deletdDocs, id])
		let deletedDoc = documentos.find((doc) => doc.id === id)
		deletedDoc.deleted = true
		setDocumentos([...documentos])
	}



}

const handleSave = () => {
	deletdDocs.map(async (id) => {
		await deleteDocumentoClinica(id)
	})

}


const handleTipoDocChange = (event, data)=> {
	setTipoDocumento(data.value)
}


  return (
	<Form  onSubmit={formik.handleSubmit} style={{display:'grid'}}>
			<p><strong>Clínica</strong></p>
			<Grid as={Segment}>
		
				<Grid.Row className='segment_border_primary'>
				
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>* Nombre</h5>
							<Form.Input  
								disabled={disabledGeneral}
								name="clinica_nombre"
								fluid 
								placeholder="Ingrese un nombre corto"
								value={formik.values.clinica_nombre} 
								onChange={(_,data)=>formik.setFieldValue("clinica_nombre",data.value)}
								error={formik.values.clinica_nombre !== "" ? false : true}
								
							/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5> Teléfono</h5>
							<Form.Input  
								disabled={disabledGeneral}
								name="clinica_telefono"
								fluid 
								placeholder="Ingrese un teléfono"
								value={formik.values.clinica_telefono} 
								onChange={(_,data)=>formik.setFieldValue("clinica_telefono",data.value)}
								error={formik.values.clinica_telefono !== "" ? false : true}
								
								
							/>
					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}>

					</Grid.Column>
					<Grid.Column computer={3} mobile={16} tablet={16}>
						<h5>* Provincia</h5>
							
							
							<Dropdown 
								search
								disabled={disabledGeneral}
								placeholder='Seleccione una provincia'
								name="provincia" 
								options={provincias}    
								value={formik.values.clinica_provincia}    
								onChange={(_,data)=>changeProvincia(data.value)}  
								selection 
								fluid
								error={formik.values.clinica_provincia !== "" ? false : true}

							/>
							
					</Grid.Column>

					<Grid.Column computer={3} mobile={16} tablet={16}>
						<h5 >* Localidad</h5>
						<Dropdown 
							search
							disabled={disabledGeneral}
							placeholder='Seleccione una localidad'
							name="localidad"
							options={localidades}   
							value={formik.values.clinica_localidad}    
							onChange={(_, data) => {formik.setFieldValue("clinica_localidad",data.value)}}

							selection 
							fluid
							error={formik.values.clinica_localidad  !== "" ? false : true}

						/>	
					</Grid.Column>
				
					<Grid.Column computer={3} mobile={16} tablet={16}> 
						<h5>* Dirección</h5>
							<Form.Input  
								disabled={disabledGeneral}
								name="clinica_direccion"
								fluid 
								placeholder="Ingrese una dirección"
								value={formik.values.clinica_direccion} 
								onChange={(_,data)=>formik.setFieldValue("clinica_direccion",data.value)}
								error={formik.values.clinica_direccion !== "" ? false : true}
								
							/>

					</Grid.Column>
					<Grid.Column computer={1} mobile={16} tablet={16}> 
						<h5> CP</h5>
							<Form.Input  
								disabled={disabledGeneral}
								name="clinica_codigo_postal"
								fluid 
								placeholder="Ingrese un código postal"
								value={formik.values.clinica_codigo_postal} 
								onChange={(_,data)=>formik.setFieldValue("clinica_codigo_postal",data.value)}
								error={formik.values.clinica_codigo_postal !== "" ? false : true}
								
								
							/>

					</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
					<Grid.Column computer={10} mobile={16} tablet={16}>
					<h5>Comentarios</h5>
							<TextArea 
							disabled={disabledGeneral}
							name="clinica_comentario"
							value={formik.values.clinica_comentario}
							onChange={(_,data)=>formik.setFieldValue("clinica_comentario",data.value)}
						
							/>
					</Grid.Column>
					
					
					</Grid.Row>

					<h5 style={{marginBottom:'5px'}}>Documentos</h5>
					{!disabledGeneral &&  (
						<Grid.Row>
							<Grid.Column computer={16} mobile={16} tablet={16}>
							<Select 
							name='tipo_documento'
							placeholder='Seleccionar Tipo' options={tiposDocs.map((x) => {
								return {key: x.id, value: x.id, text: x.nombre}
							})}
							onChange={handleTipoDocChange}
							value={tipoDocumento}
							/>
						

							{/* <Select placeholder='Seleccionar' options={[{key: 'pdf', value:'pdf', text:'PDF'}]}/> */}
							<input
								className="input"
								accept="'image/jpg'"
								type="file"
								style={{marginTop:'5px'}}
								onChange={(e) => handleChange(e)}
								disabled={disabledGeneral || !tipoDocumento}
							/>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row>
						<Grid.Column computer={16} mobile={16} tablet={16}>
							{documentos.length> 0? documentos.map((doc) => {
								return (
									<div key={doc.id}>
										<a style={doc.deleted? {textDecoration:'line-through'}: {}} href={`${BASE_API}/${doc.url_archivo}`} rel='noreferrer' target='_blank'>{doc.tipo_documento_nombre}: {doc.url_archivo.split('/')[2].slice(32)}</a>
										{!disabledGeneral && <Button style={{margin:'5px 5px'}} size='mini' type='button' icon negative basic onClick={(e) => handleDocDelete(doc.id)}>
											<Icon  name='close' />
										</Button>}
									</div>
								)
							}): <p>No hay documentos adjuntos</p>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
					<Button  fluid disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting} type="submit" content={isAddMode ? "Guardar" : "Actualizar"} primary onClick={() => handleSave()} />
				</Grid.Column>
				<Grid.Column computer={2} mobile={16} tablet={16}> 
					
					<Button fluid content="Volver" primary component={Link} href='/admin/clinica_orion/listado'/>
				</Grid.Column>
				<Grid.Column computer={12} mobile={16} tablet={16}> 			
				</Grid.Column>
				
			 </Grid>
			 </Form>
	)
}
function validationSchema(){
	
	return {
		clinica_nombre: Yup.string().required(true),
		clinica_direccion: Yup.string().required(true),
		clinica_provincia: Yup.string().required(true),
		clinica_localidad: Yup.string().required(true),
		clinica_codigo_postal: Yup.string().required(true),
		clinica_telefono: Yup.string().required(true),
  }
}

function initialValues(clinica){
	return{
		clinica_nombre: clinica?.clinica_nombre || "",
		clinica_direccion: clinica?.clinica_direccion || "",
		clinica_provincia: clinica?.clinica_provincia || "",
		clinica_localidad: clinica?.clinica_localidad || "",
		clinica_comentario:clinica?.clinica_comentario || "",
		clinica_codigo_postal:clinica?.clinica_codigo_postal || "",
		clinica_telefono:clinica?.clinica_telefono || "",
	}
}