import React, { useState } from "react";
import "./LoginForm.scss";
import { Button, Form, Grid } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  generateCSRFToken,
  loginApi,
  loginBackoffice,
} from "../../../api/Common/user";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks";
import {
  Redirect,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import axios from "axios";

export function LoginForm() {
  const login = useAuth();
  const [show, setShow] = useState(false);
  /* 
	recibe un objeto de configuracion 
		1-valor inicial del formulario
		2- validaciones
		3- submit
	 */
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
        const response = await loginApi(formValue);
        const { access } = response;

        login.login(access);
      } catch (error) {
        toast.error(error.message);
      }

      await loginBackoffice(formValue)
    },
  });

  if (login.auth !== null) return <Redirect to={"/admin"} />;

  const switchShow = () => {
    setShow(!show);
  };

  return (
    <Form className="login-form-admin" onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid.Column computer={8} mobile={16} tablet={16}>
          <Form.Input
            name="username"
            placeholder="Usuario *"
            label="Usuario *"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.errors.username}
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} tablet={16}>
          <Form.Input
            name="password"
            placeholder="contraseña *"
            label="contraseña *"
            type={show ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
            /* 
				
				input={<Button className='sacar_margin_input_password' inverted   icon={show ? "eye" : "eye slash"}  circular  type="button" onClick={switchShow}/> } */
          ></Form.Input>
        </Grid.Column>
      </Grid>
      <Grid container>
        <Grid.Column computer={8} mobile={16} tablet={16}></Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={16}>
          <Button fluid basic color="red" component={Link} href={"/recovery"}>
            Olvidó su clave?
          </Button>
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={16}>
          <Button fluid basic type="submit" primary>
            Iniciar sesión
          </Button>
        </Grid.Column>
      </Grid>
    </Form>
  );
}

function initialValues() {
  return {
    username: "",
    password: "",
  };
}

function validationSchema() {
  return {
    username: Yup.string().required(true),
    password: Yup.string().required(true),
  };
}
