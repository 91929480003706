import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './ListadoServicios.scss'
import { useAuth } from '../../../hooks';
import { getPermissions,getPermissionsSecondary } from '../../Common/Permissions';
export  function ListadoServicios(props) {
  const {servicios, onDeleteServicio,onEditServicio,pagination,onShowServicio,onAddFacturacionServicio,onEditFacturacionServicio,countPage,onOrdering, column,direction} = props
  
  const {auth} = useAuth()
  
  const class_name_sorted = direction === "ascending" ? "ascending sorted" :  "descending sorted"
  return (
    <Table sortable size='small' celled  fixed  className='tabla-servicios' >
    <Table.Header  /* className='color_header_table' */>
   
      <Table.Row >
        
		{getPermissionsSecondary("id",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'id' ? direction : null} onClick={()=>onOrdering('id')}>ID</Table.HeaderCell>}
		{getPermissionsSecondary("num_siniestro",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'numero' ? direction : null} onClick={()=>onOrdering('numero')}>N° Siniestro</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_dni",auth,'list','orion') &&  <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_dni' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_dni')}>DNI</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_apellido_nombre",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_apellido' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_apellido')}>Beneficiario</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_provincia",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_provincia' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_provincia')}>Provincia</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_localidad",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_localidad' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_localidad')}>Localidad</Table.HeaderCell>}
		{getPermissionsSecondary("aseguradora_long_name",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__aseguradora__long_name' ? direction : null} onClick={()=>onOrdering('beneficiario__aseguradora__long_name')}>Aseguradora</Table.HeaderCell>}
		{getPermissionsSecondary("estado_descripcion",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'estado' ? direction : null} onClick={()=>onOrdering('estado')}>Estado</Table.HeaderCell>}
		{getPermissionsSecondary("obra_social_descripcion",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'obra_social' ? direction : null} onClick={()=>onOrdering('obra_social')}>Obra social</Table.HeaderCell>}
		{getPermissionsSecondary("nombre_contacto",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'nombre_contacto' ? direction : null} onClick={()=>onOrdering('nombre_contacto')}>Nombre del contacto</Table.HeaderCell>}
		{getPermissionsSecondary("apellido_contacto",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'apellido_contacto' ? direction : null} onClick={()=>onOrdering('apellido_contacto')}>Apellido del contacto</Table.HeaderCell>}
		{getPermissionsSecondary("telefono_contacto",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'telefono_contacto' ? direction : null} onClick={()=>onOrdering('telefono_contacto')}>Teléfono del contacto</Table.HeaderCell>}
		{getPermissionsSecondary("accidente_lugar",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'accidente_lugar' ? direction : null} onClick={()=>onOrdering('accidente_lugar')}>Lugar del accidente</Table.HeaderCell>}
		{getPermissionsSecondary("accidente_fecha_hora",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'accidente_fecha_hora' ? direction : null} onClick={()=>onOrdering('accidente_fecha_hora')}>Fecha del accidente</Table.HeaderCell>}
		{getPermissionsSecondary("accidente_descripcion",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'accidente_descripcion' ? direction : null} onClick={()=>onOrdering('accidente_descripcion')}>Descripción del accidente</Table.HeaderCell>}
		{getPermissionsSecondary("notas",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'notas' ? direction : null} onClick={()=>onOrdering('notas')}>Notas</Table.HeaderCell>}

		{getPermissionsSecondary("clinica_nombre",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_nombre' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_nombre')}>Nombre(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_provincia",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_provincia__nombre' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_provincia__nombre')}>Provincia(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_localidad",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_localidad__nombre' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_localidad__nombre')}>Localidad(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_direccion",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_direccion' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_direccion')}>Dirección(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_telefono",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_telefono' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_telefono')}>Teléfono(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_codigo_postal",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_codigo_postal' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_codigo_postal')}>CP(s) clínica</Table.HeaderCell>}
		{getPermissionsSecondary("clinica_comentario",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'servicio_clinica__clinica__clinica_comentario' ? direction : null} onClick={()=>onOrdering('servicio_clinica__clinica__clinica_comentario')}>Comentario(s) clínica</Table.HeaderCell>}

		{getPermissionsSecondary("beneficiario_id_poliza",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__id_poliza' ? direction : null} onClick={()=>onOrdering('beneficiario__id_poliza')}>N° Póliza</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_suma_asegurada",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__suma_asegurada' ? direction : null} onClick={()=>onOrdering('beneficiario__suma_asegurada')}>Suma asegurada</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_calle",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_calle' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_calle')}>Calle</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_numero",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_numero' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_numero')}>Num.</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_codigo_postal",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_codigo_postal' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_codigo_postal')}>CP</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_fecha_nacimiento",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_fecha_nacimiento' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_fecha_nacimiento')}>Fecha de nac.</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_fecha_alta_poliza",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__asegurado_fecha_alta_poliza' ? direction : null} onClick={()=>onOrdering('beneficiario__asegurado_fecha_alta_poliza')}>Fecha alta póliza</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_tomador_codigo",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__tomador_codigo' ? direction : null} onClick={()=>onOrdering('beneficiario__tomador_codigo')}>Código tomador</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_tomador_apellido",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__tomador_apellido' ? direction : null} onClick={()=>onOrdering('beneficiario__tomador_apellido')}>Apellido tomador</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_tomador_nombre",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__tomador_nombre' ? direction : null} onClick={()=>onOrdering('beneficiario__tomador_nombre')}>Nombre tomador</Table.HeaderCell>}
		{getPermissionsSecondary("beneficiario_tipo_cobertura",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'beneficiario__tipo_cobertura' ? direction : null} onClick={()=>onOrdering('beneficiario__tipo_cobertura')}>Tipo de cobertura</Table.HeaderCell>}
		{getPermissionsSecondary("asegurado_telefono",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'asegurado_telefono' ? direction : null} onClick={()=>onOrdering('asegurado_telefono')}>Teléfono asegurado</Table.HeaderCell>}
		{getPermissionsSecondary("url_autorizacion",auth,'list','orion') && <Table.HeaderCell className={class_name_sorted} sorted={column === 'url_autorizacion' ? direction : null} onClick={()=>onOrdering('url_autorizacion')}>URL autorización</Table.HeaderCell>}
      
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {servicios?.map((servicio,i)=>{
          Moment.locale('en');
		let nombre_clinicas = servicio.servicio_clinica.map(cli=>cli?.clinica?.clinica_nombre) 
		let provincias_clinicas = servicio.servicio_clinica.map(cli=>cli?.clinica?.clinica_provincia?.nombre) 
		let localidades_clinicas = servicio.servicio_clinica.map(cli=>cli?.clinica?.clinica_localidad?.nombre) 
		
        return(<Table.Row key={servicio.id}>
         
         
         {getPermissionsSecondary("id",auth,'list','orion') && <Table.Cell > {servicio?.id}</Table.Cell>}
         {getPermissionsSecondary("num_siniestro",auth,'list','orion') && <Table.Cell > {servicio?.num_siniestro}</Table.Cell>}
         {getPermissionsSecondary("beneficiario_dni",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_dni}</Table.Cell>}
         {getPermissionsSecondary("beneficiario_apellido_nombre",auth,'list','orion') &&<Table.Cell > {servicio?.beneficiario?.asegurado_apellido}, {servicio?.beneficiario?.asegurado_nombre}</Table.Cell>}
         {getPermissionsSecondary("beneficiario_provincia",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_provincia}</Table.Cell>}
         {getPermissionsSecondary("beneficiario_localidad",auth,'list','orion') &&<Table.Cell > {servicio?.beneficiario?.asegurado_localidad}</Table.Cell>}
         {getPermissionsSecondary("aseguradora_long_name",auth,'list','orion') && <Table.Cell > {servicio?.beneficiario?.aseguradora?.long_name}</Table.Cell>}
         {getPermissionsSecondary("estado_descripcion",auth,'list','orion') && <Table.Cell > {servicio?.estado?.descripcion}</Table.Cell>}
		 {getPermissionsSecondary("obra_social_descripcion",auth,'list','orion') && <Table.Cell >{servicio?.obra_social?.descripcion}</Table.Cell>}
		 {getPermissionsSecondary("nombre_contacto",auth,'list','orion') && <Table.Cell >{servicio?.nombre_contacto}</Table.Cell>}
		{getPermissionsSecondary("apellido_contacto",auth,'list','orion') && <Table.Cell >{servicio?.apellido_contacto}</Table.Cell>}
		{getPermissionsSecondary("telefono_contacto",auth,'list','orion') && <Table.Cell >{servicio?.telefono_contacto}</Table.Cell>}

		{getPermissionsSecondary("accidente_lugar",auth,'list','orion') && <Table.Cell >{servicio?.accidente_lugar}</Table.Cell>}
		{getPermissionsSecondary("accidente_fecha_hora",auth,'list','orion') && <Table.Cell > <Icon name='clock' /> {Moment(servicio?.accidente_fecha_hora).format('DD-MM-YYYY H:mma')}</Table.Cell>}
		{getPermissionsSecondary("accidente_descripcion",auth,'list','orion') && <Table.Cell >{servicio?.accidente_descripcion}</Table.Cell>}
		
		{getPermissionsSecondary("notas",auth,'list','orion') && <Table.Cell >{servicio?.notas}</Table.Cell>}
		
		{getPermissionsSecondary("clinica_nombre",auth,'list','orion') && <Table.Cell >{nombre_clinicas.join(",")}</Table.Cell>}
		{getPermissionsSecondary("clinica_provincia",auth,'list','orion') && <Table.Cell >{provincias_clinicas.join(",")}</Table.Cell>}
		{getPermissionsSecondary("clinica_localidad",auth,'list','orion') && <Table.Cell >{localidades_clinicas.join(",")}</Table.Cell>}
		{getPermissionsSecondary("clinica_direccion",auth,'list','orion') && <Table.Cell >{servicio?.clinica?.clinica_direccion}</Table.Cell>}
		{getPermissionsSecondary("clinica_telefono",auth,'list','orion') && <Table.Cell >{servicio?.clinica?.clinica_telefono}</Table.Cell>}
		{getPermissionsSecondary("clinica_codigo_postal",auth,'list','orion') && <Table.Cell >{servicio?.clinica?.clinica_codigo_postal}</Table.Cell>}
		{getPermissionsSecondary("clinica_comentario",auth,'list','orion') && <Table.Cell >{servicio?.clinica?.clinica_comentario}</Table.Cell>}


		{getPermissionsSecondary("beneficiario_id_poliza",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.id_poliza}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_suma_asegurada",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.suma_asegurada}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_calle",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_calle}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_numero",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_numero}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_codigo_postal",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_codigo_postal}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_fecha_nacimiento",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_fecha_nacimiento}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_fecha_alta_poliza",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.asegurado_fecha_alta_poliza}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_tomador_codigo",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.tomador_codigo}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_tomador_apellido",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.tomador_apellido}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_tomador_nombre",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.tomador_nombre}</Table.Cell>}
		{getPermissionsSecondary("beneficiario_tipo_cobertura",auth,'list','orion') &&  <Table.Cell > {servicio?.beneficiario?.tipo_cobertura?.long_name}</Table.Cell>}
		{getPermissionsSecondary("asegurado_telefono",auth,'list','orion') &&  <Table.Cell > {servicio?.asegurado_telefono}</Table.Cell>}
		{getPermissionsSecondary("url_autorizacion",auth,'list','orion') &&  <Table.Cell > {servicio?.url_autorizacion}</Table.Cell>}


         <Acciones auth={auth} servicio={servicio} onShowServicio={onShowServicio} onDeleteServicio={onDeleteServicio} onEditServicio={onEditServicio} onAddFacturacionServicio={onAddFacturacionServicio} onEditFacturacionServicio={onEditFacturacionServicio}/>
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
		Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {servicio,onDeleteServicio,onEditServicio, onAddFacturacionServicio,onEditFacturacionServicio ,onShowServicio,auth} = props

 

  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_servicio",auth) &&
    <Popup content='Ver derivación' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowServicio(servicio)}>
    <Icon name='eye' />
  </Button>} />}
        {(getPermissions("add_facturacion",auth) && servicio.facturacion === null) &&
      <Popup content='Agregar facturación' trigger={<Button size='mini' icon basic onClick={()=>onAddFacturacionServicio(servicio)}>
      <Icon name='file' />
    </Button>} />  }
      {(getPermissions("change_facturacion",auth) && servicio.facturacion !== null) && 
      <Popup content='Editar facturación' trigger={<Button size='mini' icon basic onClick={()=>onEditFacturacionServicio(servicio)}>
      <Icon name='file' />
    </Button>} />  }
      {getPermissions("change_servicio",auth) &&
      <Popup content='Editar derivación' trigger={<Button size='mini' icon basic onClick={()=>onEditServicio(servicio)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_servicio",auth) &&
      <Popup content='Eliminar derivación' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteServicio(servicio)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}