import React from "react";

import { BrowserRouter, Route, Switch,Link } from "react-router-dom";
import {map} from 'lodash';
import routes from './routes';
export  function Navigation() {
  return (
    <BrowserRouter>
      <div>
     

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
         {map(routes, (route,index)=>(
              <Route 
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props)=>(
                  <route.layout>
                    <route.component {...props} />
                  </route.layout>
                )}
              >
              </Route>
         ))}
        </Switch>
      </div>
    </BrowserRouter>
  );
}
