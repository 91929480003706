import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './Clinica.scss'
import { useAuth } from '../../../hooks';
import { getPermissions } from '../../Common/Permissions';
export  function ListadoClinicas(props) {
  const {clinicas, onDeleteClinica,onEditClinica,pagination,onShowClinica,countPage} = props
  
  const {auth} = useAuth()
  return (
    <Table size='small' celled  className='tabla-servicios'>
    <Table.Header className='color_header_table'>
   
      <Table.Row >
        <Table.HeaderCell>Nombre</Table.HeaderCell>
        <Table.HeaderCell>Provincia</Table.HeaderCell>
        <Table.HeaderCell>Localidad</Table.HeaderCell>
        <Table.HeaderCell>Dirección</Table.HeaderCell>
        <Table.HeaderCell>Teléfono</Table.HeaderCell>
        <Table.HeaderCell>Código postal</Table.HeaderCell>
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {
          clinicas.map((clinica,i)=>{
        
            return(<Table.Row key={i}>
           
             <Table.Cell > {clinica.clinica_nombre}</Table.Cell>
             <Table.Cell > {clinica.clinica_provincia?.nombre}</Table.Cell>
             <Table.Cell > {clinica.clinica_localidad?.nombre}</Table.Cell>
             <Table.Cell > {clinica.clinica_direccion}</Table.Cell>
             <Table.Cell > {clinica.clinica_telefono}</Table.Cell>
             <Table.Cell > {clinica.clinica_codigo_postal}</Table.Cell>
           
             <Acciones auth={auth} clinica={clinica} onShowClinica={onShowClinica} onDeleteClinica={onDeleteClinica} onEditClinica={onEditClinica} />
           </Table.Row>
         
          )})
        
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {clinica,onDeleteClinica,onEditClinica, onShowClinica,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_clinica",auth) &&
    <Popup content='Ver clínica' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowClinica(clinica)}>
    <Icon name='eye' />
  </Button>} />}
        
     
      {getPermissions("change_clinica",auth) &&
      <Popup content='Editar clínica' trigger={<Button size='mini' icon basic onClick={()=>onEditClinica(clinica)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_clinica",auth) &&
      <Popup content='Eliminar clínica' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteClinica(clinica)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}