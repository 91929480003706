import React from 'react'
import Background from './images/login_lizen.jpeg'; 
import { Grid,Container,Segment } from 'semantic-ui-react'
import './scss/LoginLayout.scss'
export function LoginLayout(props) {
    const {children} = props
  return (
	
 <div className={`wrapper`} >
		<main className={`main login`}>
			<Container  fluid className="h-100">
				<Grid  columns={2} celled padded className='h-100'>
						
						<Grid.Column  width={4} style={{height: '100%'}} only='tablet computer'>
							<div className="bg-login h-100 align-items-center" style={{ backgroundImage: `url(${Background})` }}>

							</div>
						</Grid.Column>
						<Grid.Column  width={12} style={{height: '100%'}} className="align-items-center d-flex" mobile={16} tablet={12} computer={12}>
							{children}
						</Grid.Column>
					
				</Grid>
			</Container>
		</main>
	</div> 
  )
}

