import {
    Button,
    Grid,
    GridRow,
    Input,
    Loader,
    Segment,
} from "semantic-ui-react";
import "./ServicesAdmin.scss"
import {useState} from "react";
import DetalleBeneficiarioMista from "./components/DetalleBeneficiarioMista";
import MistaService from "../../../../services/Mista";

const BeneficiariosMista = () => {

    const [dni, setDni] = useState("");
    const [beneficiario, setBeneficiario] = useState('');
    const [loading, setLoading] = useState(false);

    async function obtenerBeneficiario(){
        setLoading(true);
        setBeneficiario(await MistaService.obtenerBeneficiario(dni));
        setLoading(false);
    }

    return (
        <>
            <h1>Beneficiarios Mista</h1>
            <Segment>
                <h3>Buscador</h3>
                    <Grid>
                        <GridRow>
                            <Grid.Column style={{paddingRight:'0'}} mobile={16} tablet={16} computer={4}>
                                <label>Ingrese el DNI del beneficiario: </label>
                                <Grid.Row>
                                    <Input
                                        style={{marginRight: "5px"}}
                                        type={"text"}
                                        value={dni}
                                        placeholder="DNI del beneficiario"
                                        onChange={(e) => setDni(e.target.value)}
                                    />
                                    <Button
                                        style={{minWidth:"100px", maxHeight:"36px"}}
                                        disabled={loading || dni === ""}
                                        onClick={(e) => obtenerBeneficiario()}
                                    >{loading
                                        ?<Loader active inline size={"mini"} />
                                        :"Buscar"}
                                    </Button>
                                </Grid.Row>
                            </Grid.Column>
                        </GridRow>
                    </Grid>
            </Segment>
            {<DetalleBeneficiarioMista beneficiario={beneficiario}/>}
        </>
    )
}

export default BeneficiariosMista;