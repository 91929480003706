import React from 'react'
import './ModalBasic.scss'
import { Modal,Button,Icon } from 'semantic-ui-react'
export  function ModalBasic(props) {
    const {show,size, title,children,onClose,onConfirm,dataModal,buttonSi,buttonNo, laoding} = props
  return (
   <Modal className='modal-basic' open={show} size={size}>
    {title && <Modal.Header>{title}</Modal.Header>}
    <Modal.Content>
       {children}
    </Modal.Content>
    <Modal.Actions>
        <Button  color='red' inverted onClick={onClose}>
          <Icon name='remove' /> {buttonNo !== undefined ? buttonNo : "No"}
        </Button>
        <Button color='green' inverted disabled={laoding} onClick={() =>onConfirm(dataModal)}>
          <Icon name='checkmark' /> {buttonSi !== undefined ? buttonSi : "Si"}
        </Button>
      </Modal.Actions>
   </Modal>
  )
}
