import {BASE_API} from '../../utils/constantes'
import { getToken } from './token'






export async function fetchUploadFile (request){
    

    const url =  `${BASE_API}/api/pdf_upload/`
    const params = {
        method:"POST",
        headers:{
          
            Authorization:`Bearer ${getToken()}`
        },
        body: request
    }
    const pdf_upload = await fetch(url,params)

    const response_pdf_upload =  await pdf_upload.json()
    return response_pdf_upload
}

