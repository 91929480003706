import React, { div, useEffect, useState } from 'react'
import { Header,Grid,Form,Segment ,Dropdown,Button,Icon, Label,List} from 'semantic-ui-react'
import "./ServicesAdmin.scss"
import {toast} from 'react-toastify'
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { getServicioOrion } from '../../../../api/Orion/servicio';
import { getFacturacionOrion,addFacturacionOrion,editFacturacionOrion,getFacturacionArchivo,deleteFacturacionArchivo,createOrionFacturacionArchivo } from '../../../../api/Orion/facturacion';
import { fetchUploadFile } from '../../../../api/Common/file';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import "./ServicesAdmin.scss"
import { ModalBasic } from '../../../../components/Common';

export  function ServicesOrionBilling(props) {
	const hiddenFileInput = React.useRef(null);

	const {auth} = useAuth()
	const {history} = props;
	const {id} = props.match.params
	const isAddMode = !id;
	const [filesFacturacion, setFilesFacturacion] = useState([]);
	
	const [servicio,setServicio] = React.useState({})
	const [facturacion,setFacturacion] = React.useState({})
	
	const [showModal,setShowModal] = useState(false)
	const [contentModal,setContentModal] = useState(null)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
	const fetchFacturacionArchivos = async () =>{

		
		const facturas = await getFacturacionArchivo(`factura=${id}`)
		
		setFilesFacturacion(facturas.results)
		formik.setFieldValue("url_archivo",facturas.results)
		
		
	} 
	useEffect(()=>{
		
		const fetchServicio = async () =>{
			const servicio = await getServicioOrion(`${history.location.state.servicio_id}`)
			setServicio(servicio)
		} 
		fetchServicio()
		if(!isAddMode){
			const fetchFacturacion = async () =>{
				const facturacion = await getFacturacionOrion(`${id}`)
				
				setFacturacion(facturacion)
				
			} 
			fetchFacturacion()
			fetchFacturacionArchivos()
			
		}
		
    },[])

	const formik = useFormik({
		initialValues:initialValues(servicio,facturacion),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{

			formValue["servicio"] = servicio?.id
			  if (isAddMode){
				const response = await addFacturacionOrion(formValue)

				try {
					
					if(response.status !== undefined && response.status === "error" ){
						toast.error(`Error en el sistema: ${JSON.stringify(response.message)}`)
					}else{
						
						toast.success("Se guardó exitosamente.",{
							onClose: () => {
								history.push(`/admin/servicios_orion/listado`);
							},
							autoClose: 1000 
						})    
					}
					
				} catch (error) {
					toast.error(JSON.stringify(error.message))
				} 
			}else{
				const response = await editFacturacionOrion(id,formValue)
				
				try {
					
					if(response.status !== undefined && response.status === "error" ){
						toast.error(`Error en el sistema: ${JSON.stringify(response.message)}`)
					}else{
						
						
					 	toast.success("Se guardó exitosamente.",{
							onClose: () => {
								history.push(`/admin/servicios_orion/listado`);
							},
							autoClose: 1000
						})    
					}
					
				} catch (error) {
					toast.error(JSON.stringify(error.message))
				} 
			}  
		},
		enableReinitialize: isAddMode ? false : true
	})
	
	const handleUploadClick =async event => {
		
		const formData = new FormData()
		let array_files = []
		let files = event.target.files
		
		Array.from(files).map(file=>{
			
			if (file) {
				formData.append('file', file)
	
				array_files.push(file.name)
			}
		})
		
		const response_pdf_upload_factura = await fetchUploadFile(formData)
		
		if(!isAddMode){
			formik.setFieldValue("url_archivo",response_pdf_upload_factura.data)
			/* 
			La vista es editar: como ya cree el archivo, lo asocio a la entidad facturacion
			*/
			const response_createFacturacionArchivo = await createOrionFacturacionArchivo(facturacion,response_pdf_upload_factura.data)
			
			if(response_createFacturacionArchivo.status === "sucess"){
				await new Promise(resolve => setTimeout(resolve, 1000));
				fetchFacturacionArchivos()
			}
			
			
		}else{
			
			if(filesFacturacion.length > 0){
				let array_new_facturas = filesFacturacion
				response_pdf_upload_factura.data.map(leg=>array_new_facturas.push(leg))
				setFilesFacturacion(array_new_facturas)
				formik.setFieldValue("url_archivo",array_new_facturas)
			}else{
				setFilesFacturacion(response_pdf_upload_factura.data)
				formik.setFieldValue("url_archivo",response_pdf_upload_factura.data)
			}
		}
	};
	
	const handleClick = event => {
    	hiddenFileInput.current.click();
  	};
	
	const handleClickDeletefactura = (id_factura) => {
		setContentModal(<p>Desea eliminar esta factura ?</p>)
		setTitleModal("Eliminar archivo")
		setShowModal(true)
		setDataModal({'accion':'eliminar','documento':'factura','id_factura':id_factura})
    	
  	};

	const handleClickDeleteCreateFactura=(factura)=>{

		let array_new_facturas = []
		array_new_facturas = filesFacturacion.filter(e=>e !== factura)
		setFilesFacturacion(array_new_facturas)	
	}

  	const openCloseModal = ()=>{
		
		setShowModal((prev)=>!prev)
	}

	const openConfirmModal = async(data_obj)=>{
		
		if(data_obj.documento === "factura") {
			if(data_obj.accion === "agregar"){
				setShowModal(false)
				hiddenFileInput.current.click();
			}

			if(data_obj.accion === "eliminar"){
				try {
					let response = await deleteFacturacionArchivo(data_obj.id_factura)
				
					toast.success(response)
					setShowModal(false)
					fetchFacturacionArchivos()
				} catch (error) {
					console.log(error)
				}
			}
		}
  	}

	const files_factura_show = filesFacturacion.map((facturacion,i)=>{
		if(isAddMode){
			return(<List.Item>
				<a href={`${BASE_API}/${facturacion}`} target="_blank">{facturacion.split('/')[2].slice(32)}</a>
				<Button 
					
					compact={true}  
					icon 
					basic 
					type="button" 
					target="_blank"
					circular
					size='mini'
					onClick={()=>handleClickDeleteCreateFactura(facturacion)}
				>
				<Icon name="delete" color='red'/>
				</Button>
			</List.Item>)
		}else{
			return(<List.Item>
				<a href={`${BASE_API}/${facturacion.url_archivo}`} target="_blank">{facturacion.url_archivo.split('/')[2].slice(32)}</a>
				<Button 
					
					compact={true}  
					icon 
					basic 
					type="button" 
					target="_blank"
					circular
					size='mini'
					onClick={()=>handleClickDeletefactura(facturacion.id)}
				>
				<Icon name="delete" color='red'/>
				</Button>
			</List.Item>)
		}
		
})
	
  return (
	<div>
    <Form  onSubmit={formik.handleSubmit}>
		<Segment color='blue'><strong>FACTURA: {servicio.beneficiario && servicio.beneficiario.asegurado_nombre + " " + servicio.beneficiario.asegurado_apellido}</strong></Segment>
		{filesFacturacion.length > 0 && 
			(<Segment><p>
				Archivo(s) seleccionado(s)</p>
				<Label.Detail>{files_factura_show}</Label.Detail>
			</Segment>)
		}
		<Grid>
			<Grid.Row >
				<Grid.Column computer={16} mobile={16} tablet={16}>  
				<label className='dropzone'>
						<Header icon disabled >
						<Icon name='upload' circular/>
						</Header>
						<Button 
							type="button" 
							variant="contained" 
							color="primary" 
							component="span" 
							icon 
							onClick={handleClick}
						>
						Agregar documento(s)		
						</Button>
					</label>
					<input
						className="input"
						accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
						id="ButtonProductUpFile"
						type="file"
						onChange={handleUploadClick}
						style={{display: 'none'}}
						ref={hiddenFileInput}
						multiple
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		<Grid as={Segment}>
			<Grid.Column computer={2} mobile={16} tablet={16}> 
				<Button  type="submit"  fluid  content={"Guardar"} primary />
			</Grid.Column>
			<Grid.Column computer={2} mobile={16} tablet={16}> 	
				<Button  fluid content="Volver" primary component={Link} href='/admin/servicios_orion/listado'/>
			</Grid.Column>
			<Grid.Column computer={12} mobile={16} tablet={16}> </Grid.Column>
		</Grid>
    </Form>
	<ModalBasic 
		show={showModal} 
		size="small" 
		title={titleModal} 
		children={contentModal}
		onClose={openCloseModal}
		onConfirm={openConfirmModal}
		dataModal={dataModal}
	/>
	 </div>
	)
}

function initialValues(servicio,billing){
	return {
		filesFacturacion: []
	}
}

function validationSchema(){
	return {
	}
}

