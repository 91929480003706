import React from 'react'

export default function Reducer_clinica(state,action) {
   
    switch (action.type) {
        

		case "UPDATE_PROVIDER":
			
			if(action.provData)
				state.proveedoresData = action.provData

			if(action.proveedoresOptions )
		    	state.proveedoresOptions = action.proveedoresOptions

			if(action.proveedores)
				state.proveedores = action.proveedores

			if(action.proveedor)
				state.proveedor = action.proveedor
			return {...state}

		case "UPDATE_PROVEEDOR":
			//state.city[action.indice] = action.city
			return {...state}
		
		
		case "SET_PROVINCIA":
			if(action.provincias !== undefined)
				state.provincias = action.provincias


			return {...state}
		
		case "SET_LOCALIDAD":
			if(action.localidades !== undefined)
				state.localidades = action.localidades
			
 		   return {...state}

        default:
          throw new Error()
      }
}
