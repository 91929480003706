import React, { useEffect, useState } from 'react'
import { Form,Grid,Segment,Button,Dropdown,Icon, TextArea ,Radio } from 'semantic-ui-react';
import { addProveedor, editProveedorLizen, getProveedor } from '../../../../api/Lizen/proveedor';
import { addComentario, putComentario, deleteComentario } from '../../../../api/Lizen/comentario';
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import { getProvincias } from '../../../../api/Common/provincia';
import { getLocalidades } from '../../../../api/Common/localidad';
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Reducer from "./Reducer_proveedor"
import "./AbmAdmin.scss"

export  function ProveedorLizenCreateEditShow(props) {
	const initialState = {
		
		provincias:[],
		localidades:[]
	}

	const [state, dispatch] = React.useReducer(Reducer, initialState)
	const { 
		provincias,
		localidades
	} = state

  const {auth} = useAuth()
	const {history} = props;
	const {id,type_action} = props.match.params
	const disabledGeneral = type_action === "show" ? true : false
	const [listaComentarios, setListaComentarios] = useState([]);
	const isAddMode = !id;
  const [proveedor,setProveedor] = React.useState([])
  const formik = useFormik({
		initialValues:initialValues(proveedor),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
				if (isAddMode){
					
					const response = await addProveedor(formValue,listaComentarios)
					
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							
							 toast.success("Se guardó exitosamente el proveedor.",{
								onClose: () => {
									history.push(`/admin/proveedor_lizen/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {

						toast.error(JSON.stringify(error.message))
					} 
				}else{
					const response = await editProveedorLizen(formValue,id,listaComentarios)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							
							 toast.success("Se guardó exitosamente el proveedor.",{
								onClose: () => {
									history.push(`/admin/proveedor_lizen/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
					 
				} 
			
		},
		enableReinitialize: isAddMode ? false :false
	})
	const agregarComentario = () => {
		const nuevoComentario = {
		  id_coment: listaComentarios.length + 1,
		  texto: ''
		};
	  
		setListaComentarios(listaComentarios.concat(nuevoComentario));
	};

	const eliminarComentario = async (index,comentario) => {
		if(comentario.id !== undefined){
			let eliminarComentario = await deleteComentario(comentario.id)
			.then(data=>{
				setListaComentarios(prevListaComentarios => {
					return prevListaComentarios.filter((_, i) => i !== index);
				  });
			})
		}else{
			setListaComentarios(prevListaComentarios => {
				return prevListaComentarios.filter((_, i) => i !== index);
			  });
		}
		
	  };
	
  useEffect(()=>{
		
    if(!isAddMode){
    	const fetchProveedor = async () =>{
      const proveedor = await getProveedor(`${props.match.params.id}`)
      .then(data=>{
          
		changeProvincia(data?.provincia?.id)
		fetchLocalidades(data?.provincia?.id)
		formik.setFieldValue("is_active",data.is_active)
		formik.setFieldValue("cuit",data.cuit)
		formik.setFieldValue("razon_social",data.razon_social)
		formik.setFieldValue("nombre_fantasia",data.nombre_fantasia)
		formik.setFieldValue("calle",data.calle)
		formik.setFieldValue("numero",data.numero)
		formik.setFieldValue("codigo_postal",data.codigo_postal)
		formik.setFieldValue("provincia",data?.provincia.id)
		formik.setFieldValue("localidad",data?.localidad.id)
		formik.setFieldValue("telefono",data.telefono)
		formik.setFieldValue("celular",data.celular)
		formik.setFieldValue("email",data.email)
		formik.setFieldValue("email_alternativo",data.email_alternativo)
		setListaComentarios(data.comentario)

		setProveedor(data)
      })
    } 
    fetchProveedor()
	
  }
  fetchProvincias()
 
  },[])
  const changeProvincia = async(value)=>{
	
	formik.setFieldValue("provincia",value)
	let localidades_process =  await fetchLocalidades(value)
	

	dispatch({type:"SET_LOCALIDAD",localidades:localidades_process})
}
	const fetchProvincias = async () =>{
		const provincias = await getProvincias()
		.then(data=>{
			let provincias_process = data.results.map((x) => {
				return{ key: x.id, text: x.nombre, value: x.id }
			})
			dispatch({type:"SET_PROVINCIA",provincias: provincias_process})	
			
		})
	}

	const fetchLocalidades = async(provincia)=>{
		const localidades = await getLocalidades(`provincia=${provincia}`)
		.then(data =>{
			let localidades_process = data.results.map((x) => {
				return { key: x.id, text: x.nombre, value: x.id };
			})
			return localidades_process	
		})

		return localidades
		
	}
	const handleRadio = (e, { value }) => formik.setFieldValue("is_active",value);
  return (
	<Form  onSubmit={formik.handleSubmit} style={{display:'grid'}}>
		<p><strong>Proveedor</strong></p>
			<Grid as={Segment}>
		
				<Grid.Row className='segment_border_primary'>
				<	Grid.Column computer={1} mobile={16} tablet={16}> 
						<Radio
							disabled={disabledGeneral}
							label='Habilitar'
							name='is_active'
							value={"1"}
							checked={formik.values.is_active == "1"}
							defaultChecked
							onChange={handleRadio}
						/>
						</Grid.Column>
						<Grid.Column computer={1} mobile={16} tablet={16}> 
						<Radio
							disabled={disabledGeneral}
							name='is_active'
							label="Deshabilitar"
							value={"0"}
							checked={formik.values.is_active == "0"}
							onChange={handleRadio}
							
						/>
					</Grid.Column>
					<Grid.Column computer={14} mobile={16} tablet={16}></Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>CUIT</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="cuit"
							fluid 
							placeholder="Ingrese un cuit"
							value={formik.values.cuit} 
							onChange={(_,data)=>formik.setFieldValue("cuit",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>* Razón Social</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="razon_social"
							fluid 
							placeholder="Ingrese una razón social"
							value={formik.values.razon_social} 
							onChange={(_,data)=>formik.setFieldValue("razon_social",data.value)}
							error={formik.values.razon_social !== "" ? false : true}
							
						/>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Nombre Fantasía</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="nombre_fantasia"
							fluid 
							placeholder="Ingrese un nombre de fantasia"
							value={formik.values.nombre_fantasia} 
							onChange={(_,data)=>formik.setFieldValue("nombre_fantasia",data.value)}
							
						/>

					</Grid.Column>
					
					<Grid.Column computer={1} mobile={16} tablet={16}>
					</Grid.Column>

					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Calle</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="calle"
							fluid 
							placeholder="Ingrese una calle"
							value={formik.values.calle} 
							onChange={(_,data)=>formik.setFieldValue("calle",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>Número</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="numero"
							fluid 
							placeholder="Ingrese un número"
							value={formik.values.numero} 
							onChange={(_,data)=>formik.setFieldValue("numero",data.value)}
							
						/>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Código Postal</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="codigo_postal"
							fluid 
							placeholder="Ingrese un código postal"
							value={formik.values.codigo_postal} 
							onChange={(_,data)=>formik.setFieldValue("codigo_postal",data.value)}
							
						/>

					</Grid.Column>
					
					<Grid.Column computer={1} mobile={16} tablet={16}>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
						<h5>* Provincia</h5>
							
							
							<Dropdown 
								search
								disabled={disabledGeneral}
								placeholder='Seleccione una provincia'
								name="provincia" 
								options={provincias}    
								value={formik.values.provincia}    
								onChange={(_,data)=>changeProvincia(data.value)}  
								selection 
								fluid
								error={formik.errors.provincia}

							/>
							
						</Grid.Column>

						<Grid.Column computer={5} mobile={16} tablet={16}>
							<h5 >* Localidad</h5>
							<Dropdown 
								search
								disabled={disabledGeneral}
								placeholder='Seleccione una ciudad'
								name="localidad"
								options={localidades}   
								value={formik.values.localidad}  
								  
								onChange={(_, data) => {formik.setFieldValue("localidad",data.value)}}

								selection 
								fluid
								error={formik.errors.localidad}

							/>	
							</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
					
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>Teléfono</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="telefono"
							fluid 
							placeholder="Ingrese un teléfono"
							value={formik.values.telefono} 
							onChange={(_,data)=>formik.setFieldValue("telefono",data.value)}
							
						/>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Celular</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="celular"
							fluid 
							placeholder="Ingrese un celular"
							value={formik.values.celular} 
							onChange={(_,data)=>formik.setFieldValue("celular",data.value)}
							
						/>

					</Grid.Column>
					
					<Grid.Column computer={6} mobile={16} tablet={16}>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>Email</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="email"
							fluid 
							placeholder="Ingrese un teléfono"
							value={formik.values.email} 
							onChange={(_,data)=>formik.setFieldValue("email",data.value)}
							
						/>
					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>Email alternativo</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="email_alternativo"
							fluid 
							placeholder="Ingrese un email alternativo"
							value={formik.values.email_alternativo} 
							onChange={(_,data)=>formik.setFieldValue("email_alternativo",data.value)}
							
						/>

					</Grid.Column>
					
					<Grid.Column computer={6} mobile={16} tablet={16}>
					</Grid.Column>
					
					</Grid.Row>
					<Grid.Row className='segment_border_primary'>
							<Grid.Column computer={16} mobile={16} tablet={16}>
								<p className='titulo_comentarios'>Comentarios {!disabledGeneral &&<Button icon 
									basic 
									type="button" 
									size='mini'
									circular
									onClick={agregarComentario}><Icon name="add" color='green'/></Button>}</p>
							</Grid.Column>
					
							
							{listaComentarios.map((comentario,index) => (
								
								<React.Fragment key={comentario.id_coment}>
									<Grid.Column computer={16} mobile={16} tablet={16}><p style={{visibility: 'hidden'}}> _</p> </Grid.Column>
									<Grid.Column computer={10} mobile={16} tablet={16}>
										<TextArea
										disabled={disabledGeneral}
										value={comentario.texto}
										onChange={e => {
											const textoComentario = e.target.value;
											setListaComentarios(prevListaComentarios => {
												
											return prevListaComentarios.map(prevComentario => {
												if(prevComentario.id_coment !== undefined){
													if (prevComentario.id_coment === comentario.id_coment)
														return { ...prevComentario, texto: textoComentario };
												}else{
													if (prevComentario.id === comentario.id)
														return { ...prevComentario, texto: textoComentario };
												}
												
												return prevComentario;
											});
											});
										}}
										/>
									</Grid.Column>
									<Grid.Column computer={6} mobile={16} tablet={16}>
										 {!disabledGeneral &&<Button 
										compact={true}  
										icon 
										basic 
										type="button" 
										size='mini'
										circular
										onClick={() => eliminarComentario(index,comentario)}><Icon name="delete" color='red'/>
									</Button>}
										</Grid.Column>
										
										
									</React.Fragment>
									
								
							))}
							
						</Grid.Row>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
					<Button  fluid disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting} type="submit" content={isAddMode ? "Guardar" : "Actualizar"} primary />
					
				</Grid.Column>
				<Grid.Column computer={2} mobile={16} tablet={16}> 
					
					<Button fluid content="Volver" primary component={Link} href='/admin/proveedor_lizen/listado'/>
				</Grid.Column>
				<Grid.Column computer={12} mobile={16} tablet={16}> 
					
			
				</Grid.Column>
				
			 </Grid>
			 </Form>
	)
}
function validationSchema(){
	
	return {
		
		razon_social: Yup.string().required(true),
		provincia: Yup.number().required(true),
		localidad: Yup.string().required(true),
  }
}

function initialValues(proveedor){
	return{
		is_active: proveedor?.is_active || "1",
		cuit: proveedor?.cuit || "",
		razon_social: proveedor?.razon_social || "",
		nombre_fantasia: proveedor?.nombre_fantasia || "",
		calle: proveedor?.calle || "",
		numero: proveedor?.numero || "",
		codigo_postal: proveedor?.codigo_postal || "",
		provincia: proveedor?.provincia || "",
		localidad: proveedor?.localidad || "",
		telefono: proveedor?.telefono || "",
		celular: proveedor?.celular || "",
		email: proveedor?.email || "",
		email_alternativo: proveedor?.email_alternativo || "",
		comentario: proveedor?.comentario || "",
	}
}