import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './Aseguradora.scss'
import { useAuth } from '../../../hooks';
import { getPermissions } from '../../Common/Permissions';
export  function ListadoAseguradoras(props) {
  const {aseguradoras, onDeleteAseguradora,onEditAseguradora,pagination,onShowAseguradora,countPage} = props
  
  const {auth} = useAuth()
  return (
    <Table size='small' celled  className='tabla-servicios'>
    <Table.Header className='color_header_table'>
   
      <Table.Row >
        <Table.HeaderCell>Nombre corto </Table.HeaderCell>
        <Table.HeaderCell>Nombre largo</Table.HeaderCell>
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {aseguradoras.map((aseguradora,i)=>{
        
        return(<Table.Row key={i}>
         
       
         <Table.Cell > {aseguradora.short_name}</Table.Cell>
         <Table.Cell > {aseguradora.long_name}</Table.Cell>
       
         <Acciones auth={auth} aseguradora={aseguradora} onShowAseguradora={onShowAseguradora} onDeleteAseguradora={onDeleteAseguradora} onEditAseguradora={onEditAseguradora} />
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {aseguradora,onDeleteAseguradora,onEditAseguradora, onShowAseguradora,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_aseguradora",auth) &&
    <Popup content='Ver aseguradora' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowAseguradora(aseguradora)}>
    <Icon name='eye' />
  </Button>} />}
        
     
      {getPermissions("change_aseguradora",auth) &&
      <Popup content='Editar aseguradora' trigger={<Button size='mini' icon basic onClick={()=>onEditAseguradora(aseguradora)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_aseguradora",auth) &&
      <Popup content='Eliminar aseguradora' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteAseguradora(aseguradora)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}