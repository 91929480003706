import React, { useEffect, useState } from 'react'
import { Form,Grid,Segment,Button } from 'semantic-ui-react';
import { addAseguradoraOrion, editAseguradoraOrion, getAseguradora } from '../../../../api/Orion/aseguradora';
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'

import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";

export  function AseguradoraOrionCreateEditShow(props) {
  const {auth} = useAuth()
	const {history} = props;
	const {id,type_action} = props.match.params
	const disabledGeneral = type_action === "show" ? true : false
	const isAddMode = !id;
  const [aseguradora,setAseguradora] = React.useState([])
  const formik = useFormik({
		initialValues:initialValues(aseguradora),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
			 
				if (isAddMode){
					
					const response = await addAseguradoraOrion(formValue)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
						
							 toast.success("Se guardó exitosamente la aseguradora.",{
								onClose: () => {
									history.push(`/admin/aseguradora_orion/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
				}else{
					const response = await editAseguradoraOrion(id,formValue)
					try {
						
						if(response.status === "error"  ){
							toast.error(`Error en el sistema: ${JSON.stringify(response)}`)
						}else{
							
							
							 toast.success("Se guardó exitosamente la aseguradora.",{
								onClose: () => {
									history.push(`/admin/aseguradora_orion/listado`);
								},
								autoClose: 1000
							})  
						}
						
					} catch (error) {
						toast.error(JSON.stringify(error.message))
					} 
					 
				} 
			
		},
		enableReinitialize: isAddMode ? false :false
	})
  useEffect(()=>{
		
    if(!isAddMode){
    const fetchAseguradora = async () =>{
      const servicio = await getAseguradora(`${props.match.params.id}`)
      .then(data=>{
          
          
          formik.setFieldValue("short_name",data.short_name)
          formik.setFieldValue("long_name",data.long_name)
        
          setAseguradora(data)
      })
    } 
    fetchAseguradora()
  }
  
 
  },[])

  return (
	<Form  onSubmit={formik.handleSubmit} style={{display:'grid'}}>
		<p><strong>Aseguradora</strong></p>
			<Grid as={Segment}>
		
				<Grid.Row className='segment_border_primary'>
				
					<Grid.Column computer={5} mobile={16} tablet={16}> 
					<h5>* Nombre corto</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="short_name"
							fluid 
							placeholder="Ingrese un nombre corto"
							value={formik.values.short_name} 
							onChange={(_,data)=>formik.setFieldValue("short_name",data.value)}
							error={formik.values.short_name !== "" ? false : true}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>
					<h5>* Nombre largo</h5>
						<Form.Input  
							disabled={disabledGeneral}
							name="long_name"
							fluid 
							placeholder="Ingrese un nombre largo"
							value={formik.values.long_name} 
							onChange={(_,data)=>formik.setFieldValue("long_name",data.value)}
							error={formik.values.long_name !== "" ? false : true}
							
						/>
					</Grid.Column>
					
					<Grid.Column computer={6} mobile={16} tablet={16}>
					</Grid.Column>
					</Grid.Row>
					<Grid.Column computer={2} mobile={16} tablet={16}> 
					<Button  fluid disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting} type="submit" content={isAddMode ? "Guardar" : "Actualizar"} primary />
					
				</Grid.Column>
				<Grid.Column computer={2} mobile={16} tablet={16}> 
					
					<Button fluid content="Volver" primary component={Link} href='/admin/aseguradora_orion/listado'/>
				</Grid.Column>
				<Grid.Column computer={12} mobile={16} tablet={16}> 
					
			
				</Grid.Column>
				
			 </Grid>
			 </Form>
	)
}
function validationSchema(){
	
	return {
		short_name: Yup.string().required(true),
		long_name: Yup.string().required(true)
  }
}

function initialValues(tipoEstudio){
	return{
		short_name: tipoEstudio?.short_name || "",
		long_name: tipoEstudio?.long_name || ""
	}
}