import React, { Fragment, useEffect, useRef } from "react";
import {
  Modal,
  Icon,
  Label,
  Grid,
  Form,
  Segment,
  Dropdown,
  Search,
  TextArea,
  Button,
} from "semantic-ui-react";
import "./ServicesAdmin.scss";
import Reducer from "./Reducer";
import { toast } from "react-toastify";
import {
  Redirect,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { getBeneficiario } from "../../../../api/Orion/beneficiario";
import { getTiposEstudio } from "../../../../api/Orion/tipoEstudio";
import { getClinica, getClinicas } from "../../../../api/Orion/clinica";
import {
  addServicioOrion,
  getServicioOrion,
  editServicioOrion,
} from "../../../../api/Orion/servicio";
import { fetchUploadFile } from "../../../../api/Common/file";
import { BeneficiarioOrionCreateEditShow } from "../../AbmAdmin/Orion/";
import { getProvincias } from "../../../../api/Common/provincia";
import { getLocalidades } from "../../../../api/Common/localidad";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../../hooks";
import { BASE_API } from "../../../../utils/constantes";
import Moment from "moment";
import {
  getPermissionsSecondary,
  getPermissions,
} from "../../../../components/Common";
import { getFacturacionArchivo } from "../../../../api/Orion/facturacion";

Moment.locale("en");

function Acciones(props) {
  const {
    content,
    compact,
    color,
    nameIcon,
    component,
    href,
    action,
    actionName,
    actionParams,
    title,
    description,
    contentPopup,
  } = props;

  const actionOnClick = () => {
    props.callbackAcciones("", actionParams.dni);
  };
  return (
    <Modal
      className="modal-basic"
      trigger={
        <Button
          type="button"
          compact={compact}
          icon
          basic
          color={color}
          circular
          size="mini"
        >
          <Icon name={nameIcon} />
        </Button>
      }
      header={title}
      content={description}
      onClose={actionOnClick}
      actions={[
        {
          key: "Cerrar para seguir con la carga del serivicio",
          content:
            "Volver a la carga del servicio. Si no se guardo, se perderan todos los datos del beneficiario",
          positive: true,
          basic: true,
        },
      ]}
    />
  );
}

const Estudio = ({
  auth,
  type_action,
  index,
  estudio,
  disabledGeneral,
  formik,
  onChangeTipo_estudio,
  indice_clinica,
  onChangeImporte,
  onChangeDetalle,
  handleUploadFileEstudio,
  removeEstudio,
  handleUploadFileRemove
}) => {
  const timeoutRef = React.useRef();

  return (
    <Fragment>
      {!disabledGeneral && (
        <Grid.Column computer={1} mobile={16} tablet={16}>
          <h5 style={{ visibility: "hidden" }}>* Detalle</h5>
          <Button
            disabled={disabledGeneral}
            type="button"
            onClick={() => removeEstudio(indice_clinica, index)}
            icon
            basic
            compact={true}
            color="red"
            circular
            size="mini"
          >
            <Icon name={"delete"} />
          </Button>
        </Grid.Column>
      )}
      {getPermissionsSecondary(
        "estudio_tipo_estudio",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={3} mobile={16} tablet={16}>
          <h5>* Tipo estudio</h5>
          <Dropdown
            search
            disabled={disabledGeneral}
            options={estudio.tipos_estudios}
            value={estudio.tipo_estudio}
            selection
            fluid
            onChange={(e, data) =>
              onChangeTipo_estudio(e, data.value, indice_clinica, index)
            }
            error={estudio.tipo_estudio !== "" ? false : true}
          />
        </Grid.Column>
      )}
      {getPermissionsSecondary(
        "estudio_importe",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={2} mobile={16} tablet={16}>
          <h5> Importe</h5>

          <Form.Input
            disabled={disabledGeneral}
            name="importe"
            type="number"
            fluid
            placeholder="Ingrese un importe"
            value={estudio.importe}
            onChange={(_, data) =>
              onChangeImporte(data.value, indice_clinica, index)
            }
            error={formik.values.saldo_suma_asegurada < 0 ? true : false}
          />
          <p>Formato numérico sin puntos ni comas.</p>
        </Grid.Column>
      )}
      {getPermissionsSecondary(
        "estudio_descripcion",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={3} mobile={16} tablet={16}>
          <h5> Detalle</h5>

          <TextArea
            disabled={disabledGeneral}
            name="descripcion"
            value={estudio.descripcion}
            onChange={(_, data) =>
              onChangeDetalle(data.value, indice_clinica, index)
            }
          />
        </Grid.Column>
      )}

      {getPermissionsSecondary(
        "estudio_url_archivo",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={4} mobile={16} tablet={16}>
          {estudio?.url_archivo !== "" ? (
            <>
              <h5> Informe</h5>
              <p>
                <a href={`${BASE_API}/${estudio?.url_archivo}`} rel="noreferrer" target="_blank">
                  {estudio?.url_archivo.split("/")[2].slice(32)}
                </a>
				<Button
					disabled={disabledGeneral}
					type="button"
					onClick={() => handleUploadFileRemove(indice_clinica, index)}
					icon
					basic
					compact={true}
					color="red"
					circular
					size="mini"
					style={{margin: '5px'}}
				>
					<Icon name={"delete"} />
				</Button>
              </p>
            </>
          ) : (
            !disabledGeneral && (
              <>
                <h5> Adjuntar Informe</h5>
                <input
                  disabled={disabledGeneral}
                  accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
                  type="file"
                  onChange={(e, _) =>
                    handleUploadFileEstudio(e, indice_clinica, index)
                  }
                />{" "}
              </>
            )
          )}
        </Grid.Column>
      )}
      <Grid.Column computer={3} mobile={16} tablet={16}>
        {" "}
      </Grid.Column>
    </Fragment>
  );
};

function Clinica({
  auth,
  type_action,
  formik,
  indice,
  disabledGeneral,
  onDelete,
  onChange,
  clinica,
  onAdd,
  onChangeTipo_estudio,
  onChangeImporte,
  onChangeDetalle,
  handleUploadFileEstudio,
  handleUploadFileRemove,
  removeEstudio,
}) {
  return (
    <Fragment>
      {getPermissionsSecondary(
        "clinica_provincia",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={5} mobile={16} tablet={16}>
          <h5>* Provincia</h5>
          <Dropdown
            fluid
            search
            disabled={disabledGeneral}
            placeholder="Seleccione una provincia"
            name="clinica_provincia"
            options={clinica?.provincias}
            value={clinica?.provincia}
            onChange={(e, data) => onChange(e, data.value, "provincia", indice)}
            selection
            error={clinica?.provincia !== "" ? false : true}
          />
        </Grid.Column>
      )}
      {getPermissionsSecondary(
        "clinica_localidad",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={5} mobile={16} tablet={16}>
          <h5>*Localidad</h5>
          <Dropdown
            fluid
            search
            disabled={disabledGeneral}
            placeholder="Seleccione una ciudad"
            name="clinica_localidad"
            id="clinica_localidad"
            options={clinica?.ciudades}
            value={clinica?.localidad}
            onChange={(e, data) => onChange(e, data.value, "localidad", indice)}
            selection
            error={clinica?.localidad !== "" ? false : true}
          />
        </Grid.Column>
      )}
      {!disabledGeneral && (
        <Grid.Column computer={2} mobile={16} tablet={16}>
          <h5>Eliminar filtros</h5>
          <Button
            disabled={disabledGeneral}
            type="button"
            onClick={() => onDelete(indice, "filtros")}
            icon
            basic
            compact={true}
            color="red"
            circular
            size="mini"
          >
            <Icon name={"delete"} />
          </Button>
        </Grid.Column>
      )}

      <Grid.Column computer={4} mobile={16} tablet={16}></Grid.Column>
      {getPermissionsSecondary(
        "clinica_nombre",
        auth,
        type_action,
        "orion"
      ) && (
        <Fragment>
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>*Clínica</h5>

            <Dropdown
              search
              disabled={disabledGeneral}
              placeholder="Seleccione una clínica"
              name="clinica_nombre"
              id="clinica_nombre"
              options={clinica?.clinicas}
              value={clinica?.id}
              onChange={(e, data) => onChange(e, data.value, "clinica", indice)}
              selection
              fluid
              error={clinica?.nombre !== "" ? false : true}
            />
          </Grid.Column>

          <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
        </Fragment>
      )}

      {getPermissionsSecondary(
        "clinica_direccion",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={5} mobile={16} tablet={16}>
          <h5>Dirección</h5>
          <Form.Input
            disabled
            name="clinica_direccion"
            value={clinica?.direccion}
            fluid
            placeholder="Ingrese apellido"

            //error={isAddMode ? clinica.direccion : (clinica.direccion !== "") ? false : true}

            //onChange={(_,data)=>formik.setFieldValue("clinica_direccion",data.value)}
          />
        </Grid.Column>
      )}
      {getPermissionsSecondary(
        "clinica_telefono",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={5} mobile={16} tablet={16}>
          <h5>Teléfono</h5>
          <Form.Input
            disabled
            name="clinica_telefono"
            value={clinica?.telefono}
            fluid
            placeholder=""

            //	error={isAddMode ? formik.errors.clinica_telefono : (clinica.telefono !== "") ? false : true}

            //onChange={(_,data)=>formik.setFieldValue("clinica_telefono",data.value)}
          />
        </Grid.Column>
      )}
      <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>

      {getPermissionsSecondary(
        "title_estudios",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <p>
            <strong>Estudios</strong>
            {!disabledGeneral && (
              <Button
                disabled={disabledGeneral}
                type="button"
                onClick={() => onAdd(indice)}
                icon
                basic
                compact={true}
                color="green"
                circular
                size="mini"
              >
                <Icon name={"add"} />
              </Button>
            )}
          </p>
        </Grid.Column>
      )}

      {clinica?.estudios.length > 0 &&
        clinica?.estudios.map((estudio, i) => (
          <Segment style={{ width: "100%" }} color="grey">
            <Grid>
              <Grid.Row>
                <Estudio
                  auth={auth}
                  type_action={type_action}
                  index={i}
                  estudio={estudio}
                  disabledGeneral={disabledGeneral}
                  formik={formik}
                  onChangeTipo_estudio={onChangeTipo_estudio}
                  indice_clinica={indice}
                  onChangeImporte={onChangeImporte}
                  onChangeDetalle={onChangeDetalle}
                  handleUploadFileEstudio={handleUploadFileEstudio}
				  handleUploadFileRemove={handleUploadFileRemove}
                  removeEstudio={removeEstudio}
                />
              </Grid.Row>
            </Grid>
          </Segment>
        ))}

      {!disabledGeneral && (
        <>
          <Grid.Column computer={13} mobile={16} tablet={16}></Grid.Column>
          <Grid.Column computer={3} mobile={16} tablet={16}>
            <Button
              floated="right"
              circular
              disabled={disabledGeneral}
              onClick={() => onDelete(indice, "clinica")}
              color="red"
              basic
            >
              Eliminar clínica
            </Button>
          </Grid.Column>
        </>
      )}
    </Fragment>
  );
}

function Facturas({ facturas, disabledGeneral, auth }) {
  return (
    // <Segment style={{ width: '100%'}} color='orange' padded='very'>
    <div style={{ marginTop: "20px" }}>
      <Grid.Row>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <p>
            <strong>Facturas</strong>
          </p>
        </Grid.Column>
      </Grid.Row>
      {facturas ? (
        facturas.map((factura) => (
          <Grid.Row>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <div style={{ display: "flex", gap: "15px" }}>
                <p>
                  <a
                    href={`${BASE_API}/${factura.url_archivo}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {factura.url_archivo.split("/")[2].slice(32)}
                  </a>
                </p>
                {!disabledGeneral &&
                  getPermissions("change_facturacion", auth) && (
                    <a href={`/admin/facturacion_orion/id/${factura.factura}`}>
                      <Icon name="edit" color="yellow" />
                    </a>
                  )}
              </div>
            </Grid.Column>
          </Grid.Row>
        ))
      ) : (
        <p>No hay facturas cargadas</p>
      )}
    </div>
    // </Segment>
  );
}

{
  /* </Segment> */
}
export default function ServicesOrionCreateEditShow(props) {
  const { auth } = useAuth();
  const { history } = props;
  const { id, type_action } = props.match.params;
  const disabledGeneral = type_action === "show" ? true : false;
  const isAddMode = !id;

  const [inputClinicas, setInputClinicas] = React.useState([]);
  const [servicio, setServicio] = React.useState({});
  const [facturas, setFacturas] = React.useState([]);

  const onDelete = (index, type) => {
    if (type === "clinica") return eliminarClinica(index);

    if (type === "filtros") return removeFilters(index);
  };
  const eliminarClinica = (indice) => {
    if (inputClinicas.length === 1) {
      toast.error("Al menos una clínica tiene que cargarse");
    } else {
      const newInputClinicas = inputClinicas.filter(
        (cli) => cli.props.indice !== indice
      );
      setInputClinicas(newInputClinicas);
      formik.values.clinicas.splice(indice, 1);
    }
  };

  const fetchTiposEstudios = async () => {
    const tipo_estudio = await getTiposEstudio().then((data) => {
      let response = data.map(({ id, long_name, value_ars }) => ({
        key: id,
        text: long_name,
        dataset: value_ars,
        value: id,
      }));
      return response;
    });
    return tipo_estudio;
  };
  const proccess_clinica = (data) => {
    let clinicas_process = data.map((x) => {
      return {
        key: x.id,
        text: x.clinica_nombre,
        value: x.id,
        dataset: JSON.stringify({
          clinica_localidad: x.clinica_localidad.id,
          clinica_provincia: x.clinica_provincia.id,
          clinica_direccion: x.clinica_direccion,
          id: x.id,
          clinica_telefono: x.clinica_telefono,
        }),
      };
    });
    return clinicas_process;
  };

  const fetchClinicas = async (index, actualizar_clinicas) => {
    const results_clinicas = await getClinicas().then((data) => {
      return proccess_clinica(data);
    });

    if (actualizar_clinicas) {
      let cli = formik.values.clinicas;
      cli[index].clinicas = results_clinicas;
      formik.setFieldValue("clinicas", cli);
    } else {
      return results_clinicas;
    }
  };

  const fetchFacturas = async () => {
    let factura = await getFacturacionArchivo(
      `factura=${servicio.facturacion}`
    );
    setFacturas(factura.results);
  };

  const fetchProvincias = async (index = 0, actualizar_provincias) => {
    const provincias = await getProvincias().then((data) => {
      let process_prov = data.results.map((x) => {
        return { key: x.id, text: x.nombre, value: x.id };
      });
      return process_prov;
    });
    if (actualizar_provincias) {
      let cli = formik.values.clinicas;
      cli[index].provincias = provincias;
      formik.setFieldValue("clinicas", cli);
    } else {
      return provincias;
    }
  };
  const fetchLocalidades = async (provincia) => {
    const ciudades = await getLocalidades(`provincia=${provincia}`).then(
      (data) => {
        let localidades_process = data.results.map((x) => {
          return { key: x.id, text: x.nombre, value: x.id };
        });
        return localidades_process;
      }
    );

    return ciudades;
  };
  const onChange = async (e, value, type_select, index) => {
    if (type_select === "provincia") return changeProvincia(value, index);

    if (type_select === "localidad") return changeLocalidad(value, index);

    if (type_select === "clinica") return changeClinica(e, value, index);
  };

  const changeProvincia = async (value, index) => {
    let cli = formik.values.clinicas;
    cli[index].provincia = value;

    let localidades_process = await fetchLocalidades(value);
    cli[index].ciudades = localidades_process;
    formik.setFieldValue("clinicas", cli);
  };

  const removeFilters = async (index) => {
    let cli = formik.values.clinicas;
    cli[index].provincia = "";
    cli[index].localidad = "";

    await fetchClinicas(index, false).then((data) => {
      cli[index].clinicas = data;
      formik.setFieldValue("clinicas", cli);
    });
  };

  const changeLocalidad = async (value, index) => {
    let cli = formik.values.clinicas;
    cli[index].localidad = value;

    let request_filtros = `&clinica_localidad=${value}`;

    const clinica = await getClinica(null, request_filtros).then((data) => {
      let clinicas_process = proccess_clinica(data.results);
      let cli = formik.values.clinicas;

      cli[index].clinicas = clinicas_process;
      formik.setFieldValue("clinicas", cli);
    });
    formik.setFieldValue("clinicas", cli);
  };
  const changeClinica = async (e, value, index) => {
    let cli = formik.values.clinicas;
    cli[index].nombre = value;

    let datasetKey = e.target.getAttribute("dataset");
    let parent = e.target.parentElement;

    if (datasetKey === null) {
      datasetKey = parent.getAttribute("dataset");
    }
    let clinicaSeleccionada = JSON.parse(datasetKey);
    cli[index].id = clinicaSeleccionada.id;
    cli[index].direccion = clinicaSeleccionada.clinica_direccion;
    cli[index].telefono = clinicaSeleccionada.clinica_telefono;

    changeProvincia(clinicaSeleccionada.clinica_provincia, index);
    changeLocalidad(clinicaSeleccionada.clinica_localidad, index);
  };
  function generateDateTime() {
    const dateTimeString = `${formik.values.accidente_fecha} ${formik.values.accidente_hora}`;
    const dateTime = Moment(dateTimeString, "YYYY-MM-DD HH:mm");

    return dateTime;
  }
  const formik = useFormik({
    initialValues: initialValues(auth, servicio),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      formValue["accidente_fecha_hora"] = generateDateTime();
      let request_servicioClinica = [];
      let request_servicioClinica_estudios = [];

      formValue.clinicas.map((cli) => {
        request_servicioClinica_estudios = [];
        cli.estudios.map((estudio) => {
          request_servicioClinica_estudios.push({
            tipo_estudio: estudio.tipo_estudio,
            importe: estudio.importe,
            descripcion: estudio.descripcion,
            url_archivo: estudio.url_archivo,
          });
        });

        request_servicioClinica.push({
          clinica: cli.id,
          estudio: request_servicioClinica_estudios,
        });
      });
      formValue["servicio_clinica"] = request_servicioClinica;
      //delete formValue.clinicas;

      if (isAddMode) {
        if (formik.values.fecha === null) {
          formik.values.fecha = Moment(new Date()).format("YYYY-MM-DD");
        }
        const response = await addServicioOrion(formValue);
        try {
          if (response.status === "error") {
            toast.error(`Error en el sistema: ${JSON.stringify(response)}`);
          } else {
            toast.success("Se guardó exitosamente el servicio.", {
              onClose: () => {
                history.push(`/admin/servicios_orion/listado`);
              },
              autoClose: 1000,
            });
          }
        } catch (error) {
          toast.error(JSON.stringify(error.message));
        }
      } else {
        const response = await editServicioOrion(id, formValue);
        try {
          if (response.status === "error") {
            toast.error(`Error en el sistema: ${JSON.stringify(response)}`);
          } else {
            toast.success("Se guardó exitosamente el servicio.", {
              onClose: () => {
                history.push(`/admin/servicios_orion/listado`);
              },
              autoClose: 1000,
            });
          }
        } catch (error) {

          toast.error(JSON.stringify(error.message));
        }
      }
    },
    enableReinitialize: isAddMode ? false : false,
  });

  const initialState = {
    provincias: [],
    clinicas: [],
    loading_search: false,
    results_search: [],
    value_search: "",
    tipos_estudios: [],
  };

  const [state, dispatch] = React.useReducer(Reducer, initialState);

  const estados = [
    { key: 1, text: "En tratamiento", value: 1 },
    { key: 2, text: "Abandono tratamiento", value: 2 },
    { key: 3, text: "Dado de alta", value: 3 },
    { key: 4, text: "No autorizado", value: 4 },
    { key: 5, text: "Pendiente de revisión", value: 5 },
    { key: 6, text: "con OS/Particular", value: 6 },
  ];

  const { loading_search, results_search } = state;

  const obraSocial = [
    { key: 1, text: "No", value: 1 },
    { key: 2, text: "Si", value: 2 },
    { key: 3, text: "Desconocido", value: 3 },
  ];

  const resultRenderer = ({
    asegurado_nombre,
    asegurado_apellido,
    asegurado_dni,
  }) => (
    <Label
      key={asegurado_dni}
      content={`${asegurado_nombre} ${asegurado_apellido}`}
    />
  );
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, value) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: "START_SEARCH", query: value, input: "dni" });
    formik.setFieldValue("asegurado_dni", value);

    timeoutRef.current = setTimeout(async () => {
      const beneficiario =
        value.length > 4 ? await getBeneficiario(null, `search=${value}`) : [];

      if (value.length === 0) {
        setearCamposVacios(true);
        return;
      }

      dispatch({
        type: "FINISH_SEARCH",
        results: beneficiario.results,
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (!isAddMode) {
      const fetchServicio = async () => {
        const servicio = await getServicioOrion(
          `${props.match.params.id}`
        ).then((data) => {
          // Obtener la fecha actual
          const datetime = new Date(data?.accidente_fecha_hora);

          // Crear una nueva variable para la fecha
          const date = datetime.toISOString().slice(0, 10);

          // Crear una nueva variable para la hora
          const time = datetime.toLocaleTimeString();

          //changeProvincia(data?.provincia.id)

          formik.setFieldValue("beneficiario", data?.beneficiario);
          formik.setFieldValue(
            "asegurado_num_poliza",
            data?.beneficiario?.id_poliza
          );
          formik.setFieldValue(
            "asegurado_nombre",
            data?.beneficiario?.asegurado_nombre
          );
          formik.setFieldValue(
            "asegurado_apellido",
            data?.beneficiario?.asegurado_apellido
          );
          formik.setFieldValue(
            "asegurado_provincia",
            data?.beneficiario?.asegurado_provincia
          );
          formik.setFieldValue(
            "asegurado_localidad",
            data?.beneficiario?.asegurado_localidad
          );
          formik.setFieldValue("asegurado_telefono", data?.asegurado_telefono);
          formik.setFieldValue("obra_social", data?.obra_social?.id);
          formik.setFieldValue("beneficiario", data?.beneficiario?.id);
          formik.setFieldValue(
            "asegurado_dni",
            data?.beneficiario?.asegurado_dni
          );
          formik.setFieldValue(
            "tipo_cobertura",
            data?.beneficiario?.tipo_cobertura?.long_name
          );

          formik.setFieldValue(
            "suma_asegurada",
            data?.beneficiario?.suma_asegurada
          );

          formik.setFieldValue("estado", data?.estado?.id);
          formik.setFieldValue("num_siniestro", data?.num_siniestro);
          formik.setFieldValue("apellido_contacto", data?.apellido_contacto);
          formik.setFieldValue("telefono_contacto", data?.telefono_contacto);
          formik.setFieldValue("nombre_contacto", data?.nombre_contacto);

          formik.setFieldValue("accidente_lugar", data?.accidente_lugar);
          formik.setFieldValue("accidente_fecha", date);
          formik.setFieldValue("accidente_hora", time);
          formik.setFieldValue(
            "accidente_descripcion",
            data?.accidente_descripcion
          );
          formik.setFieldValue("notas", data?.notas);
          formik.setFieldValue("url_autorizacion", data?.url_autorizacion);
          formik.setFieldValue("user", auth?.me?.id);

          let clinicas_totales = [];
          let inputs = [];

          let estudios = [];
          data?.servicio_clinica.map(async (cli, index) => {
            inputs.push(<Clinica indice={index} />);
            await fetchTiposEstudios().then(async (data_tipos_estudio) => {
              await fetchClinicas(null, false).then(async (data_clinicas) => {
                await fetchProvincias(0, false).then(
                  async (data_provincias) => {
                    await fetchLocalidades(
                      cli?.clinica?.clinica_provincia?.id
                    ).then((data_localidad) => {
                      estudios = [];
                      cli.estudio.map((est) => {
                        estudios.push({
                          tipo_estudio: est.tipo_estudio,
                          importe: est.importe,
                          descripcion: est.descripcion,
                          url_archivo: est.url_archivo,
                          tipos_estudios: data_tipos_estudio,
                        });
                      });
                      clinicas_totales.push({
                        direccion: cli?.clinica?.clinica_direccion,
                        id: cli?.clinica?.id,
                        nombre: cli?.clinica?.clinica_nombre,
                        provincia: cli?.clinica?.clinica_provincia?.id,
                        localidad: cli?.clinica?.clinica_localidad?.id,
                        telefono: cli?.clinica?.clinica_telefono,
                        provincias: data_provincias,
                        ciudades: data_localidad,
                        clinicas: data_clinicas,
                        estudios: estudios,
                      });
                    });
                  }
                );
              });
              formik.setFieldValue("clinicas", clinicas_totales);
              let saldo_suma_asegurada = calcularSaldoSumaAsegudara(
                data?.beneficiario?.suma_asegurada,
                clinicas_totales
              );
              formik.setFieldValue(
                "saldo_suma_asegurada",
                saldo_suma_asegurada
              );
            });
          });

          setInputClinicas(inputs);

          //(data?.clinica?.clinica_provincia?.id)
          //changeLocalidad(data?.clinica?.clinica_localidad?.id)

          setServicio(data);
        });
      };
      fetchServicio();
    } else {
      fetchProvincias(0, true);
      fetchClinicas(0, true);
      setInputClinicas([
        ...inputClinicas,
        <Clinica indice={inputClinicas.length} />,
      ]);
    }
  }, []);

  useEffect(() => {
    fetchFacturas();
  }, [servicio]);
  const setearCamposVacios = (boolean) => {
    dispatch({ type: "CLEAR_INPUT" });
  };
  const resultSelect = React.useCallback(async (e, data) => {
    formik.setFieldValue("beneficiario", data.result.id);
    formik.setFieldValue("asegurado_dni", data.result.asegurado_dni);
    formik.setFieldValue("asegurado_nombre", data.result.asegurado_nombre);
    formik.setFieldValue("asegurado_apellido", data.result.asegurado_apellido);
    formik.setFieldValue(
      "asegurado_localidad",
      data.result.asegurado_localidad
    );
    formik.setFieldValue(
      "asegurado_provincia",
      data.result.asegurado_provincia
    );
    formik.setFieldValue(
      "asegurado_tomador_cuenta",
      data.result.tomador_codigo
    );
    formik.setFieldValue("asegurado_num_poliza", data.result.id_poliza);
    formik.setFieldValue("suma_asegurada", data.result.suma_asegurada);
    formik.setFieldValue(
      "tipo_cobertura",
      data.result.tipo_cobertura.long_name
    );
    let saldo_suma_asegurada = calcularSaldoSumaAsegudara(
      data.result.suma_asegurada
    );
    formik.setFieldValue("saldo_suma_asegurada", saldo_suma_asegurada);

    dispatch({
      type: "UPDATE_SELECTION",
      selection: data.result,
      icon: "remove",
    });
  });

  const hijoAPadre = (datoshijo) => {
    formik.setFieldValue("asegurado_dni", datoshijo);
  };
  const addClinica = async (e) => {
    setInputClinicas([
      ...inputClinicas,
      <Clinica indice={inputClinicas.length} />,
    ]);
    const results = await fetchClinicas(inputClinicas.length, false).then(
      (data) => {
        formik.setFieldValue("clinicas", [
          ...formik.values.clinicas,
          {
            direccion: "",
            id: "",
            nombre: "",
            provincia: "",
            localidad: "",
            provincias: formik.values.clinicas[0].provincias,
            ciudades: [],
            clinicas: data,
            estudios: [],
          },
        ]);
      }
    );
  };

  const handleUploadFileAutorizacion = async (event) => {
    var file = event.target.files[0];
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
      const response_pdf_upload = await fetchUploadFile(formData);

      formik.setFieldValue("url_autorizacion", response_pdf_upload.data[0]);
    }
  };
  const handleClickDeleteAutorizacion = (factura) => {
    formik.setFieldValue("url_autorizacion", null);
  };
  /* 
		INICIO FUNCIONES RELACIONADAS AL BLOQUE ESTUDIOS
	*/
  const SegmentEstudioClinico = (props) => {
    const { disabled } = props;
    return (
      <Grid.Row className="segment_border_primary">
        <Grid.Column computer={8} mobile={16} tablet={16}>
          <p>
            <strong>LÍMITE DE COBERTURA</strong>
          </p>
        </Grid.Column>
        <Grid.Column computer={5} mobile={16} tablet={16}>
          {" "}
        </Grid.Column>
        <Grid.Column computer={3} mobile={16} tablet={16}>
          {!disabledGeneral && (
            <Button
              floated="right"
              circular
              disabled={disabled}
              onClick={addClinica}
              color="green"
              basic
            >
              Agregar clínica
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  };
  const calcularSaldoSumaAsegudara = (suma_asegurada, clinicas = null) => {
    let cli = clinicas === null ? formik.values.clinicas : clinicas;
    let importe_parcial = [];

    cli.map((clinica) => {
      clinica.estudios.map((estudo) => {
        importe_parcial.push(
          estudo.importe === "" ? 0 : parseInt(estudo.importe)
        );
      });
    });
    const sum = importe_parcial.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return suma_asegurada === "" ? sum : suma_asegurada - sum;
  };

  const addEstudio = async (indice) => {
    //let estudios = formik.values.estudio
    let cli = formik.values.clinicas;
    let tipos_estudios = await fetchTiposEstudios().then((data) => {
      cli[indice].estudios = [
        ...cli[indice].estudios,
        {
          tipos_estudios: data,
          tipo_estudio: "",
          importe: "",
          descripcion: "",
          url_archivo: "",
        },
      ];
    });
    formik.setFieldValue("clinicas", cli);
  };

  const onChangeTipo_estudio = (
    e,
    tipo_estudio,
    indice_clinica,
    indice_estudio
  ) => {
    let cli = formik.values.clinicas;
    cli[indice_clinica].estudios[indice_estudio].tipo_estudio = tipo_estudio;
    //el valor ars lo puse como un atributo dataset

    let datasetKey = e.target.getAttribute("dataset");
    let parent = e.target.parentElement;
    if (datasetKey === null) {
      datasetKey = e.target.parentElement.getAttribute("dataset");
    }
    cli[indice_clinica].estudios[indice_estudio].importe = datasetKey;
    let saldo_suma_asegurada = calcularSaldoSumaAsegudara(
      formik.values.suma_asegurada
    );
    formik.setFieldValue("saldo_suma_asegurada", saldo_suma_asegurada);
    formik.setFieldValue("clinicas", cli);
  };
  const onChangeImporte = (importe, indice_clinica, indice_estudio) => {
    let cli = formik.values.clinicas;
    cli[indice_clinica].estudios[indice_estudio].importe = importe;
    let saldo_suma_asegurada = calcularSaldoSumaAsegudara(
      formik.values.suma_asegurada
    );
    formik.setFieldValue("saldo_suma_asegurada", saldo_suma_asegurada);
    formik.setFieldValue("clinicas", cli);
  };

  const onChangeDetalle = (detalle, indice_clinica, indice_estudio) => {
    let cli = formik.values.clinicas;
    cli[indice_clinica].estudios[indice_estudio].descripcion = detalle;
    formik.setFieldValue("clinicas", cli);
  };

  const handleUploadFileEstudio = async (e, indice_clinica, indice_estudio) => {
    var file = e.target.files[0];
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
      const response_pdf_upload = await fetchUploadFile(formData).then(
        (data) => {
          let cli = formik.values.clinicas;
          cli[indice_clinica].estudios[indice_estudio].url_archivo =
            data.data[0];

          formik.setFieldValue("clinicas", cli);
        }
      );
    }
  };

  const handleUploadFileRemove = (indice_clinica, indice_estudio) => {
	let cli = formik.values.clinicas;
	cli[indice_clinica].estudios[indice_estudio].url_archivo = "";
	formik.setFieldValue("clinicas", cli);
  }

  const removeEstudio = (indice_clinica, indice_estudio) => {
    let cli = formik.values.clinicas;
    let estudios = cli[indice_clinica].estudios;

    estudios.splice(indice_estudio, 1);

    cli[indice_clinica].estudios = estudios;

    let saldo_suma_asegurada = calcularSaldoSumaAsegudara(
      formik.values.suma_asegurada
    );

    formik.setFieldValue("saldo_suma_asegurada", saldo_suma_asegurada);
    formik.setFieldValue("clinicas", cli);
  };

  /* 
		FIN FUNCIONES RELACIONADAS AL BLOQUE ESTUDIOS
	*/

  return (
    <Form onSubmit={formik.handleSubmit} style={{ display: "grid" }}>
      <p>
        <strong>
          Derivación:{" "}
          {formik.values.asegurado_nombre &&
            formik.values.asegurado_nombre.toUpperCase()}{" "}
          {formik.values.asegurado_apellido &&
            formik.values.asegurado_apellido.toUpperCase()}
        </strong>
      </p>
      {getPermissionsSecondary(
        "estado_descripcion",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid as={Segment}>
          <Grid.Column computer={5} mobile={16} tablet={16}>
            <h5>Estado</h5>
            <Dropdown
              disabled={disabledGeneral}
              clearable
              options={estados}
              value={formik.values.estado}
              selection
              fluid
              onChange={(_, data) => formik.setFieldValue("estado", data.value)}
            />
          </Grid.Column>
          <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
        </Grid>
      )}

      {getPermissionsSecondary(
        "segment_asegurado",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <p>
                <strong>ASEGURADO</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
          {getPermissionsSecondary(
            "beneficiario_dni",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>
                  * DNI{" "}
                  {!disabledGeneral && (
                    <Acciones
                      contentPopup="Agregar un asegurado"
                      title=""
                      description={
                        <BeneficiarioOrionCreateEditShow
                          hijoAPadre={hijoAPadre}
                          redireccionar={false}
                        />
                      }
                      content="Agrega un nuevo beneficiario"
                      compact={true}
                      color="green"
                      nameIcon="add"
                      href=""
                      //actionName="GetBeneficiario"
                      actionParams={{ dni: formik.values.asegurado_dni }}
                      callbackAcciones={handleSearchChange}
                    />
                  )}
                </h5>
                <Search
                  disabled={disabledGeneral}
                  input={{
                    name: "asegurado_dni",
                    icon: "search",
                    iconPosition: "left",
                    fluid: true,
                    error: formik.errors.asegurado_dni,
                  }}
                  loading={loading_search}
                  onResultSelect={resultSelect}
                  onSearchChange={(e, data) =>
                    handleSearchChange(e, data.value)
                  }
                  resultRenderer={resultRenderer}
                  results={results_search}
                  value={formik.values.asegurado_dni}
                  noResultsMessage="No se encontró el dni ingresado"
                  placeholder="Ingrese un DNI"
                  minCharacters={5}
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}

          {getPermissionsSecondary(
            "beneficiario_id_poliza",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>N° Póliza </h5>
                <Form.Input
                  disabled
                  name="asegurado_num_poliza"
                  fluid
                  placeholder="Ingrese un N° Póliza"
                  value={formik.values.asegurado_num_poliza}
                  onChange={(_, data) =>
                    formik.setFieldValue("asegurado_num_poliza", data.value)
                  }
                  error={
                    formik.values.asegurado_num_poliza !== "" ? false : true
                  }
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}

          {getPermissionsSecondary(
            "num_siniestro",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                {!isAddMode && (
                  <>
                    <h5>N° Siniestro</h5>
                    <Form.Input
                      disabled
                      name="num_siniestro"
                      fluid
                      value={formik.values.num_siniestro}
                    />
                  </>
                )}
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            {getPermissionsSecondary(
              "beneficiario_nombre",
              auth,
              type_action,
              "orion"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Nombre</h5>
                <Form.Input
                  value={formik.values.asegurado_nombre}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "beneficiario_apellido",
              auth,
              type_action,
              "orion"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Apellido</h5>
                <Form.Input
                  value={formik.values.asegurado_apellido}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            <Grid.Column computer={6} mobile={16} tablet={16}>
              {" "}
            </Grid.Column>
          </Grid.Row>

          {getPermissionsSecondary(
            "asegurado_telefono",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>*Teléfono</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  value={formik.values.asegurado_telefono}
                  fluid
                  onChange={(_, data) =>
                    formik.setFieldValue("asegurado_telefono", data.value)
                  }
                  error={formik.values.asegurado_telefono !== "" ? false : true}
                  placeholder="Ingrese un teléfono"
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>{" "}
            </Grid.Row>
          )}

          {getPermissionsSecondary(
            "beneficiario_provincia",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <h5>Provincia</h5>

              <Form.Input
                value={formik.values.asegurado_provincia}
                fluid
                disabled
              />
            </Grid.Column>
          )}

          {getPermissionsSecondary(
            "beneficiario_localidad",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Column computer={5} mobile={16} tablet={16}>
              <h5>Localidad</h5>
              <Form.Input
                value={formik.values.asegurado_localidad}
                fluid
                disabled
              />
            </Grid.Column>
          )}
          <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
          <Grid.Row>
            {getPermissionsSecondary(
              "obra_social_descripcion",
              auth,
              type_action,
              "orion"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Obra social</h5>
                <Dropdown
                  disabled={disabledGeneral}
                  clearable
                  options={obraSocial}
                  value={formik.values.obra_social}
                  selection
                  fluid
                  onChange={(_, data) =>
                    formik.setFieldValue("obra_social", data.value)
                  }
                />
              </Grid.Column>
            )}
            {getPermissionsSecondary(
              "beneficiario_tipo_cobertura",
              auth,
              type_action,
              "orion"
            ) && (
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Tipo de cobertura</h5>
                <Form.Input
                  value={formik.values.tipo_cobertura}
                  fluid
                  disabled
                />
              </Grid.Column>
            )}
            <Grid.Column computer={6} mobile={16} tablet={16}>
              {" "}
            </Grid.Column>
          </Grid.Row>
          {getPermissionsSecondary(
            "beneficiario_tomador_codigo",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Tomador cuenta</h5>
                <Form.Input
                  value={formik.values.asegurado_tomador_cuenta}
                  fluid
                  disabled
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}>
                {" "}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}

      {getPermissionsSecondary(
        "segment_contacto",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <p>
                <strong>CONTACTO</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
          {getPermissionsSecondary(
            "apellido_contacto",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Apellido</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="apellido_contacto"
                  value={formik.values.apellido_contacto}
                  fluid
                  placeholder="Ingrese apellido"
                  onChange={(_, data) =>
                    formik.setFieldValue("apellido_contacto", data.value)
                  }
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
          {getPermissionsSecondary(
            "nombre_contacto",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Nombre</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="nombre_contacto"
                  value={formik.values.nombre_contacto}
                  fluid
                  placeholder="Ingrese un nombre"
                  onChange={(_, data) =>
                    formik.setFieldValue("nombre_contacto", data.value)
                  }
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}

          {getPermissionsSecondary(
            "telefono_contacto",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Teléfono</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="telefono_contacto"
                  value={formik.values.telefono_contacto}
                  fluid
                  placeholder="Ingrese un teléfono"
                  onChange={(_, data) =>
                    formik.setFieldValue("telefono_contacto", data.value)
                  }
                />
              </Grid.Column>

              <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
      {getPermissionsSecondary(
        "segment_accidente",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <p>
                <strong>DETALLE ACCIDENTE</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
          {getPermissionsSecondary(
            "accidente_lugar",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Lugar del accidente</h5>
                <Form.Input
                  disabled={disabledGeneral}
                  name="accidente_lugar"
                  value={formik.values.accidente_lugar}
                  fluid
                  placeholder="Ingrese un lugar"
                  error={formik.values.accidente_lugar !== "" ? false : true}
                  onChange={(_, data) =>
                    formik.setFieldValue("accidente_lugar", data.value)
                  }
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
          {getPermissionsSecondary(
            "accidente_fecha_hora",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={3} mobile={16} tablet={16}>
                <h5>* Fecha/Hora del accidente</h5>
                <Form.Input
                  type="date"
                  name="accidente_fecha"
                  fluid
                  value={formik.values.accidente_fecha}
                  onChange={(_, data) =>
                    formik.setFieldValue("accidente_fecha", data.value)
                  }
                  disabled={disabledGeneral}
                  error={formik.values.accidente_fecha !== "" ? false : true}
                />
              </Grid.Column>

              <Grid.Column computer={2} mobile={16} tablet={16}>
                <h5 style={{ visibility: "hidden" }}>* Hora</h5>
                <Form.Input
                  type="time"
                  name="accidente_hora"
                  fluid
                  value={formik.values.accidente_hora}
                  onChange={(_, data) =>
                    formik.setFieldValue("accidente_hora", data.value)
                  }
                  disabled={disabledGeneral}
                  error={formik.values.accidente_hora !== "" ? false : true}
                />
              </Grid.Column>

              <Grid.Column computer={10} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
          {getPermissionsSecondary(
            "accidente_descripcion",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>* Descripción del accidente</h5>

                <TextArea
                  disabled={disabledGeneral}
                  name="accidente_descripcion"
                  value={formik.values.accidente_descripcion}
                  onChange={(_, data) =>
                    formik.setFieldValue("accidente_descripcion", data.value)
                  }
                  error={formik.errors.accidente_descripcion}
                  style={
                    formik.values.accidente_descripcion === ""
                      ? { borderColor: "#9f3a38" }
                      : { borderColor: "#3498db" }
                  }
                />
              </Grid.Column>
              <Grid.Column computer={11} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}

      {getPermissionsSecondary(
        "segment_estudio_clinico",
        auth,
        type_action,
        "orion"
      ) && (
        <Grid as={Segment}>
          <SegmentEstudioClinico disabled={disabledGeneral} />
          {getPermissionsSecondary(
            "beneficiario_suma_asegurada",
            auth,
            type_action,
            "orion"
          ) && (
            <Grid.Row>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Suma Asegurada</h5>
                <Form.Input
                  disabled
                  name="suma_asegurada"
                  value={formik.values.suma_asegurada}
                  fluid
                  placeholder=""
                />
              </Grid.Column>
              <Grid.Column computer={5} mobile={16} tablet={16}>
                <h5>Saldo Suma Asegurada</h5>
                <Form.Input
                  disabled
                  name="saldo_suma_asegurada"
                  value={formik.values.saldo_suma_asegurada}
                  fluid
                  error={formik.values.saldo_suma_asegurada < 0 ? true : false}
                />
                {formik.values.saldo_suma_asegurada < 0 ? (
                  <p style={{ color: "red" }}>Se sobrepasó la suma asegurada</p>
                ) : (
                  ""
                )}
              </Grid.Column>

              <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
            </Grid.Row>
          )}
          {inputClinicas.map((data, i) => (
            <Segment style={{ width: "100%" }} color="orange" padded="very">
              <Grid>
                <Grid.Row>
                  <Clinica
                    auth={auth}
                    type_action={type_action}
                    formik={formik}
                    indice={data.props.indice}
                    disabledGeneral={disabledGeneral}
                    onDelete={onDelete}
                    onChange={onChange}
                    clinica={formik.values.clinicas[data.props.indice]}
                    onAdd={addEstudio}
                    onChangeTipo_estudio={onChangeTipo_estudio}
                    onChangeImporte={onChangeImporte}
                    onChangeDetalle={onChangeDetalle}
                    handleUploadFileEstudio={handleUploadFileEstudio}
					handleUploadFileRemove={handleUploadFileRemove}
                    removeEstudio={removeEstudio}
                  />
                </Grid.Row>
              </Grid>
              {/* <Segment style={{ width: '100%'}} color='black' padded='very'> */}
              {/* <Facturas facturas={facturas} disabledGeneral={disabledGeneral}/> */}
              {/* </Segment> */}
            </Segment>
          ))}
        </Grid>
      )}

      {getPermissions("add_facturacion", auth) && (
        <Grid as={Segment}>
          <Grid.Row className="segment_border_primary">
            <Grid.Column computer={8} mobile={16} tablet={16}>
              <Facturas
                facturas={facturas}
                disabledGeneral={disabledGeneral}
                auth={auth}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <Grid as={Segment}>
        <Grid.Row className="segment_border_primary">
          {getPermissionsSecondary(
            "url_autorizacion",
            auth,
            type_action,
            "orion"
          ) && (
            <Fragment>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                {formik.values.url_autorizacion !== null ? (
                  <>
                    <h5> Autorización</h5>
                    <p>
                      <a
                        href={`${BASE_API}/${formik.values.url_autorizacion}`}
                        target="_blank"
                      >{`${BASE_API}/${formik.values.url_autorizacion}`}</a>
                      {!disabledGeneral && (
                        <Button
                          compact={true}
                          icon
                          basic
                          target="_blank"
                          circular
                          size="mini"
                          onClick={handleClickDeleteAutorizacion}
                        >
                          <Icon name="delete" color="red" />
                        </Button>
                      )}
                    </p>
                  </>
                ) : (
                  !disabledGeneral && (
                    <>
                      {" "}
                      <h5> Adjuntar Autorización</h5>
                      <input
                        disabled={disabledGeneral}
                        accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
                        type="file"
                        onChange={handleUploadFileAutorizacion}
                      />{" "}
                    </>
                  )
                )}
              </Grid.Column>
              <Grid.Column computer={6} mobile={16} tablet={16}></Grid.Column>
            </Fragment>
          )}
          {getPermissionsSecondary("notas", auth, type_action, "orion") && (
            <Fragment>
              <Grid.Column computer={16} mobile={16} tablet={16}>
                <p>
                  <strong>NOTA DE IMPORTANCIA</strong>
                </p>
              </Grid.Column>
              <Grid.Column computer={10} mobile={16} tablet={16}>
                <TextArea
                  disabled={disabledGeneral}
                  name="nota"
                  value={formik.values.notas}
                  onChange={(_, data) =>
                    formik.setFieldValue("notas", data.value)
                  }
                />
              </Grid.Column>
            </Fragment>
          )}
        </Grid.Row>

        <Grid.Column computer={2} mobile={16} tablet={16}>
          <Button
            fluid
            disabled={disabledGeneral ? disabledGeneral : formik.isSubmitting}
            type="submit"
            content={isAddMode ? "Guardar" : "Actualizar"}
            primary
          />
        </Grid.Column>
        <Grid.Column computer={2} mobile={16} tablet={16}>
          <Button
            fluid
            content="Volver"
            primary
            component={Link}
            href="/admin/servicios_orion/listado"
          />
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} tablet={16}>
          {" "}
        </Grid.Column>
      </Grid>
    </Form>
  );
}

function initialValues(auth, servicio) {
  return {
    estado: servicio?.estado?.id || 1,
    estudio: servicio.estudio || [],
    num_siniestro: servicio.num_siniestro || "",
    asegurado_telefono: servicio.asegurado_telefono || "",
    obra_social: servicio?.obra_social?.id || 3,
    apellido_contacto: servicio.apellido_contacto || "",
    nombre_contacto: servicio.nombre_contacto || "",
    telefono_contacto: servicio.telefono_contacto || "",
    accidente_lugar: servicio.accidente_lugar || "",
    accidente_descripcion: servicio.accidente_descripcion || "",
    notas: servicio.notas || "",
    clinicas: [
      {
        direccion: "",

        id: "",
        nombre: "",
        provincia: "",
        localidad: "",
        provincias: [],
        ciudades: [],
        clinicas: [],
        estudios: [],
      },
    ],

    suma_asegurada: servicio?.beneficiario?.suma_asegurada || "",
    saldo_suma_asegurada: "",
    tipo_cobertura: servicio?.tipo_cobertura?.long_name || "",
    beneficiario: servicio?.beneficiario?.id || "",
    user: auth?.me?.id || "",
    url_autorizacion: servicio?.url_autorizacion || null,
  };
}

function validationSchema() {
  return {
    asegurado_dni: Yup.string().required(true),
    asegurado_telefono: Yup.string().required(true),
    accidente_lugar: Yup.string().required(true),
    accidente_hora: Yup.string().required(true),
    accidente_fecha: Yup.string().required(true),
    accidente_descripcion: Yup.string().required(true),

    clinicas: Yup.array()
      .of(
        Yup.object().shape({
          direccion: Yup.string().required(true),
          id: Yup.number().required(true),
          nombre: Yup.string().required(true),
          provincia: Yup.string().required(true),
          localidad: Yup.string().required(true),
          estudios: Yup.array().of(
            Yup.object().shape({
              tipo_estudio: Yup.string().required(true),
            })
          ),
        })
      )
      .required("El array de clínicas es obligatorio"),
  };
}
