import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function getTipoEstudio(request_get,query_string =null){
    
    try {
        
        const url =  query_string === null ? `${BASE_API}/api-orion/tipo_estudio/${request_get}` : `${BASE_API}/api-orion/tipo_estudio/?${query_string}` 
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}


export async function getTiposEstudio(query_string = null){
    try {
        
        const url =  query_string === null ? `${BASE_API}/api-orion/tipo_estudio/` :  `${BASE_API}/api-orion/tipo_estudio/?${query_string}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}

export async function deleteTipoEstudio(id){
    try {
        
        
        const url =  `${BASE_API}/api-orion/tipo_estudio/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 204){
            throw new Error (response.message)
        } 
        const result= {status:"sucess",data:"Se eliminó exitosamente"}
        return result
    } catch (error) {
        throw error
    }
}

export async function addTipoEstudioOrion(request){
    try {
        const url =  `${BASE_API}/api-orion/tipo_estudio/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 201){
            result["status"]= "sucess"
        }else
            result["status"]= "error"

        return result  
    } catch (error) {
        throw error
    }
}

export async function editTipoEstudioOrion(id,request){
    try {
        const url =  `${BASE_API}/api-orion/tipo_estudio/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"
            
        return result  
    } catch (error) {
        throw error
    }
}