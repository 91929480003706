export default function Reducer_beneficiario(state,action) {
   
    switch (action.type) {
        case "SET_TIPOS_COBERTURA":
			state.tipos_cobertura = action.payload
			return {...state}
		
		case "SET_ASEGURADORAS":
			state.aseguradoras = action.payload
			return {...state}
        default:
            throw new Error()

    }
}

