import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'


export async function getServiciosOrion(request_get = null){
    try {
        
        const url = request_get !== null ?  `${BASE_API}/api-orion/servicio/?${request_get}` : `${BASE_API}/api-orion/servicio/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}
export async function getReport(request_get = null){
    try {
        
        const url = request_get !== null ?  `${BASE_API}/api-orion/reporte_orion/?${request_get}` : `${BASE_API}/api-orion/reporte_orion/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        return false
    }
}
export async function getServicioOrion(request_get = null){
    try {
        
        const url = `${BASE_API}/api-orion/servicio/${request_get}/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}

export async function addServicioOrion(request_post){
    try {
       
        const url =  `${BASE_API}/api-orion/servicio/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request_post)
        }
        
        const response = await fetch(url,params)
       

        const result= await response.json()
        
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"

        return result  
    } catch (error) {
        console.log(error)
        return error
        
        
    }
}
export async function editServicioOrion(id,request_put){
    try {
        
       
        const url =  `${BASE_API}/api-orion/servicio/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request_put)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"
            
        return result  
    } catch (error) {
        throw error
    }
}

export async function deleteServicio(id){
    try {
        
        
        const url =  `${BASE_API}/api-orion/servicio/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 204){
            throw new Error (response.message)
        } 
        const result= {status:"sucess",data:"Se eliminó exitosamente"}
        return result
    } catch (error) {
        throw error
    }
}