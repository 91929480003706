import React, { useEffect, useState } from 'react'

import { Segment,Form,Input,Icon, Label, Menu, Table, Grid, Loader,Button } from 'semantic-ui-react'
import {  getClinicas,deleteClinica } from '../../../../api/Orion/clinica';
import { ModalBasic,PaginationTable } from '../../../../components/Common';
import {useHistory,Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ListadoClinicas} from '../../../../components/Admin/Orion';
import { HeaderPage } from '../../../../components/Admin';
import {toast} from 'react-toastify'
import "./AbmAdmin.scss"
import { getPermissions } from '../../../../components/Common';
import { useAuth } from '../../../../hooks';
import { animateScroll as scroll } from 'react-scroll'

export  function ClinicaOrionList() {
	const {auth} = useAuth()
    const [clinicas,setClinicas] = useState([])
    const [loading,setLoading] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
    const [contentModal,setContentModal] = useState(null)
    const [refetch, setRefetch] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [countPage, setCountPage] = useState(1);
	const [searchActive,setSearchActive] = useState("")

	const limitPagination = 10
    const history = useHistory()
	const fetchClinicas = async (request = null) =>{
		
		const clinicas = await getClinicas(request)
		setClinicas(clinicas.results)
		setCountPage(clinicas.count)
		setLoading(false)
		
	} 
    useEffect(()=>{
		scroll.scrollToTop();
        fetchClinicas(`page=1&paginate=true`); 
    },[refetch])
	
    const onRefetch = () => setRefetch((prev) => !prev);
	const openCloseModal = ()=>{
		
		setShowModal((prev)=>!prev)
	}
   	const onDeleteClinica =  async(clinica)=>{
		setContentModal(<p>Desea eliminar clínica {clinica.clinica_nombre} ?</p>)
		setTitleModal("Eliminar clínica")
		setShowModal(true)
		setDataModal(clinica.id)
    

  	}
  	const openConfirmModal = async(id)=>{
		try {
			let response = await deleteClinica(id)
		
			toast.success(response.data)
			setShowModal(false)
			onRefetch()
		} catch (error) {
			console.log(error)
		} 
  	}
  	const crearClinica = (id) => {
    	history.push(`/admin/clinica_orion/crear`);
  	}

  	const onEditClinica = (clinica) => {
		history.push(`/admin/clinica_orion/edit/id/${clinica.id}`);
  	}
	const onShowClinica = (clinica) => {
		history.push(`/admin/clinica_orion/show/id/${clinica.id}`);
  	}
	


  	const onSearch =  (e) => {
		setSearchActive(e.target.value)
    	fetchClinicas(`page=1&paginate=true&search=${e.target.value}`); 
		
  	}
  	const onChange = (e, pageInfo) => {
		let request = `page=${pageInfo.activePage.toString()}&paginate=true`

		/*
		Logica de search
		*/
		if(searchActive !== "")
			request += `&search=${searchActive}` 
		
    	fetchClinicas(request); 

		setActivePage(pageInfo.activePage);
		
	};
  return (
    <div>
		
		<HeaderPage title="Clínicas" btnTitle="Agregar clínica" btnClick={crearClinica} permitionName="add_clinica"/>
		
		
		   <Grid as={Segment} className='segment_search'>
				<Grid.Row >
					<Grid.Column computer={3} mobile={16} tablet={16}>  
						<Input 
							fluid
							
						icon={<Icon name='search' className='color_search' inverted circular />} 
						className="input_search"  
						inverted 
						placeholder='Buscar...' 
						onChange={onSearch}
					/>
					</Grid.Column>
					<Grid.Column computer={11} mobile={16} tablet={16}>


					</Grid.Column>
					<br></br>
					<Grid.Column computer={2} mobile={16} tablet={16}>
					{getPermissions("add_clinica",auth) && (
						<Button circular basic positive onClick={crearClinica}>
							Agregar clínica
						</Button>
            )}
					</Grid.Column>

			
			
			</Grid.Row>
			</Grid>
		   
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16}>
        
          </Grid.Column>
        </Grid.Row>
      </Grid>

	  {loading ? (
		<Loader active inline="centered">
			Cargando...
		</Loader>
	  ):(
		<ListadoClinicas 
		clinicas={clinicas} 
		onDeleteClinica={onDeleteClinica}
		onEditClinica={onEditClinica}
		onShowClinica={onShowClinica}
		countPage={countPage}
		pagination={
			<PaginationTable 
				clinicas={clinicas} 
				onChange={onChange}
				activePage={activePage}
				countPage={countPage}
				
				limitPagination={limitPagination}
			/>
		}
    />
  		)}

      <ModalBasic 
      show={showModal} 
      size="small" 
      title={titleModal} 
      children={contentModal}
      onClose={openCloseModal}
      onConfirm={openConfirmModal}
	  dataModal={dataModal}
      />
    </div>
  )
}
