import React, { useState } from 'react'
import { Message,MessageHeader} from 'semantic-ui-react'
export  function MessageBeforeList({text,closeMessage,onCloseMessage,warning=false,info=false,header=null}) {
 
  return (
    
    closeMessage && 
        <Message
       
        floating
        onDismiss={onCloseMessage}
       warning={warning}
       info={info}
   >
     {header !== null &&<MessageHeader>{header}</MessageHeader>}
   
   <span>{text.value}</span><span> {text.url &&
   <a href={text?.url} target="_blank" rel="noopener noreferrer">{text.name_url}</a>}</span>
  
  
   </Message>
  )
}
