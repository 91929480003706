import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'



export async function getFacturacionLizen(request_get = null){
    try {
        
        const url = `${BASE_API}/api/lizen_facturacion/${request_get}/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        const result= await response.json()
          
        return result
        
    } catch (error) {
        throw new Error ("Error")
    }
}

export async function getFacturaCliente(idAseguradora, idAsegurado){
    try {

        const url = `${BASE_API}/api/lizen_cliente_factura?cliente=${idAseguradora}&asegurado=${idAsegurado}`

        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)

        const result= await response.json()

        return result.results[0]

    } catch (error) {
        throw new Error ("Error")
    }
}

export async function getLegajoLizen(request_get = null){
    try {
        
        const url = `${BASE_API}/api/lizen_legajo/?${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        const result= await response.json()
          
        return result
        
    } catch (error) {
        throw new Error ("Error")
    }
}

export async function addFacturacionLizen(request_post,servicio){
    try {
        /* 
        FACTURA
        */
        const  response_createFacturacion = await createLizenFacturacion(request_post,request_post.billing_file_facturacion)
        /* 
        LEGAJO
        */
        let response_createLegajo = await  createLizenLegajo(response_createFacturacion,request_post.billing_file_legajo)

        /*
        * CLIENTE FACTURA
        */
        // let response_createFactCliente = await createLizenFactCliente(request_post,request_post.billing_file_fact_cliente)
        /* 
        UPDATE SERVICIO 
        */
        const  response_updateServicio = await updateLizenServicio(request_post,servicio,response_createFacturacion)
        return {status:"sucess"}  
    } catch (error) {
        return error
    }
}

export async function  createLizenFactCliente(aseguradora, asegurado,path_file) {
    let request = {
        "cliente":aseguradora,
        "asegurado": asegurado,
        "url_archivo": path_file === null ? null : path_file,
    }
    const url =  `${BASE_API}/api/lizen_cliente_factura/`
    const params = {
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(request)
    }
    const response = await fetch(url,params)

    const result = await response.json()
    return result
}


export async function createLizenFacturacion(request_post,path_file){
    let request = {
        "fecha_emision":request_post.billing_fecha_emision,
        "file_factura": path_file === null ? null : path_file
    }
    const url =  `${BASE_API}/api/lizen_facturacion/`
    const params = {
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(request)
    }
    const response = await fetch(url,params)

    const result= await response.json()

    return result
}

export async function createLizenLegajo(factura,path_file_legajos){
    if(path_file_legajos.length === 0)
        return null

    const url =  `${BASE_API}/api/lizen_legajo/`
  
    path_file_legajos.map(async (legajo)=>{
        let request = {
            "file_legajo":legajo,
            "factura": factura.id
        }
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
        const result= await response.json()
        
      
    })
    
    return {"status" : "sucess"};
  
}

export async function fetchUploadFile (request){
    

    const url =  `${BASE_API}/api/pdf_upload/`
    const params = {
        method:"POST",
        headers:{
          
            Authorization:`Bearer ${getToken()}`
        },
        body: request
    }
    const pdf_upload = await fetch(url,params)

    const response_pdf_upload =  await pdf_upload.json()
    return response_pdf_upload
}

export async function editFacturacionLizen(id,request_put,servicio,factura){
    try {
      
        
        const  response_createFacturacion = await updateLizenFacturacion(id,request_put,request_put.billing_file_facturacion)

        const  response_updateServicio = await updateLizenServicio(request_put,servicio,response_createFacturacion)
        return {status:"sucess"} 
    } catch (error) {
        throw error
    }
}
export async function updateLizenFacturacion(id,request_put,path_file){
    let request = {
        "fecha_emision":request_put.billing_fecha_emision,
        "file_factura": path_file
    }
    const url =  `${BASE_API}/api/lizen_facturacion/${id}/`
    const params = {
        method:"PUT",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(request)
    }
    const response = await fetch(url,params)

    const result= await response.json()

    return result
}

export async function deleteFacturacion(id){
    try {
        
        
        const url =  `${BASE_API}/api/lizen_servicio/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result
    } catch (error) {
        throw error
    }
}




export async function updateLizenServicio(request_post,servicio,response_createFacturacion){
    servicio.cliente = servicio.cliente.id
    servicio.lizen_contacto_servicio = servicio.lizen_contacto_servicio.id
    
    servicio.estado = request_post.servicio_estado
   
    servicio.localidad = servicio.localidad?.id
    servicio.provincia = servicio.provincia?.id
    servicio.lizen_facturacion = response_createFacturacion?.id
    
    if(servicio.proveedor_servicio.length > 0 ){
        let proveedor_servicio_aux = []
        servicio.proveedor_servicio.map(prov=>{
            prov.proveedor = prov.proveedor.id
            proveedor_servicio_aux.push(prov)
        })
        servicio.proveedor_servicio = proveedor_servicio_aux
    }

    
    const url =  `${BASE_API}/api/lizen_servicio/${servicio.id}/`
    const params = {
        method:"PUT",
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${getToken()}`
        },
        body: JSON.stringify(servicio)
    }
    const response = await fetch(url,params)

    const result= await response.json()

    return result
}

export async function deleteLegajo(id){
    try {
        
        
        const url =  `${BASE_API}/api/lizen_legajo/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 204){
            return {status: "sucess"}
        }
      
        return {status: "error"}
    } catch (error) {
        throw error
    }
}

export async function deleteFactCliente(id){
    try {
        const url =  `${BASE_API}/api/lizen_cliente_factura/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 204){
            return {status: "sucess"}
        }

        return {status: "error"}
    } catch (error) {
        throw error
    }
}