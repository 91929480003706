import React from 'react'
import { Pagination,Icon } from 'semantic-ui-react'

import "./Pagination.scss"

export  function PaginationTable(props) {
    const {onChange,activePage,countPage,servicios,limitPagination} = props
  
  return (
    <Pagination
    boundaryRange={0}
    defaultActivePage={1}
    ellipsisItem={null}
    firstItem={countPage === 0 ? null : "Primera página"}
    lastItem={countPage === 0 ? null : "Ultima página"}
    siblingRange={1}
    totalPages={Math.ceil(countPage / limitPagination)}
    onPageChange={onChange}
    
  />
  )
}
