import React, { useEffect, useState } from "react";

import {
  Segment,
  Form,
  Input,
  Icon,
  Label,
  Menu,
  Table,
  Grid,
  Loader,
  Button,
  Modal, Popup,
} from "semantic-ui-react";
import {
  getBeneficiarios,
  deleteBeneficiario,
  addExcelBeneficiario,
} from "../../../../api/Orion/beneficiario";
import { fetchUploadFile } from "../../../../api/Common/file";

import {
  FiltersOrionBeneficiaro,
  getPermissionsSecondary,
  ModalBasic,
  PaginationTable
} from "../../../../components/Common";
import {
  useHistory,
  Redirect,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { ListadoBeneficiarios } from "../../../../components/Admin/Orion";
import { HeaderPage } from "../../../../components/Admin";
import { toast } from "react-toastify";
import "./AbmAdmin.scss";
import { getPermissions } from "../../../../components/Common";
import { useAuth } from "../../../../hooks";
import { animateScroll as scroll } from "react-scroll";
import Moment from "moment/moment";
function ModalBasicExcel(props) {
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [title, setTitle] = useState("Adjuntar Excel");
  const {
    show,
    size,
    children,
    onClose,
    onConfirm,
    dataModal,
    buttonSi,
    buttonNo,
    loading,
  } = props;
  const onConfirmExcel = async (data) => {
    setLoadingExcel(true);
    if (props.file === "") {
      toast.error("Adjunte un excel para poder guardar");
    } else {
      let response = await addExcelBeneficiario(props.file);

      const objetoJSON = JSON.stringify(response);
      const objetoParseado = JSON.parse(objetoJSON);

      if (objetoParseado.error !== undefined) {
        setLoadingExcel(false);
        //toast.dismiss()
        toast.error(
          `Error en la fila: ${
            objetoParseado.fila
          }. Descripción : ${JSON.stringify(objetoParseado.error)}`
        );
        onConfirm();
      } else {
        setLoadingExcel(false);
        //toast.dismiss()
        toast.success(
          `Se agregaron : ${objetoParseado.data.nuevos} beneficiarios. Se dieron de baja : ${objetoParseado.data.baja} beneficiarios.`
        );
        onConfirm();
      }
    }
  };
  return (
    <Modal className="modal-basic" open={show} size={size}>
      {loadingExcel ? (
        <Modal.Header>Cargando beneficiarios ...</Modal.Header>
      ) : (
        <>
          {title && <Modal.Header>{title}</Modal.Header>}
          <Modal.Content>{children}</Modal.Content>
          <Modal.Actions>
            <Button color="red" inverted onClick={onClose}>
              <Icon name="remove" /> {buttonNo !== undefined ? buttonNo : "No"}
            </Button>
            <Button
              color={loading ? "grey" : "green"}
              disabled={loading}
			  inverted={loading?false:true}
              onClick={() => onConfirmExcel(dataModal)}
            >
              {loading ? (
                <>
                  <Loader
                    active
                    size="mini"
                    inline={true}
                    style={{ marginRight: "12px" }}
                  />{" "}
                  Cargando...
                </>
              ) : (
                <>
                  <Icon name="checkmark" />
                  {buttonSi !== undefined ? buttonSi : "Si"}
                </>
              )}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}



export function BeneficiarioOrionList() {
  const { auth } = useAuth();
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalImportExcel, setShowModalImportExcel] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [buttonNo, setButtonNo] = useState("");
  const [buttonSi, setButtonSi] = useState("");
  const [dataModal, setDataModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [file, setFile] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [filtersActive,setFiltersActive] = useState("")

  const limitPagination = 10;
  const history = useHistory();
  const fetchBeneficiarios = async (request = null) => {
    const beneficiarios = await getBeneficiarios(request);

    setBeneficiarios(beneficiarios.results);
    setCountPage(beneficiarios.count);
    setLoading(false);
  };
  useEffect(() => {
    scroll.scrollToTop();

    let filtrosExistentes = sessionStorage.getItem('orion_beneficiarios_filters')
    let searchExistentes = sessionStorage.getItem('orion_beneficiarios_search')
    if(searchExistentes){
      setFiltersActive(filtrosExistentes)
      fetchBeneficiarios(filtrosExistentes !== "" ? `search=${searchExistentes}&${filtrosExistentes}`: `search=${searchExistentes}`);
    } else {
      fetchBeneficiarios(filtrosExistentes !== "" ? `page=1&${filtrosExistentes}&${filtrosExistentes}`: `page=1&${filtrosExistentes}`);
    }
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModalDelete = () => {
    setShowModalDelete((prev) => !prev);
  };
  const openCloseModalImportExcel = () => {
    setShowModalImportExcel((prev) => !prev);
  };
  const onDeleteBeneficiario = async (beneficiario) => {
    setContentModal(
      <p>
        Desea eliminar el beneficiario {beneficiario.asegurado_nombre}{" "}
        {beneficiario.asegurado_apellido} ?
      </p>
    );
    setTitleModal("Eliminar beneficiario");
    setShowModalDelete(true);
    setDataModal(beneficiario.id);
    setButtonSi("Si");
    setButtonNo("No");
  };
  const openConfirmModalDelete = async (id) => {
    try {
      let response = await deleteBeneficiario(id);

      toast.success(response.data);
      setShowModalDelete(false);
      onRefetch();
    } catch (error) {
      console.log(error);
    }
  };
  const openConfirmModalImportExcel = () => {
    try {
      setShowModalImportExcel(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFile = async (event) => {
    //!
    setLoading(true);
    var file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      const response_pdf_upload_factura = await fetchUploadFile(formData);
      if (response_pdf_upload_factura.data !== undefined) {
        setFile(response_pdf_upload_factura.data[0]);
        setLoading(false);
      } else {
        toast.error(`Error: ${JSON.stringify(response_pdf_upload_factura)}`);
      }
    }
  };
  const importBeneficiarios = async () => {
    setContentModal(
      <input
        className="input"
        accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
        type="file"
        onChange={handleUploadFile}
      />
    );
    setTitleModal("Adjuntar documento");
    setShowModalImportExcel(true);
    setDataModal("");
    setButtonSi("Guardar");
    setButtonNo("Cancelar");
  };
  const crearBeneficiario = (id) => {
    history.push(`/admin/beneficiario_orion/crear`);
  };

  const onEditBeneficiario = (beneficiario) => {
    history.push(`/admin/beneficiario_orion/edit/id/${beneficiario.id}`);
  };
  const onShowBeneficiario = (beneficiario) => {
    history.push(`/admin/beneficiario_orion/show/id/${beneficiario.id}`);
  };

  const onSearch = (e) => {
    fetchBeneficiarios(`search=${e.target.value}`);
  };
  const onChange = (e, pageInfo) => {

    let filtrosExistentes = sessionStorage.getItem('orion_beneficiarios_filters')
    fetchBeneficiarios(`page=${pageInfo.activePage.toString()}&${filtrosExistentes}`);

    setActivePage(pageInfo.activePage);
  };

  const openConfirmModalFilters = (formData)=>{
    let respuesta_final = []
    let convenio_filter =""
    if(formData.aseguradora !== undefined && formData.aseguradora.length > 0){
      convenio_filter = `aseguradora=${formData.aseguradora.join(",")}`
      respuesta_final.push(convenio_filter)
    }
    fetchBeneficiarios(respuesta_final.join('&'))
    sessionStorage.setItem('orion_beneficiarios_filters',respuesta_final.join('&'))
    setFiltersActive(respuesta_final.join('&'))
    setShowModalFilters((prev)=>!prev)
    setLoading(true)

  }

  const openClearFilters = () =>{
    fetchBeneficiarios()
    setFiltersActive("")
    sessionStorage.removeItem('orion_beneficiarios_filters')
    setShowModalFilters((prev)=>!prev)
    setLoading(true)
    onRefetch()
  }

  const BotonFiltros = () =>{
    return(getPermissionsSecondary("segment_filters",auth,'filters','lizen') ? (
        <Popup
            content='Ver filtros'
            trigger={
              <Button
                  onClick={() => setShowModalFilters(true)}
                  icon={
                    <Icon
                        name='filter'
                        className='color_search'
                        inverted
                        circular />
                  }
                  inverted
            /> }/>
    ):(<Grid.Column computer={2} mobile={16} tablet={8}  ></Grid.Column>))

  }

  return (
    <div>
      <HeaderPage
        title="Beneficiarios"
        btnTitle="Agregar beneficiario"
        btnClick={crearBeneficiario}
        permitionName="add_beneficiario"
      />
      <Grid as={Segment} className="segment_search">
        <Grid.Row>
          <Grid.Column computer={3} mobile={16} tablet={16}>
            <Input
              fluid
              icon={
                <Icon
                  name="search"
                  className="color_search"
                  inverted
                  circular
                />
              }
              className="input_search"
              inverted
              placeholder="Buscar..."
              onChange={onSearch}
            />
          </Grid.Column>
          <Grid.Column computer={2} mobile={8} tablet={8} style={{bottom: '5px'}}>
            <BotonFiltros></BotonFiltros>
          </Grid.Column>
          <Grid.Column computer={7} mobile={16} tablet={16}></Grid.Column>
          <br></br>
          <Grid.Column computer={2} mobile={16} tablet={16}>
            {getPermissions("add_beneficiario", auth) && (
              <Button circular basic positive onClick={crearBeneficiario}>
                Agregar beneficiario
              </Button>
            )}
          </Grid.Column>
          <Grid.Column computer={2} mobile={16} tablet={16}>
            {getPermissions("view_beneficiario", auth) && (
              <Button circular basic positive onClick={importBeneficiarios}>
                Importar beneficiarios
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column computer={16}></Grid.Column>
        </Grid.Row>
      </Grid>

      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <ListadoBeneficiarios
          beneficiarios={beneficiarios}
          onDeleteBeneficiario={onDeleteBeneficiario}
          onEditBeneficiario={onEditBeneficiario}
          onShowBeneficiario={onShowBeneficiario}
          countPage={countPage}
          pagination={
            <PaginationTable
              beneficiarios={beneficiarios}
              onChange={onChange}
              activePage={activePage}
              countPage={countPage}
              limitPagination={limitPagination}
            />
          }
        />
      )}

      <ModalBasic
        show={showModalDelete}
        size="small"
        title={titleModal}
        children={contentModal}
        onClose={openCloseModalDelete}
        onConfirm={openConfirmModalDelete}
        dataModal={dataModal}
        buttonNo={buttonNo}
        buttonSi={buttonSi}
        loading={loading}
      />
      <ModalBasicExcel
        show={showModalImportExcel}
        size="small"
        title={titleModal}
        children={contentModal}
        onClose={openCloseModalImportExcel}
        onConfirm={openConfirmModalImportExcel}
        dataModal={dataModal}
        buttonNo={buttonNo}
        buttonSi={buttonSi}
        file={file}
        loading={loading}
      />
      <FiltersOrionBeneficiaro
        show={showModalFilters}
        onClose={() => setShowModalFilters(false)}
        onConfirm={openConfirmModalFilters}
        onClear={openClearFilters}
        auth={auth}
        type={"orion"}
      />
    </div>
  );
}
