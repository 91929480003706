import React, { useEffect, useState } from 'react'

import { Segment,Form,Input,Icon, Label, Menu, Table, Grid, Loader,Button } from 'semantic-ui-react'
import {  getTiposCobertura,deleteTipoCobertura } from '../../../../api/Orion/tipoCobertura';
import { ModalBasic,PaginationTable } from '../../../../components/Common';
import {useHistory,Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ListadoTiposCobertura}from '../../../../components/Admin/Orion';
import { HeaderPage } from '../../../../components/Admin';
import {toast} from 'react-toastify'
import "./AbmAdmin.scss"
import { getPermissions } from '../../../../components/Common';
import { useAuth } from '../../../../hooks';
import { animateScroll as scroll } from 'react-scroll'

export  function TipoCoberturaOrionList() {
	const {auth} = useAuth()
    const [tiposCobertura,setTiposCobertura] = useState([])
    const [loading,setLoading] = useState(true)
    const [showModal,setShowModal] = useState(false)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
    const [contentModal,setContentModal] = useState(null)
    const [refetch, setRefetch] = useState(false)
	const [activePage, setActivePage] = useState(1);
	const [countPage, setCountPage] = useState(1);
	
	const limitPagination = 10
    const history = useHistory()
	const fetchTiposCobertura = async (request = null) =>{
		
		const tiposCobertura = await getTiposCobertura(request)

		setTiposCobertura(tiposCobertura.results)
		setCountPage(tiposCobertura.count)
		setLoading(false)
		
	} 
    useEffect(()=>{
		scroll.scrollToTop();
        fetchTiposCobertura(`page=1&is_active=1`); 
    },[refetch])
	
    const onRefetch = () => setRefetch((prev) => !prev);
	const openCloseModal = ()=>{
		
		setShowModal((prev)=>!prev)
	}
   	const onDeleteTipoCobertura =  async(tipoCobertura)=>{
		setContentModal(<p>Desea eliminar el tipo de cobertura {tipoCobertura.long_name} ?</p>)
		setTitleModal("Eliminar tipo cobertura")
		setShowModal(true)
		setDataModal(tipoCobertura.id)
    

  	}
  	const openConfirmModal = async(id)=>{
		try {
			let response = await deleteTipoCobertura(id)
		
			toast.success(response.data)
			setShowModal(false)
			onRefetch()
		} catch (error) {
			console.log(error)
		} 
  	}
  	const crearTipoCobertura = (id) => {
    	history.push(`/admin/tipo_cobertura_orion/crear`);
  	}

  	const onEditTipoCobertura = (tipoCobertura) => {
		history.push(`/admin/tipo_cobertura_orion/edit/id/${tipoCobertura.id}`);
  	}
	const onShowTipoCobertura = (tipoCobertura) => {
		history.push(`/admin/tipo_cobertura_orion/show/id/${tipoCobertura.id}`);
  	}
	


  	const onSearch =  (e) => {
    	 fetchTiposCobertura(`search=${e.target.value}&is_active=1`); 
		
  	}
  	const onChange = (e, pageInfo) => {
		
    	fetchTiposCobertura(`page=${pageInfo.activePage.toString()}&is_active=1`); 

		setActivePage(pageInfo.activePage);
		
	};
  return (
    <div>
		
		<HeaderPage title="Tipos de Coberturas" btnTitle="Agregar tipo de cobertura" btnClick={crearTipoCobertura} permitionName="add_tipocobertura"/>
		
		
		   <Grid as={Segment} className='segment_search'>
				<Grid.Row >
					<Grid.Column computer={3} mobile={16} tablet={16}>  
						<Input 
							fluid
							
						icon={<Icon name='search' className='color_search' inverted circular />} 
						className="input_search"  
						inverted 
						placeholder='Buscar...' 
						onChange={onSearch}
					/>
					</Grid.Column>
					<Grid.Column computer={11} mobile={16} tablet={16}>


					</Grid.Column>
					<br></br>
					<Grid.Column computer={2} mobile={16} tablet={16}>
					{getPermissions("add_tipocobertura",auth) && (
						<Button circular basic positive onClick={crearTipoCobertura}>
							Agregar tipo cobertura
						</Button>
            )}
					</Grid.Column>

			
			
			</Grid.Row>
			</Grid>
		   
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16}>
        
          </Grid.Column>
        </Grid.Row>
      </Grid>

	  {loading ? (
		<Loader active inline="centered">
			Cargando...
		</Loader>
	  ):(
		<ListadoTiposCobertura 
      tiposCobertura={tiposCobertura} 
      onDeleteTipoCobertura={onDeleteTipoCobertura}
      onEditTipoCobertura={onEditTipoCobertura}
      onShowTipoCobertura={onShowTipoCobertura}
	  countPage={countPage}
	  pagination={<PaginationTable 
		tiposCobertura={tiposCobertura} 
		onChange={onChange}
		activePage={activePage}
		countPage={countPage}
		
		limitPagination={limitPagination}
		/>}
    />
  		)}

      <ModalBasic 
      show={showModal} 
      size="small" 
      title={titleModal} 
      children={contentModal}
      onClose={openCloseModal}
      onConfirm={openConfirmModal}
	  dataModal={dataModal}
      />
    </div>
  )
}