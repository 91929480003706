import React from 'react'
import Moment from 'moment';
import { Popup,Segment,Form,Input,Pagination,Icon, Label, Menu, Table, Grid, Loader, Button } from 'semantic-ui-react'
import './Beneficiario.scss'
import { useAuth } from '../../../hooks';
import { getPermissions } from '../../Common/Permissions';
export  function ListadoBeneficiarios(props) {
  const {beneficiarios, onDeleteBeneficiario,onEditBeneficiario,pagination,onShowBeneficiario,countPage} = props
  
  const {auth} = useAuth()
  return (
    <Table size='small' celled  className='tabla-servicios'>
    <Table.Header className='color_header_table'>
   
      <Table.Row >
        <Table.HeaderCell>Id póliza </Table.HeaderCell>
        <Table.HeaderCell>DNI</Table.HeaderCell>
        <Table.HeaderCell>Apellido</Table.HeaderCell>
        <Table.HeaderCell>Nombre</Table.HeaderCell>
        <Table.HeaderCell>Provincia</Table.HeaderCell>
        <Table.HeaderCell>Localidad</Table.HeaderCell>
        <Table.HeaderCell>Aseguradora</Table.HeaderCell>
        <Table.HeaderCell>Acciones</Table.HeaderCell>

      </Table.Row>
    </Table.Header>

    <Table.Body style={{'borderLeft': '1px solid white'}}>
      {beneficiarios.map((beneficiario,i)=>{
        
        return(<Table.Row key={i}>
         
       
         <Table.Cell > {beneficiario.id_poliza}</Table.Cell>
         <Table.Cell > {beneficiario.asegurado_dni}</Table.Cell>
         <Table.Cell > {beneficiario.asegurado_apellido}</Table.Cell>
         <Table.Cell > {beneficiario.asegurado_nombre}</Table.Cell>
         <Table.Cell > {beneficiario.asegurado_provincia}</Table.Cell>
         <Table.Cell > {beneficiario.asegurado_localidad}</Table.Cell>
         <Table.Cell > {beneficiario.aseguradora?.long_name}</Table.Cell>
       
         <Acciones auth={auth} beneficiario={beneficiario} onShowBeneficiario={onShowBeneficiario} onDeleteBeneficiario={onDeleteBeneficiario} onEditBeneficiario={onEditBeneficiario} />
       </Table.Row>
     
      )})
      } 
      
      </Table.Body> 
      <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  colSpan='10'>
        {pagination}
        Total de registros: {countPage}
        </Table.HeaderCell>
          
        </Table.Row> 
      </Table.Footer>
  </Table>
  )
}


function Acciones(props){
  const {beneficiario,onDeleteBeneficiario,onEditBeneficiario, onShowBeneficiario,auth} = props

 
     


  return(
    <Table.Cell  className='acciones'>
      {getPermissions("view_beneficiario",auth) &&
    <Popup content='Ver beneficiario' trigger={<Button size='mini' icon color="blue" basic onClick={()=>onShowBeneficiario(beneficiario)}>
    <Icon name='eye' />
  </Button>} />}
        
     
      {getPermissions("change_beneficiario",auth) &&
      <Popup content='Editar beneficiario' trigger={<Button size='mini' icon basic onClick={()=>onEditBeneficiario(beneficiario)}>
      <Icon name='pencil' />
    </Button>} />}
      {getPermissions("delete_beneficiario",auth) &&
      <Popup content='Eliminar beneficiario' trigger={<Button size='mini' icon negative basic onClick={()=>onDeleteBeneficiario(beneficiario)}>
      <Icon name='close' />
    </Button>} />}
    </Table.Cell>
  )
}