import {Grid, Input} from "semantic-ui-react";

const DatoBeneficiario = ({label, value}) => {
    return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
            <h5>{label}: </h5>
            <Input
                size={"small"}
                disabled={true}
                type={"text"}
                value={value}
            />
        </Grid.Column>
    )
}

export  default DatoBeneficiario