import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function getProvincias(){
    try {
        
        const url =  `${BASE_API}/api/provincia/`
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
            const result= await response.json()
            return result
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}