import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
export async function getClinica(request_get,query_string =null){
    try {
        
        const url =  query_string === null ? `${BASE_API}/api-orion/clinica/${request_get}` : `${BASE_API}/api-orion/clinica?${query_string}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
           
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}


export async function getClinicas(query_string = null){
    try {
        const url =  query_string === null ? `${BASE_API}/api-orion/clinica/` : `${BASE_API}/api-orion/clinica/?${query_string}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
        
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Hubo un problema, vuelva a intentar")
    }
}

export async function deleteClinica(id){
    try {
        
        
        const url =  `${BASE_API}/api-orion/clinica/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 204){
            throw new Error (response.message)
        } 
        const result= {status:"sucess",data:"Se eliminó exitosamente"}
        return result
    } catch (error) {
        throw error
    }
}

export async function addClinicaOrion(request){
    try {
        const url =  `${BASE_API}/api-orion/clinica/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"

        return result  
    } catch (error) {
        throw error
    }
}

export async function editClinicaOrion(id,request){
    try {
        const url =  `${BASE_API}/api-orion/clinica/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
        if(response.status === 200){
            result["status"]= "sucess"
        }else
            result["status"]= "error"
            
        return result  
    } catch (error) {
        throw error
    }
}

export async function addDocumentoClinica(clinica, tipoDocumento, archivo) {
    try {
        
        const url = `${BASE_API}/api-orion/clinica_documento/`
        
        const params = {
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify({
                clinica,
                tipo_documento: tipoDocumento,
                url_archivo: archivo
            })
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            result["status"] = "sucess"
        } else
            result["status"] = "error"

        return result

    } catch (error) {
        throw error
    }
}

export async function deleteDocumentoClinica(id) {
    try {
        
        const url = `${BASE_API}/api-orion/clinica_documento/${id}/`
        
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        if (response.status !== 204) {
            throw new Error(response.message)
        }
        const result = {status: "sucess", data: "Se eliminó exitosamente"}
        return result

    } catch (error) {
        throw error
    }
}

export async function getDocumentosClinica(clinica) {
    try {
        
        const url = `${BASE_API}/api-orion/clinica_documento/?clinica=${clinica}`
        
        const params = {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            return result
        }
        return false

    } catch (error) {
        throw new Error("Hubo un problema, vuelva a intentar")
    }
}

export async function getTiposDoc() {
    try {
        
        const url = `${BASE_API}/api-orion/tipo_documento/`
        
        const params = {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            return result
        }
        return false

    } catch (error) {
        throw new Error("Hubo un problema, vuelva a intentar")
    }
}