import {API_MISTA_URL, ID_ASEGURADORA_MISTA, MISTA_API_KEY} from "../utils/constantes";

export default class MistaService {

    static async obtenerBeneficiario(dni){
        const myHeaders = new Headers();
        myHeaders.append("X-Api-Key", MISTA_API_KEY);
        myHeaders.append("X-Tenant", "mista");
        myHeaders.append("X-User", "lizen");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(`${API_MISTA_URL}/insureds?identificationNumber=${dni}&identificationTypeCode=DNI`, requestOptions);

        if(response.status !== 200){
            return null;
        }

        let beneficiario = await response.json();
        beneficiario = {...beneficiario, ...await this.obtenerDetalleBeneficiario(beneficiario.policies[0].insuredCode)};

        console.log('PREbeneficiario');
        console.log(beneficiario);

        beneficiario = {
            dni: beneficiario.identificationNumber,
            apellido: beneficiario.lastName,
            nombre: beneficiario.firstName,
            fecha_nacimiento: beneficiario.birthdate,
            vigencia: beneficiario.policies[0].coverages[0].inclusionDate,
            vinculo: beneficiario.insuredType.name,
            poliza: beneficiario.policies[0].policyCode,
            aseguradora: ID_ASEGURADORA_MISTA,
            domicilio: '-'
        }


        console.log('beneficiario');
        console.log(beneficiario);

        return beneficiario;
    }

    static async obtenerDetalleBeneficiario(codigoBeneficiario) {
        const myHeaders = new Headers();
        myHeaders.append("X-Api-Key", MISTA_API_KEY);
        myHeaders.append("X-Tenant", "mista");
        myHeaders.append("X-User", "lizen")

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(`${API_MISTA_URL}/insureds/${codigoBeneficiario}/individual`, requestOptions);

        if(response.status !== 200){
            return null;
        }

        return await response.json();

    }
}