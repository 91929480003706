import {BASE_API} from '../../utils/constantes'
import { getToken } from './token'
export async function getLocalidades(request_get){
    try {
        
        const url =  `${BASE_API}/api/localidad/?${request_get}`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 200){
           
            const result= await response.json()
            
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}