import React, {useEffect,useState,createContext} from 'react'
import { setToken, getToken, removeToken } from '../api/Common/token';
import { removeNotification } from '../api/Common/report';
import { useUser } from '../hooks';
export const AuthContext = createContext({
    auth: undefined,
    login :()=>null,
    logout:()=>null
});

export function AuthProvider(props){
    const {children} = props
    const [auth,setAuth] = useState(undefined)
    const {getMe} = useUser()
    useEffect(()=>{
            (async()=>{
                const token = getToken()

                if(token){
                    const me = await getMe(token)

                    if(!me)
                        setAuth(null)
                    else
                        setAuth({token,me}) 
                }else{
                    setAuth(null)
                }
               
            })()
    },[])

    const login = async (token)=>{
        setToken(token)
        const me = await getMe(token)
        if(!me)
            setAuth(null)
        else
            setAuth({token,me}) 
    }

    const logout = () =>{

        setAuth(null)
        removeToken()
        removeNotification()
        
    }
    const valueContext = {
        auth,
        login,
        logout
    }

    if (auth === undefined ) return null
    return (
        <AuthContext.Provider value={valueContext}>
            {children}
        </AuthContext.Provider>
    )
}