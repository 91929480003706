import {BASE_API} from '../../utils/constantes'
import { getToken } from '../Common/token'
import { editProveedor } from './proveedor'

export async function getServiciosLizen(request_get = null){
    try {
        const url = request_get !== null ?  `${BASE_API}/api/lizen_servicio/?${request_get}` : `${BASE_API}/api/lizen_servicio/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status === 200){
            const result= await response.json()
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}
export async function getReport(request_get = null){
    try {
        
        const url = request_get !== null ?  `${BASE_API}/api/reporte_lizen/?${request_get}` : `${BASE_API}/api/reporte_lizen/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        return false
    }
}

export async function getServicioLizen(request_get = null){
    try {
        
        const url = `${BASE_API}/api/lizen_servicio/${request_get}/`
            
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}

export async function addServicioLizen(request_post,inputProveedores){
    try {
        //Agregar la entidad contacto del servicio
        const contacto = await addContacto(request_post);

        //Actualizar Datos del proveedores
        
        inputProveedores.map((input,i)=>{
            
            let proveedor_data = request_post.proveedor_proveedor_data[i]?.proveedor_obj || request_post.proveedor_proveedor_data[i]
            
            proveedor_data["telefono"] = request_post[`proveedor_telefono_${i}`]
            proveedor_data["calle"]=request_post[`proveedor_direccion_${i}`]
            proveedor_data["email"]=request_post[`proveedor_email_${i}`]
            
            let proveedor_id = request_post.proveedor_proveedor[i]
            editProveedor(proveedor_data,proveedor_id)
        })
        
        //Logica para impactar los datos en tabla intermedia
        let proveedores = inputProveedores.map((input,i)=>{
            return {
                "proveedor": request_post.proveedor_proveedor[i],
                "traslado": request_post.proveedor_traslado[i],
                "lugar": request_post.proveedor_lugar[i],
                "contacto_proveedor":request_post.proveedor_contacto[i],
                "provincia":request_post.proveedor_provincias[i],
                "localidad":request_post.proveedor_localidades[i],
                "valor_con_iva":request_post.proveedor_conIva[i],                
                "valor_sin_iva":request_post.proveedor_sinIva[i],
                "cantidad_km":request_post.proveedor_cantKM[i],                
                "valor_km":request_post.proveedor_valorKM[i].toLocaleString()
            }
        })

        let request ={
            "lizen_contacto_servicio":contacto.data,
            'cliente':request_post.cliente,
            'cliente_tipo_servicio': request_post.fallecido_tipo_servicio,
            'nota_importancia':request_post.notas,
            'url_autorizacion':request_post.url_autorizacion,
            'velatorio':request_post.fallecido_velatorio,
            'destino':request_post.fallecido_destino,
            'direccion_fallecimiento':request_post.fallecido_direccion_fallecimiento,
            'provincia':request_post.fallecido_provincia,
            'localidad':request_post.fallecido_localidad,
            'direccion_residencia':request_post.fallecido_direccion_residencia,
            "proveedor_servicio":proveedores,
            "estado":request_post.estado,
            "fecha":request_post.fecha,
            "operador":request_post.operador
        } 
        const url =  `${BASE_API}/api/lizen_servicio/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
    
        return result  
    } catch (error) {
        return error
        throw error
        
    }
}
export async function editServicioLizen(id,request_put,inputProveedores){
    try {
        
        const contacto = await editContacto(request_put);
       
        //Actualizar Datos del proveedores
            
        inputProveedores.map((input,i)=>{
            let proveedor_data = request_put.proveedor_proveedor_data[i]?.proveedor_obj || request_put.proveedor_proveedor_data[i]
            proveedor_data["telefono"] = request_put[`proveedor_telefono_${i}`]
            proveedor_data["calle"]=request_put[`proveedor_direccion_${i}`]
            proveedor_data["email"]=request_put[`proveedor_email_${i}`]
            
            let proveedor_id = request_put.proveedor_proveedor[i]
            editProveedor(proveedor_data,proveedor_id)
        })
    
        //Logica para impactar los datos en tabla intermedia
        let proveedores = inputProveedores.map((input,i)=>{
            return {
                "proveedor": request_put.proveedor_proveedor[i],
                "traslado": request_put.proveedor_traslado[i],
                "lugar": request_put.proveedor_lugar[i],
                "contacto_proveedor":request_put.proveedor_contacto[i],
                "provincia":request_put.proveedor_provincias[i],
                "localidad":request_put.proveedor_localidades[i],
                "valor_con_iva":request_put.proveedor_conIva[i],                
                "valor_sin_iva":request_put.proveedor_sinIva[i],
                "cantidad_km":request_put.proveedor_cantKM[i],                
                "valor_km":request_put.proveedor_valorKM[i],
                "id":request_put.proveedor_servicio_proveedor[i],
            }
        })

        let request ={
            "lizen_contacto_servicio":contacto.id,
            'cliente':request_put.cliente,
            'cliente_tipo_servicio': request_put.fallecido_tipo_servicio,
            'nota_importancia':request_put.notas,
            'url_autorizacion':request_put.url_autorizacion,
            'velatorio':request_put.fallecido_velatorio,
            'destino':request_put.fallecido_destino,
            'direccion_fallecimiento':request_put.fallecido_direccion_fallecimiento,
            'direccion_residencia':request_put.fallecido_direccion_residencia,
            'provincia':request_put.fallecido_provincia,
            'localidad':request_put.fallecido_localidad,
            
            "proveedor_servicio":proveedores,
            "estado":request_put.estado,
            "fecha":request_put.fecha,
            "operador":request_put.operador
        } 
        const url =  `${BASE_API}/api/lizen_servicio/${id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
    
        const result= await response.json()
    
        return result  
    } catch (error) {
        throw error
    }
}

export async function addDocumentoServicio(servicio, tipoDocumento, archivo) {
    try {
        
        const url = `${BASE_API}/api/lizen_servicio_documento/`
        
        const params = {
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify({
                servicio,
                tipo_documento: tipoDocumento,
                url_archivo: archivo
            })
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            result["status"] = "sucess"
        } else
            result["status"] = "error"

        return result

    } catch (error) {
        throw error
    }
}

export async function addDocumentoServicioNota(documentoServicioId, nota) {
    try {
        
        const url = `${BASE_API}/api/lizen_servicio_documento/${documentoServicioId}/`
        
        const params = {
            method: "PATCH",
            headers: {
                "Content-Type":"application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify({
                comentario: nota
            })
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            result["status"] = "sucess"
        } else
            result["status"] = "error"

        return result

    } catch (error) {
        throw error
    }
}

export async function getTiposDoc() {
    try {
        
        const url = `${BASE_API}/api/lizen_tipo_documento/`
        
        const params = {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            return result
        }
        return false

    } catch (error) {
        throw new Error("Hubo un problema, vuelva a intentar")
    }
}

export  async function getDocumentosServicio(servicio) {
    try {
        
        const url = `${BASE_API}/api/lizen_servicio_documento/?servicio=${servicio}`
        
        const params = {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        const result = await response.json()
        if (response.status === 200) {
            return result
        }
        return false

    } catch (error) {
        throw new Error("Hubo un problema, vuelva a intentar")
    }
}

export async function deleteDocumentoServicio(id) {
    try {
        
        const url = `${BASE_API}/api/lizen_servicio_documento/${id}/`
        
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        
        if (response.status !== 204) {
            throw new Error(response.message)
        }
        const result = {status: "sucess", data: "Se eliminó exitosamente"}
        return result

    } catch (error) {
        throw error
    }
}

export async function addContacto (request_post){
    try {
        let request = {
           "nombre_apellido": request_post.contacto_nombre_apellido,
           "telefono": request_post.contacto_telefono,
           "email": request_post.contacto_email,
           "vinculo": request_post.contacto_vinculo,
           "detalle_vinculo": request_post.contacto_detalle,
           "art": request_post.contacto_art,
           "cia_seguro":request_post.contacto_cia
        }
        const url =  `${BASE_API}/api/lizen_contacto_servicio/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
        const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result
    } catch (error) {
        throw error
    }
}

export async function editContacto (request_put){
    try {
        let request = {
           "nombre_apellido": request_put.contacto_nombre_apellido,
           "telefono": request_put.contacto_telefono,
           "email": request_put.contacto_email,
           "vinculo": request_put.contacto_vinculo,
           "detalle_vinculo": request_put.contacto_detalle,
           "art": request_put.contacto_art,
           "cia_seguro":request_put.contacto_cia
        }
       
        const url =  `${BASE_API}/api/lizen_contacto_servicio/${request_put.contacto_id}/`
        const params = {
            method:"PUT",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            },
            body: JSON.stringify(request)
        }
         const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result 
    } catch (error) {
        throw error
    }
}

export async function deleteServicio(id){
    try {
        
        
        const url =  `${BASE_API}/api/lizen_servicio/${id}/`
        const params = {
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error (response.message)
        }
        const result= await response.json()
        return result
    } catch (error) {
        throw error
    }
}


export function disableStatusByGroup(userData,disabledGeneral){
    let disabled = true;
    
    if(disabledGeneral)
        return disabledGeneral;

    if(userData?.me){
        
        if(userData?.me?.groups.length > 0){
            for (const group of userData?.me?.groups) {
                switch (group.name) {
                    case 'CEO':
                        disabled=false;
                        break;
                    case 'OPERADOR':
                        disabled = false;
                        break;
                    default:
                        disabled = true;
                        break;
                }
            }
        }

        
    }

    return disabled;
}

export function optionsStatusByGroup(userData){
    let status= [
        { key: 1, text: 'Activo', value: 'Activo' },
        { key: 2, text: 'Legajo', value: 'Legajo' },
        { key: 3, text: 'Facturado', value: 'Facturado' },
        { key: 4, text: 'Abonado', value: 'Abonado' },
        { key: 5, text: 'Particular', value: 'Particular' },
        { key: 6, text: 'Rechazado', value: 'Rechazado' }
    ];
    if(userData?.me){
        
        if(userData?.me?.groups.length > 0){
            for (const group of userData?.me?.groups) {
                switch (group.name) {
                    case 'CEO':
                        status= [
                            { key: 1, text: 'Activo', value: 'Activo' },
                            { key: 2, text: 'Legajo', value: 'Legajo' },
                            { key: 3, text: 'Facturado', value: 'Facturado' },
                            { key: 4, text: 'Abonado', value: 'Abonado' },
                            { key: 5, text: 'Particular', value: 'Particular' },
                            { key: 6, text: 'Rechazado', value: 'Rechazado' }
                        ];
                        break;
                    case 'OPERADOR':
                        status= [
                            { key: 1, text: 'Activo', value: 'Activo' },
                            { key: 5, text: 'Particular', value: 'Particular' }
                        ];
                        break;
                    default:
                        status= [
                            { key: 1, text: 'Activo', value: 'Activo' },
                            { key: 2, text: 'Legajo', value: 'Legajo' },
                            { key: 3, text: 'Facturado', value: 'Facturado' },
                            { key: 4, text: 'Abonado', value: 'Abonado' },
                            { key: 5, text: 'Particular', value: 'Particular' },
                            { key: 6, text: 'Rechazado', value: 'Rechazado' }
                        ];
                        break;
                }
            }
        }

        
    }

    return status;
}