import React,{useState} from 'react'
import "./SideMenu.scss"
import {Icon} from "semantic-ui-react"
import {useLocation} from "react-router-dom"
import config from '../../../utils/config.json';
import { useAuth } from '../../../hooks'
import { getPermissions } from '../../Common/Permissions';

export function SideMenu  ({ toggleBtn,setToggle }) {
    const {pathname} = useLocation()
    const {auth} = useAuth()
  return (
    
    <div className={`${toggleBtn ? "sidebar collapse" : "sidebar"}`} >
       
       <ul>
        <li>
        <a>
              <span >
              <Icon circular name={toggleBtn ? "arrow right" : "arrow left"} className='toolbarDrawerButton_left' onClick={setToggle}/>
              </span>
            </a>
            </li>
        <li >
            <a href="/admin" className={"active"}>
              <span className="icon">
              <Icon name='home' />
              </span>
              {toggleBtn ? "" : "Dashboard"}
              
            </a>
          </li>
        {config.menu.map(item => {
              const active = getPermissions(item.permissionName,auth)
			 
              return active && item.active &&
              (item.submenu.length > 0 ? 
                (<li key={item.text}>
                <a  onClick={setToggle} className={item.active ? "active" : ""}>
                  <span className="icon">
                  <Icon name={item.icon} />
                  </span>
                  {toggleBtn ? "" : <span className="title">{item.name}</span>}
				  </a>
				  {!toggleBtn && 
				  (<ul id="sub_list">
					{item.submenu.map((item_submenu,indice_submenu)=>{
 						const active_submenu = getPermissions(item_submenu.permissionName,auth)
			 
						return active_submenu && item_submenu.active &&
						(<li key={indice_submenu}>
							 <a  href={item_submenu.routing}  className={item_submenu.active ? "active" : ""}>
							{/* <span className="icon">
							<Icon name={item_submenu.icon} />
							</span> */}
							{toggleBtn ? "" : <span className="title">{item_submenu.name}</span>}
							</a>
						</li>)
					})}
				 
				  </ul>)}
               
               </li>):
               (<li key={item.text}>
                <a href={item.routing} className={item.active ? "active" : ""}>
                  <span className="icon">
                  <Icon name={item.icon} />
                  </span>
                  {toggleBtn ? "" : <span className="title">{item.name}</span>}
                </a>
               </li>)
            )
})}
      </ul> 
     
    </div>
    
  );
};

{/* <a  className={"active"} onClick={setToggle}> 
<span className="icon">
<Icon name='home' />
</span>
{toggleBtn ? "" : "Dashboard"}
{!toggleBtn ?
(<ul id="sub_list">
   <li>bbb_1</li>
   <li>bbb_2</li>
   <li>bbb_3</li>
</ul>):
("")}
</a> */}