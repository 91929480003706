import React from 'react'
import "./ClientLayout.scss"
import {getToken } from '../../api/Common/token';
import {Redirect, BrowserRouter as Router, Route, Switch} from "react-router-dom";

export function ClientLayout(props) {
    const {children} = props

  if(getToken())
		return (<Redirect to={"/admin"} />) 
  return (
    <div>ClientLayout {children}</div>
  )
}
